import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	breadcrumbs: {
		padding: theme.spacing(3, 0, 0, 0),
	},
	list: {
		display: 'flex',
		margin: 0,
		padding: 0,
	},
	listItem: {
		listStyle: 'none',
		display: 'flex',
		'&:last-child': {
			color: theme.palette.brand.grey[500],
		},
	},
}));

export default useStyles;
