// react
import { SyntheticEvent, useContext, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, Outlet, useLocation, useSearchParams } from 'react-router-dom';

// context
import { ContextEntities, ContextEntitiesSummary, ContextProject } from '../Project.context';

// mui
import { Grid } from '@mui/material';

// ui
import { Tabs } from '../../../ui/Tabs/Tabs';

// pages
import { Summary } from './Summary/Summary';

// dialogs
import { DialogAddEntity } from '../../../dialogs/DialogAddEntity/DialogAddEntity';

export const Entities = () => {
	// context
	const { isOnboarded: isProjectOnboarded, project } = useContext(ContextProject);
	const { refetchEntities } = useContext(ContextEntities);
	const { refetchEntitiesSummary } = useContext(ContextEntitiesSummary);

	// hooks
	const location = useLocation();
	const { t } = useTranslation();

	// defaults
	const defaultTabValue = useMemo(() => {
		if (location.pathname.includes('hierarchy')) {
			return 'hierarchy';
		}
		return 'details';
	}, [location]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState(defaultTabValue);

	// vars
	const paramDialog = searchParams.get('dialog');

	const tabs = [
		{
			component: RouterLink,
			label: t('details'),
			to: `/project/${project?.idWbg}/entities/details`,
			value: 'details',
		},
	];

	if (isProjectOnboarded) {
		tabs.push({
			component: RouterLink,
			label: t('hierarchy'),
			to: `/project/${project?.idWbg}/entities/hierarchy`,
			value: 'hierarchy',
		});
	}

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	return (
		<>
			<Grid container spacing={2}>
				{isProjectOnboarded && (
					<Grid item xs={12}>
						<Summary />
					</Grid>
				)}

				<Grid item xs={12}>
					<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
				</Grid>
				<Grid item xs={12}>
					<Outlet />
				</Grid>
			</Grid>
			<DialogAddEntity
				isOpen={paramDialog === 'addEntity'}
				onClose={() => {
					setSearchParams((params) => {
						params.delete('dialog');
						return params;
					});
				}}
				onSuccess={() => {
					refetchEntitiesSummary();
					refetchEntities();
				}}
			/>
		</>
	);
};
