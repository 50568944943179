import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	message: {
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
	},
	notification: {
		borderBottom: `1px ${theme.palette.divider} solid`,
		display: 'flex',
		alignItems: 'flex-start',
		gap: theme.spacing(1),
		padding: theme.spacing(2),
		'&:last-child': {
			border: 'none',
		},
	},
	time: {
		color: theme.palette.brand.grey[500],
	},
}));

export default useStyles;
