// react
import { useContext } from 'react';

// context
import { ContextEntitySuppliers } from '../Entity.context';

// mui
import { Container, Grid } from '@mui/material';

// payments
import { TableSuppliers } from '../../../tables/cards/TableSuppliers/TableSuppliers';

export const Suppliers = () => {
	// context
	const { isLoadingSuppliers, paginationSuppliers, suppliers } = useContext(ContextEntitySuppliers);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableSuppliers
						frame="SUPPLIER"
						isLoading={isLoadingSuppliers}
						pagination={paginationSuppliers}
						suppliers={suppliers}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
