// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { Form } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// utils
import { translateFormName } from '../../../utils/translations';

// props
export interface DialogUpdateFormProps {
	form: Form;
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export const DialogUpdateForm = ({ form, isOpen, onClose }: DialogUpdateFormProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// vars
	const formName = translateFormName(form.name, t);

	// handlers
	const handleSubmit = async (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		const fetchUpdateForm = await fetch(`${process.env.REACT_APP_API_URL}/forms/${form.id}`, {
			method: 'PATCH',
			body: JSON.stringify({
				fields: form.fields,
			}),
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		});
		const resUpdateForm = await fetchUpdateForm.json();

		// clear loading
		setIsLoading(false);

		if (resUpdateForm.error) {
			let msg = t('error.default');

			if (resUpdateForm.error.id === 'FORM_UNAUTHORIZED_PATCH') {
				msg = t('error.unauthorized');
			}

			setError(msg);
		} else {
			// set banner
			setBanner({
				hasClose: true,
				message: t('alert.formUpdated', { name: formName }),
				severity: 'success',
			});

			// close dialog
			if (onClose) {
				onClose();
			}
		}
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: t('saveForm'),
					loading: isLoading,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'form',
			}}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			subtitle={{
				children: formName,
			}}
			title={{
				children: t('saveForm'),
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.orange.600" component="span" fontWeight={600} />,
						}}
						i18nKey="dialogUpdateForm.content"
						values={{ name: formName }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{t('alert.updateForm')}</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
