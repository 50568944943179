// react
import { useContext } from 'react';

// context
import { ContextLoans, ContextProject } from '../Project.context';

// i18n
import { Trans } from 'react-i18next';

// material
import { Container, Grid } from '@mui/material';

// pages
import { Details } from './Details/Details';
import { Loans } from './Loans/Loans';

// ui
import { Activity } from '../../../ui/Activity/Activity';
import { Tasks } from '../../../ui/Tasks/Tasks';

export const Overview = () => {
	// context
	const { project } = useContext(ContextProject);
	const { loans } = useContext(ContextLoans);

	// vars
	const tasks = [];

	// define tasks
	if (project?.status === 'ENABLED') {
		for (let i = 0; i < loans.length; i++) {
			const loan = loans[i];

			if (loan.status === 'DISABLED') {
				tasks.push({
					content: (
						<Trans
							components={{ strong: <strong /> }}
							i18nKey="tasks.enableLoan"
							values={{ id: loan.idWbg }}
						/>
					),
					type: 'loan',
				});
			}
		}
	}

	if (!project) {
		return <></>;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Details project={project} />
			</Grid>
			<Grid item xs={12}>
				<Loans project={project} />
			</Grid>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Grid container spacing={2}>
						<Grid item xs={12} lg={6}>
							<Tasks items={tasks} />
						</Grid>
						<Grid item xs={12} lg={6}>
							<Activity project={project} />
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Grid>
	);
};
