// react
import { MouseEvent } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Grid } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// props
export interface DialogIdleWarningProps {
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, reason?: string) => void;
}

export const DialogIdleWarning = ({ isOpen, onClose }: DialogIdleWarningProps) => {
	// hooks
	const { t } = useTranslation();

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>, reason?: string) => {
		if (onClose) {
			onClose(e, reason);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('continue'),
					onClick: handleClose,
					variant: 'contained',
				},
			]}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			title={{
				children: t('sessionExpiring'),
				variant: 'h3',
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Alert severity="warning">{t('alert.idleWarning')}</Alert>
				</Grid>
			</Grid>
		</DialogActions>
	);
};
