// react
import { ChangeEvent, useRef, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Button, InputLabel, Grid } from '@mui/material';

// fields
import { Input, InputProps } from '../Input/Input';

// props
export type InputFileProps = InputProps & {
	hasButton?: boolean;
};

export const InputFile = ({
	error,
	hasButton = false,
	helperText,
	inputProps,
	label,
	onChange,
	required,
	...props
}: InputFileProps) => {
	// state
	const [value, setValue] = useState('');

	// hooks
	const { t } = useTranslation();

	// ref
	const refInputFile = useRef<HTMLInputElement | null>(null);

	const handleChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;

		if (files?.length) {
			setValue(files[0].name);
		}

		if (onChange) {
			onChange(e);
		}
	};

	const handleClickButton = () => {
		if (refInputFile.current) {
			refInputFile.current.click();
		}
	};

	return (
		<Grid alignItems="flex-end" container spacing={2}>
			<Grid item xs={true}>
				{label && <InputLabel required={required}>{label}</InputLabel>}
				<Box sx={{ position: 'relative' }}>
					<Input
						error={error}
						helperText={helperText}
						inputProps={{ readOnly: true }}
						sx={{ cursor: 'pointer' }}
						type="text"
						value={value}
					/>
					<Input
						{...props}
						hidden={true}
						inputProps={{
							...inputProps,
							sx: {
								cursor: 'pointer',
							},
						}}
						label={false}
						onChange={handleChangeFile}
						ref={refInputFile}
						required={required}
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							opacity: 0,
							cursor: 'pointer',
						}}
						type="file"
					/>
				</Box>
			</Grid>
			{hasButton && (
				<Grid item xs="auto">
					<Button color="primary" onClick={handleClickButton} variant="contained">
						{t('selectFile')}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};
