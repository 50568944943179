// react
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, Outlet, useLocation, useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// ui
import { Tabs } from '../../../ui/Tabs/Tabs';

// pages
import { QuickActions } from './QuickActions/QuickActions';

// dialogs
import { DialogAddTransaction } from '../../../dialogs/DialogAddTransaction/DialogAddTransaction';
import { DialogAddTransfer } from '../../../dialogs/DialogAddTransfer/DialogAddTransfer';
import { DialogUploadHistoricalTransactions } from '../../../dialogs/DialogUploadHistoricalTransactions/DialogUploadHistoricalTransactions';

export const Transactions = () => {
	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const location = useLocation();
	const { t } = useTranslation();

	// defaults
	const defaultTabValue = useMemo(() => {
		if (location.pathname.includes('transfers')) {
			return 'transfers';
		} else if (location.pathname.includes('historical')) {
			return 'historical';
		}
		return 'payments';
	}, [location]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState(defaultTabValue);

	// params
	const paramDialog = searchParams.get('dialog');

	// vars
	const hasCreateTransactionPermission = user?.permissions?.transaction?.create;

	const tabs = [
		{
			component: RouterLink,
			label: t('payments'),
			to: `/project/${project?.idWbg}/transactions/payments`,
			value: 'payments',
		},
		{
			component: RouterLink,
			label: t('operatingExpenses'),
			to: `/project/${project?.idWbg}/transactions/opex`,
			value: 'opex',
		},
		{
			component: RouterLink,
			label: t('transfers'),
			to: `/project/${project?.idWbg}/transactions/transfers`,
			value: 'transfers',
		},
		{
			component: RouterLink,
			label: t('historicalTransactions'),
			to: `/project/${project?.idWbg}/transactions/historical`,
			value: 'historical',
		},
	];

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// set active tab
	useEffect(() => {
		let tabValueByLocation = 'payments';

		if (location.pathname.includes('opex')) {
			tabValueByLocation = 'opex';
		} else if (location.pathname.includes('transfers')) {
			tabValueByLocation = 'transfers';
		} else if (location.pathname.includes('historical')) {
			tabValueByLocation = 'historical';
		} else {
			tabValueByLocation = 'payments';
		}

		setTabValue(tabValueByLocation);
	}, [location]);

	return (
		<>
			<Grid container spacing={2}>
				{hasCreateTransactionPermission && (
					<Grid item xs={12}>
						<QuickActions />
					</Grid>
				)}
				<Grid item xs={12}>
					<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
				</Grid>
				<Grid item xs={12}>
					<Outlet />
				</Grid>
			</Grid>
			{hasCreateTransactionPermission && (
				<>
					<DialogAddTransaction
						isOpen={paramDialog === 'recordPayment'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								params.delete('dialogToEntity');
								return params;
							});
						}}
						type="PAYMENT"
					/>

					<DialogAddTransaction
						isOpen={paramDialog === 'recordOpEx'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
						type="OPEX"
					/>

					<DialogAddTransfer
						isOpen={paramDialog === 'recordFundTransfer'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
					/>

					<DialogUploadHistoricalTransactions
						isOpen={paramDialog === 'uploadHistoricalTransactions'}
						onClose={() => {
							setSearchParams((params) => {
								params.delete('dialog');
								return params;
							});
						}}
					/>
				</>
			)}
		</>
	);
};
