// l18n
import { TFunction } from 'i18next';

// mui
import { Typography } from '@mui/material';

// table
import { TableHeader } from '../../../ui/Table/Table';

// types
import { Document, DocumentType, Transaction, User } from '../../../../@types';

// ui
import { Time } from '../../../ui/Time/Time';
import { UserAvatar } from '../../../ui/UserAvatar/UserAvatar';

// links
import { LinkDocument } from '../../../links/LinkDocument/LinkDocument';

// props
export interface DocumentHeaderTableProps {
	t: TFunction<'translation', undefined>;
	transaction?: Transaction;
}

export const getDocumentsHeaders = ({
	t,
	transaction,
}: DocumentHeaderTableProps): TableHeader[] => {
	const columns: TableHeader[] = [
		{
			label: t('name'),
			value: 'name',
			render: (name: string, header: TableHeader, doc: Document) => {
				return <LinkDocument document={doc} underline="hover" />;
			},
		},
		{
			label: t('date'),
			value: 'created',
			render: (created: Date) => {
				return <Time date={new Date(created)} />;
			},
		},
	];

	if (transaction && transaction.expenseType) {
		columns.push({
			label: t('type'),
			value: 'type',
			render: (type: DocumentType) => {
				return <Typography>{type ? type.name : t('na')}</Typography>;
			},
		});
	}

	columns.push({
		label: t('uploaded'),
		value: 'user',
		render: (user: User) => {
			return <UserAvatar user={user} />;
		},
	});

	return columns;
};
