// react
import { MouseEventHandler } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// material
import { Button, IconButton } from '@mui/material';

// icons
import {
	ExpandLessOutlined as IconExpandLess,
	ExpandMoreOutlined as IconExpandMore,
} from '@mui/icons-material';

// styles
import useStyles from './BtnExpand.styles';

// props
interface BtnExpandProps {
	isExpanded: boolean;
	label: string;
	onClick: MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
}

export const BtnExpand = ({ isExpanded, label, onClick }: BtnExpandProps) => {
	// hooks
	const styles = useStyles();
	const { t } = useTranslation();

	return (
		<>
			<Button
				className={styles.btn}
				endIcon={isExpanded ? <IconExpandLess /> : <IconExpandMore />}
				onClick={onClick}
				variant="text">
				{label}
			</Button>
			<IconButton
				aria-label={isExpanded ? t('collapse') : t('expand')}
				className={styles.iconBtn}
				onClick={onClick}
				size="small">
				{isExpanded ? <IconExpandLess /> : <IconExpandMore />}
			</IconButton>
		</>
	);
};
