// react
import { FormEvent, FormEventHandler, useRef } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// material
import { Box, Button, Grid, InputAdornment } from '@mui/material';

// icons
import { ClearOutlined as IconClear, SearchOutlined as IconSearch } from '@mui/icons-material';

// fields
import { Input } from '../../fields/Input/Input';

// props
export interface FormSearchProps {
	id?: string;
	handleSearch?: FormEventHandler<HTMLFormElement>;
	hasButton?: boolean;
}

export const FormSearch = ({ id = '', handleSearch, hasButton = false }: FormSearchProps) => {
	// hooks
	const { t } = useTranslation();

	// refs
	const refInputQ = useRef<HTMLInputElement>(null);

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// handlers
	const handleSearchDefault = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const form = e.target as HTMLFormElement;

		if (form) {
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());

			if (entries.q) {
				// create new search params
				const searchParamsNew = new URLSearchParams();
				searchParamsNew.set('q', String(entries.q));
				setSearchParams(searchParamsNew);
			} else {
				// remove q search param
				searchParams.delete('q');
				setSearchParams(searchParams);
			}
		}
	};

	const handleClear = () => {
		// remove q param
		searchParams.delete('q');
		setSearchParams(searchParams);

		// clear input
		if (refInputQ.current) {
			refInputQ.current.value = '';
		}
	};

	return (
		<form id={id || `form-search`} onSubmit={handleSearch || handleSearchDefault}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={true}>
					<Input
						aria-label={t('search')}
						defaultValue={searchParams.get('q')}
						fullWidth
						id="form-search-q"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Box
										onClick={handleClear}
										sx={(theme) => {
											return {
												marginRight: theme.spacing(-0.5),
												cursor: 'pointer',
											};
										}}>
										<IconClear
											sx={(theme) => {
												return {
													color: theme.palette.brand.grey[500],
													width: theme.spacing(2),
												};
											}}
										/>
									</Box>
								</InputAdornment>
							),
							startAdornment: (
								<InputAdornment position="start">
									<IconSearch />
								</InputAdornment>
							),
						}}
						name="q"
						ref={refInputQ}
					/>
				</Grid>
				{hasButton && (
					<Grid item xs={12} md="auto">
						<Button fullWidth type="submit" variant="contained">
							{t('search')}
						</Button>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
