// react
import { ReactNode, useContext } from 'react';

// context
import { ContextBanner } from '../../../App.context';

// types
import { ActionButton } from '../../../@types';

// mui
import { Alert, AlertProps, Button, Grid, IconButton, Typography } from '@mui/material';

// icons
import {
	ClearOutlined as IconClear,
	InfoOutlined as IconInfo,
	TaskAltOutlined as IconTaskAlt,
	WarningAmberOutlined as IconWarning,
} from '@mui/icons-material';

// styles
import useStyles from './Banner.styles';

// props
export interface BannerProps extends AlertProps {
	actions?: ActionButton[];
	hasClose?: boolean;
	message: string;
}

export const Banner = ({
	actions = [],
	hasClose,
	icon = true,
	message,
	severity = 'info',
	variant = 'filled',
	...props
}: BannerProps) => {
	// hooks
	const styles = useStyles();

	// context
	const { setBanner } = useContext(ContextBanner);

	const icons: Record<string, ReactNode> = {
		error: <IconInfo />,
		info: <IconInfo />,
		success: <IconTaskAlt />,
		warning: <IconWarning />,
	};

	const hasCloseOnly = hasClose && actions.length === 0;

	return (
		<Alert
			{...props}
			classes={{
				message: styles.message,
			}}
			icon={false}
			severity={severity}
			sx={(theme) => {
				return {
					alignItems: 'center',
					borderRadius: 0,
					padding: actions.length ? theme.spacing(2, 3) : theme.spacing(1, 3),
				};
			}}
			variant={variant}>
			<Grid alignItems="center" justifyContent={{ md: 'space-between' }} container spacing={2}>
				<Grid item xs={hasCloseOnly ? 10 : 12} md={8}>
					<Grid alignItems="center" container columnSpacing={2}>
						{icon && (
							<Grid item xs="auto">
								{icons[severity]}
							</Grid>
						)}
						<Grid item xs={true}>
							<Typography fontWeight={600}>{message}</Typography>
						</Grid>
					</Grid>
				</Grid>
				{(hasClose || actions.length > 0) && (
					<Grid item xs={hasCloseOnly ? 2 : 12} md="auto">
						<Grid container justifyContent="flex-end" spacing={2}>
							{actions?.map((action, i) => {
								const { children, ...props } = action;
								return (
									<Grid item key={`banner-action-${i}`} xs={actions.length > 1 ? 6 : 12} md="auto">
										<Button fullWidth {...props}>
											{children}
										</Button>
									</Grid>
								);
							})}
							{hasClose && (
								<Grid item xs="auto">
									<IconButton color="white" onClick={() => setBanner(null)} size="small">
										<IconClear />
									</IconButton>
								</Grid>
							)}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Alert>
	);
};
