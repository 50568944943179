// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { User } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextActivities } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';
import { ContextSupplierUsers } from '../../pages/Supplier/Supplier.context';

// props
export interface DialogUpdateUserStatusProps {
	user: User;
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export const DialogUpdateUserStatus = ({ isOpen, onClose, user }: DialogUpdateUserStatusProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { refetchActivities } = useContext(ContextActivities);
	const { refetchUsers } = useContext(ContextSupplierUsers);
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const isInviting = user?.status === 'DEACTIVATED';
	const userFullName = `${user.firstName} ${user.lastName}`;

	// deactivate translations
	const dialog = {
		alert: t('alert.deactivateUser'),
		btnSubmitChildren: t('deactivateUser'),
		i18nKey: 'dialogUpdateUserStatus.deactivate.content',
		title: t('deactivateUser'),
	};

	// activate translations
	if (isInviting) {
		dialog.btnSubmitChildren = t('activateUser');
		dialog.alert = t('alert.activateUser');
		dialog.i18nKey = 'dialogUpdateUserStatus.activate.content';
		dialog.title = t('activateUser');
	}

	// patch user
	const { fetchRequest: updateUser, isLoading: isLoadingUpdateUser } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/users/${user.id}`,
		options: {
			method: 'PATCH',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify({
				status: isInviting ? 'INVITED' : 'DEACTIVATED',
			}),
		},
		onError: (error) => {
			let msg = t('error.default');

			if (error.id === 'USER_UNAUTHORIZED_PATCH') {
				msg = t('error.unauthorized');
			}

			setError(msg);
		},
		onSuccess: () => {
			// set banner
			setBanner({
				hasClose: true,
				message: isInviting
					? t('alert.userActivated', { name: userFullName })
					: t('alert.userDeactivated', { name: userFullName }),
				severity: 'success',
			});

			// refetch users
			refetchUsers();

			// refetch activities
			refetchActivities();

			// close dialog
			if (onClose) {
				onClose();
			}
		},
	});

	// handlers
	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// update user status
		updateUser();
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: dialog.btnSubmitChildren,
					loading: isLoadingUpdateUser,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'user',
			}}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			subtitle={{
				children: userFullName,
			}}
			title={{
				children: dialog.title,
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.orange.600" component="span" fontWeight={600} />,
						}}
						i18nKey={dialog.i18nKey}
						values={{ name: userFullName }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{dialog.alert}</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
