// types
import { Payment, Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// headers
import { getSourcesHeaders } from './TableBasicPayments.headers';

// ui
import { Table, TableHeader } from '../../../ui/Table/Table';

// props
export interface TableBasicPaymentsProps {
	payments: Payment[];
	transaction?: Transaction;
}

export const TableBasicPayments = ({ payments, transaction }: TableBasicPaymentsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers: TableHeader[] = getSourcesHeaders({ t, transaction });

	return <Table data={payments} headers={headers} />;
};
