// react
import { ChangeEvent } from 'react';

// types
import { Pagination as PaginationProps } from '../../../@types';

// i18n
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid, Pagination, Typography } from '@mui/material';

// props
export interface TableFooterProps {
	numShowing: number;
	pagination: PaginationProps;
}

export const TableFooter = ({ numShowing, pagination }: TableFooterProps) => {
	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// vars
	let numOnPage = pagination.perPage;

	if (numShowing < pagination.perPage) {
		numOnPage = numShowing;
	}

	const handlePagination = (e: ChangeEvent<unknown>, page: number) => {
		searchParams.set('page', String(page));
		setSearchParams(searchParams);
	};

	return (
		<Grid container justifyContent="space-between" spacing={2}>
			<Grid item>
				<Typography variant="caption">
					{t('tableShowing', { showing: numOnPage, total: pagination.total })}
				</Typography>
			</Grid>
			<Grid item>
				<Pagination
					color="primary"
					count={pagination.totalPages}
					onChange={handlePagination}
					shape="rounded"
					page={pagination.page}
				/>
			</Grid>
		</Grid>
	);
};
