// react
import { SyntheticEvent, useContext, useEffect, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';

// context
import { ContextUser } from '../../../App.context';

// material
import { Grid } from '@mui/material';

// layouts
import { Authenticated } from '../../layouts/Authenticated/Authenticated';

// ui
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Title } from '../../ui/Title/Title';

export const PageProjects = () => {
	// hooks
	const { user } = useContext(ContextUser);
	const location = useLocation();
	const { t } = useTranslation();

	// state
	const [tabValue, setTabValue] = useState('details');

	const tabs = [
		{
			component: RouterLink,
			label: t('projects'),
			title: t('allProjects'),
			to: `/projects`,
			value: 'details',
		},
	];

	if (user?.role === 'WFA') {
		tabs.push({
			component: RouterLink,
			label: t('onboard'),
			title: t('onboardProject'),
			to: `/projects/onboard`,
			value: 'onboard',
		});
	}

	const activeTab = tabs.find((tab) => tab.value === tabValue);

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// set active tab
	useEffect(() => {
		let tabValueNew = 'details';
		if (location.pathname.includes('onboard')) {
			tabValueNew = 'onboard';
		}
		setTabValue(tabValueNew);
	}, [location]);

	return (
		<Authenticated>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Breadcrumbs
						crumbs={[
							{
								children: t('projects'),
								to: '/projects',
							},
						]}
						page={t('allProjects')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Title
						color="purple"
						overline={t('projects')}
						title={activeTab ? activeTab.title : t('allProjects')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Tabs onChange={handleChangeTab} tabs={tabs} value={tabValue} />
				</Grid>
				<Grid item xs={12}>
					<Outlet />
				</Grid>
			</Grid>
		</Authenticated>
	);
};
