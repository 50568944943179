// i18n
import { useTranslation } from 'react-i18next';

// types
import { Project } from '../../../../../@types';

// material
import { Container, Grid, Typography } from '@mui/material';

// ui
import { CardDetails } from './CardDetails/CardDetails';
import { CardUsers } from '../../../../cards/CardUsers/CardUsers';
import { CardTraceability } from '../../../../cards/CardTraceability/CardTraceability';

// props
interface DetailsProps {
	project: Project;
}

export const Details = ({ project }: DetailsProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('details')}</Typography>
				</Grid>

				<Grid item xs={12} lg={6}>
					<CardDetails project={project} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardUsers title={t('taskTeamLeads')} users={[]} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardTraceability traceability={project.traceability} />
				</Grid>
			</Grid>
		</Container>
	);
};
