// react
import { useCallback, useContext } from 'react';

// types
import { Account, AccountEntity, Loan } from '../../../@types';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Box, ChipProps, Grid, Paper, Typography } from '@mui/material';

// ui
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { ChipBox } from '../../ui/ChipBox/ChipBox';
import { Time } from '../../ui/Time/Time';
import { VisibilityTextToggle } from '../../ui/VisibilityTextToggle/VisibilityTextToggle';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// utils
import { formatCurrency } from '../../../utils/currency';

// dialogs
import { DialogAddAccountBalance } from '../../dialogs/DialogAddAccountBalance/DialogAddAccountBalance';

// icons
import {
	AddCircleOutlineOutlined as IconAddCircle,
	LocalAtmOutlined as IconLocalAtm,
	PaidOutlined as IconPaid,
	SwapHorizOutlined as IconSwapHoriz,
} from '@mui/icons-material';

// props
export interface CardAccountProps {
	account: Account;
}

export const CardAccount = ({ account }: CardAccountProps) => {
	// hooks
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogAccount = searchParams.get('dialogAccount');

	// context
	const { isOnboarded: isProjectOnboarded, project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// vars
	const hasCreateTransactionPermission = user?.permissions?.transaction?.create;
	const currency = account.currency;

	let balance: string = t('na');

	if (account.balances.length) {
		const total = account.balances.reduce((total, account) => {
			return total + account.balance;
		}, 0);

		balance = formatCurrency({ currency, value: total });
	}

	const associatedAccounts = account.accounts as Account[];
	const associatedEntities = (account.entities as AccountEntity[]).map((rel) => rel.entity);

	// handlers
	const handleOpenDialogAddAccountBalance = useCallback(() => {
		searchParams.set('dialog', 'addAccountBalance');
		searchParams.set('dialogAccount', String(account.id));
		setSearchParams(searchParams);
	}, [account, searchParams, setSearchParams]);

	return (
		<Paper sx={{ position: 'relative' }}>
			<Box
				sx={(theme) => {
					return {
						borderBottom: `1px ${theme.palette.grey[300]} solid`,
						padding: theme.spacing(2),
					};
				}}>
				<Grid container spacing={2}>
					<Grid alignSelf="center" item xs={12} lg={2}>
						<Grid alignItems="center" container spacing={2}>
							<Grid item>
								<IconCircle type={account.type === 'LOCAL' ? 'local' : 'account'} />
							</Grid>
							<Grid item>
								<Typography fontWeight={600}>{account.name}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} md={true}>
						<Typography color="brand.grey.500">{t('accountNumber')}</Typography>
						<Typography component="div" fontWeight={500}>
							<VisibilityTextToggle text={String(account.num)} />
						</Typography>
					</Grid>
					<Grid item xs={6} md={3} lg={3}>
						<Typography color="brand.grey.500">{t('financialInstitution')}</Typography>
						<Typography fontWeight={500}>{account.institution}</Typography>
					</Grid>
					<Grid item xs={6} md={3} lg={2}>
						<Typography color="brand.grey.500">{t('lastUpdated')}</Typography>
						{account.modified || account.created ? (
							<Time
								date={new Date(account.modified || account.created)}
								TypographyProps={{ color: 'text.primary', fontWeight: 500 }}
							/>
						) : (
							t('na')
						)}
					</Grid>
					<Grid item xs={6} md={2}>
						<Typography color="brand.grey.500">{t('balance')}</Typography>
						<Typography variant="h3">{balance}</Typography>
					</Grid>
					{isProjectOnboarded && (
						<Grid
							item
							xs={true}
							sx={(theme) => {
								return {
									position: 'absolute',
									top: theme.spacing(-1),
									right: theme.spacing(1),
									[theme.breakpoints.up('lg')]: {
										position: 'relative',
										top: 'auto',
										right: 'auto',
									},
								};
							}}>
							{hasCreateTransactionPermission && (
								<Grid container justifyContent="flex-end">
									<Grid item>
										<BtnMenu
											items={[
												{
													children: t('addToBalance'),
													component: RouterLink,
													divider: true,
													icon: <IconAddCircle />,
													onClick: handleOpenDialogAddAccountBalance,
												},
												{
													children: t('recordPayment'),
													component: RouterLink,
													icon: <IconPaid />,
													to: `/project/${project?.idWbg}/transactions/payments?dialog=recordPayment`,
												},
												{
													children: t('recordOpEx'),
													component: RouterLink,
													icon: <IconLocalAtm />,
													to: `/project/${project?.idWbg}/transactions/opex?dialog=recordOpEx`,
												},
												{
													children: t('recordFundTransfer'),
													component: RouterLink,
													icon: <IconSwapHoriz />,
													to: `/project/${project?.idWbg}/transactions/transfers?dialog=recordFundTransfer`,
												},
											]}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</Box>
			<Box
				sx={(theme) => {
					return {
						borderBottom: `1px ${theme.palette.brand.grey[300]} solid`,
					};
				}}>
				<Grid alignItems="center" container>
					<Grid item xs={12} md={3} lg={2}>
						<Box
							sx={(theme) => {
								return {
									padding: {
										xs: theme.spacing(2, 2, 0, 2),
										md: theme.spacing(2),
									},
								};
							}}>
							<Typography color="brand.grey.500">{t('financingSources')}</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} md={9}>
						<Box
							sx={(theme) => {
								return {
									padding: theme.spacing(2),
								};
							}}>
							<Grid container spacing={2}>
								{account.sources.length > 0 ? (
									account.sources.map((source, i) => {
										const loan = source.loan as Loan;

										let balance = null;
										let label;
										let color = 'default';
										let value = t('na');

										// find balance that has same source and loan, if loan is defined
										if (account.balances.length) {
											balance = account.balances.find(
												(b) => b.source === source.source && (!source.loan || loan.id === b.loan)
											);
										}

										if (source.loan) {
											const { idWbg } = source.loan as Loan;
											label = idWbg;
											color = 'teal';
										} else if (source.source === 'GOVERNMENT') {
											label = t('government');
										} else {
											label = t('other');
										}

										if (balance) {
											value = `${formatCurrency({ currency, value: balance.balance })} ${currency}`;
										}

										return (
											<Grid item key={`account-balance-${i}`} xs={12} md="auto">
												<ChipBox color={color as ChipProps['color']} label={label} value={value} />
											</Grid>
										);
									})
								) : (
									<Grid item xs={12}>
										<Typography>{t('na')}</Typography>
									</Grid>
								)}
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
					};
				}}>
				<Grid alignItems="center" container spacing={2}>
					<Grid item xs={12} md={3} lg={2}>
						<Typography color="brand.grey.500">{t('entities')}</Typography>
					</Grid>
					<Grid item xs={12} md={6} lg={7}>
						{associatedEntities.length > 0 ? (
							<Grid container spacing={{ xs: 1, md: 2 }}>
								{associatedEntities.map((entity, i) => {
									return (
										<Grid item key={`account-associated-entity-${i}`} xs={12} md="auto">
											<LinkEntity entity={entity} />
										</Grid>
									);
								})}
							</Grid>
						) : (
							<Typography>{t('na')}</Typography>
						)}
					</Grid>
					{account.type === 'LOCAL' && (
						<Grid item xs={12} md={3} lg={2}>
							<Typography color="brand.grey.500">{t('designatedAccounts')}</Typography>
							{associatedAccounts.length > 0 ? (
								<Grid container spacing={1}>
									{associatedAccounts.map((associatedAccount, i) => {
										const isLast = i === associatedAccounts.length - 1;
										return (
											<Grid item key={`account-associated-account-${i}`}>
												<Typography fontWeight={500}>
													{associatedAccount.name}
													{!isLast ? ',' : ''}
												</Typography>
											</Grid>
										);
									})}
								</Grid>
							) : (
								<Typography>{t('na')}</Typography>
							)}
						</Grid>
					)}
				</Grid>
			</Box>
			<DialogAddAccountBalance
				account={account}
				isOpen={paramDialog === 'addAccountBalance' && paramDialogAccount === String(account.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogAccount');
					setSearchParams(searchParams);
				}}
			/>
		</Paper>
	);
};
