import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	btn: {
		[theme.breakpoints.up('lg')]: {
			display: 'none !important',
		},
	},
	iconBtn: {
		display: 'none !important',
		[theme.breakpoints.up('lg')]: {
			display: 'inline-flex !important',
		},
	},
}));

export default useStyles;
