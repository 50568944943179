// react
import { ReactNode, useContext, useState } from 'react';

// types
import { Contract, Document, Entity, EntityType, Frame, Transaction, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// ui
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Time } from '../../ui/Time/Time';
import { UserAvatar } from '../../ui/UserAvatar/UserAvatar';

// icons
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// links
import { LinkDocument } from '../../links/LinkDocument/LinkDocument';
import { LinkSupplier } from '../../links/LinkSupplier/LinkSupplier';

// utils
import { translateType } from '../../../utils/translations';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// props
export interface CardHistoricalTransactionProps {
	document: Document;
	entityType: EntityType;
	frame?: Frame;
}

export const CardDocument = ({
	document,
	entityType,
	frame = 'PROJECT',
}: CardHistoricalTransactionProps) => {
	// context
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// vars
	let entityLink: ReactNode = <Typography>{t('na')}</Typography>;
	const docType = document?.type as unknown as DocumentType;
	const docUser = document?.user as User;
	const docUserEntity = docUser?.entity as Entity;
	const isBeneficiary = docUserEntity?.type === 'BENEFICIARY';

	if (docUserEntity) {
		if (entityType === 'SUPPLIER') {
			entityLink = <LinkSupplier supplier={docUserEntity} />;
		} else {
			entityLink = <Typography>{docUserEntity?.name}</Typography>;
		}
	}

	// state
	const [transaction, setTransaction] = useState<Transaction>();

	// fetch transactions
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/transactions/${document.transaction}?populate=contract`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data[0]) {
				setTransaction(res.data[0]);
			}
		},
	});

	// vars
	let headerDocumentLg = 2;
	if (isBeneficiary) {
		headerDocumentLg = 3;
	}
	if (frame === 'SUPPLIER') {
		headerDocumentLg = 4;
	}

	return (
		<>
			<Paper
				sx={() => {
					return {
						border: `1px transparent solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(2) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={headerDocumentLg}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={'document'} />
									</Grid>
									<Grid item>
										<LinkDocument document={document} underline="hover" />
									</Grid>
								</Grid>
							</Box>
						</Grid>

						{frame === 'PROJECT' && (
							<Grid item xs={12} lg={isBeneficiary ? 3 : 2}>
								<RowKeyValue label={translateType(entityType, t)} value={entityLink} />
							</Grid>
						)}

						{!isBeneficiary && (
							<Grid item xs={12} lg={2}>
								<RowKeyValue
									label={t('contract')}
									value={
										<Typography>
											{transaction?.contract ? (transaction.contract as Contract).num : t('na')}
										</Typography>
									}
								/>
							</Grid>
						)}

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('type')}
								value={<Typography>{docType?.name || t('na')}</Typography>}
							/>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('created')}
								value={<Time date={new Date(document.created)} />}
							/>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue label={t('uploaded')} value={<UserAvatar user={docUser} />} />
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
