// react
import { useContext, useEffect, useMemo, useState } from 'react';

// types
import { Account, Entity, Loan, Option, Period } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextProject } from '../Project.context';

// mui
import { Container, Grid, Typography } from '@mui/material';

// pages
import { Sankey } from './Sankey/Sankey';
import { Summary } from './Summary/Summary';

// tables
import { ChartSankeyProps } from '../../../charts/ChartSankey/ChartSankey';

// ui
import { FilterProps } from '../../../ui/Filters/Filters';

// utils
import { getDatesByPeriod } from '../../../../utils/dates';
import { translateType } from '../../../../utils/translations';

export interface FilterValuesProps {
	entities: Option[];
}

export const FundFlow = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);

	// hooks
	const { t } = useTranslation();

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramAccounts = searchParams.get('accounts');
	const paramCurrency = searchParams.get('currency');
	const paramPeriod = searchParams.get('period');
	const paramEntities = searchParams.get('entities');
	const paramLoans = searchParams.get('loans');
	const paramType = searchParams.get('type');

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [dataSankey, setDataSankey] = useState<ChartSankeyProps | null>(null);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);

	// fetch sankey
	let fetchSankeyUrl = `${process.env.REACT_APP_API_URL}/charts/sankey?project=${project?.id}`;

	if (paramAccounts) {
		fetchSankeyUrl += `&accounts=${decodeURIComponent(paramAccounts)}`;
	}

	if (paramCurrency) {
		fetchSankeyUrl += `&currency=${decodeURIComponent(paramCurrency)}`;
	}

	if (paramPeriod) {
		const { dateAfter, dateBefore } = getDatesByPeriod(paramPeriod as Period);

		if (dateAfter) {
			fetchSankeyUrl += `&dateAfter=${dateAfter}`;
		}

		if (dateBefore) {
			fetchSankeyUrl += `&dateBefore=${dateBefore}`;
		}
	}

	if (paramEntities) {
		fetchSankeyUrl += `&entities=${decodeURIComponent(paramEntities)}`;
	}

	if (paramLoans) {
		fetchSankeyUrl += `&loans=${decodeURIComponent(paramLoans)}`;
	}

	if (paramType) {
		fetchSankeyUrl += `&type=${decodeURIComponent(paramType)}`;
	}

	// vars
	const formId = 'form-sankey-filters';

	// fetch
	const { refetch: refetchSankey } = useFetch({
		isEnabled: Boolean(project && paramCurrency && paramPeriod),
		url: fetchSankeyUrl,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			let dataSankeyNew = null;
			if (res.data?.links?.length && res.data?.nodes?.length) {
				dataSankeyNew = {
					data: {
						...res.data,
					},
				};
			}

			setDataSankey(dataSankeyNew);
		},
	});

	// fetch loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch accounts
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&fields=id,name,currency`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// options
	const sortByLabel = (a: Option, b: Option) => {
		return a.label > b.label ? 1 : -1;
	};

	const optionsLoans: Option[] = loans
		.map((loan) => {
			return {
				disabled: loan.status === 'DISABLED',
				label: loan.idWbg,
				value: String(loan.id),
			};
		})
		.sort(sortByLabel);

	const optionsEntities: Option[] = entities
		.map((entity) => {
			return {
				label: entity.name,
				secondary: translateType(entity.type, t),
				value: String(entity.id),
			};
		})
		.sort(sortByLabel);

	const optionsAccounts: Option[] = accounts
		.map((account) => {
			return {
				label: account.name,
				value: String(account.id),
			};
		})
		.sort(sortByLabel);

	const currencies = accounts.map((account) => account.currency);
	const optionsCurrency: Option[] = currencies
		.filter((item, index) => currencies.indexOf(item) === index)
		.map((currency) => {
			return {
				label: currency,
				value: currency,
			};
		})
		.sort(sortByLabel);

	const optionsTransactionTypes = [
		{
			label: t('payment'),
			value: 'PAYMENT',
		},
		{
			label: t('transfer'),
			value: 'TRANSFER',
		},
	];

	// filters
	const filterCurrency: FilterProps = useMemo(() => {
		return {
			label: t('currency'),
			defaultValue: optionsCurrency?.[0]?.value ? String(optionsCurrency[0].value) : null,
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-currency`,
			name: 'currency',
			options: [...optionsCurrency],
			type: 'SELECT',
		};
	}, [optionsCurrency, t]);

	const filterPeriod: FilterProps = useMemo(() => {
		return {
			label: t('period'),
			defaultValue: 'YEAR',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-period`,
			name: 'period',
			options: [
				{
					label: t('month'),
					value: 'MONTH',
				},
				{
					label: t('quarter'),
					value: 'QUARTER',
				},
				{
					label: t('year'),
					value: 'YEAR',
				},
			],
			type: 'SELECT',
		};
	}, [t]);

	const defaults = {
		accounts: ['all'],
		entities: optionsEntities,
		loans: ['all'],
		type: ['all'],
	};

	if (paramAccounts) {
		defaults.accounts = paramAccounts.split(',');
	}

	if (paramEntities) {
		const paramEntitiesArray = paramEntities.split(',');
		defaults.entities = defaults.entities.filter((option) =>
			paramEntitiesArray.includes(String(option.value))
		);
	}

	if (paramLoans) {
		defaults.loans = paramLoans.split(',');
	}

	if (paramType) {
		defaults.loans = paramType.split(',');
	}

	const filters: FilterProps[] = [
		{
			defaultValue: defaults.type,
			hasAllOption: true,
			hasSearchParam: true,
			id: `${formId}-type`,
			label: t('type'),
			multiple: true,
			name: 'type',
			options: optionsTransactionTypes,
			type: 'SELECT',
		},
		filterCurrency,
		filterPeriod,
		{
			defaultValue: defaults.loans,
			hasAllOption: true,
			hasSearchParam: true,
			id: `${formId}-loans`,
			label: t('financingSources'),
			multiple: true,
			name: 'loans',
			options: optionsLoans,
			type: 'SELECT',
		},
		{
			defaultValue: defaults.entities,
			hasAllOption: true,
			hasSearchParam: true,
			id: `${formId}-entities`,
			label: t('entities'),
			name: 'entities',
			options: optionsEntities,
			type: 'AUTOCOMPLETE',
		},
		{
			defaultValue: defaults.accounts,
			hasAllOption: true,
			hasSearchParam: true,
			id: `${formId}-accounts`,
			label: t('accounts'),
			multiple: true,
			name: 'accounts',
			options: optionsAccounts,
			type: 'SELECT',
		},
	];

	// refetch sankey data when search params change
	useEffect(() => {
		if (paramCurrency && paramPeriod) {
			refetchSankey();
		}
	}, [refetchSankey, paramCurrency, paramPeriod, searchParams]);

	// set default search params
	useEffect(() => {
		if (!paramCurrency || !paramPeriod) {
			if (filterCurrency?.defaultValue) {
				searchParams.set('currency', String(filterCurrency.defaultValue));
			}

			if (filterPeriod?.defaultValue) {
				searchParams.set('period', String(filterPeriod.defaultValue));
			}

			setSearchParams(searchParams);
		}
	}, [filterCurrency, filterPeriod, paramCurrency, paramPeriod, searchParams, setSearchParams]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Typography variant="h2">{t('fundFlow')}</Typography>
				</Container>
			</Grid>
			<Grid item xs={12}>
				<Summary dataSankey={dataSankey} />
			</Grid>

			<Grid item xs={12}>
				<Sankey dataSankey={dataSankey} FilterProps={{ id: formId, filters }} />
			</Grid>
		</Grid>
	);
};
