// types
import { Contract } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// ui
import { Table, TableHeader } from '../../../ui/Table/Table';

// headers
import { getHeaders } from './TableBasicContracts.headers';

// props
export interface TableBasicContractsProps {
	contracts: Contract[];
}

export const TableBasicContracts = ({ contracts }: TableBasicContractsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const headers: TableHeader[] = getHeaders({ t });

	return <Table headers={headers} data={contracts} />;
};
