// react
import { useContext, useMemo } from 'react';

// i18next
import { t } from 'i18next';

// context
import { ContextLoans, ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardLoan } from '../../../cards/CardLoan/CardLoan';

// ui
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';

// icons
import { PaymentsOutlined as IconPayments } from '@mui/icons-material';

export const TableLoans = () => {
	// context
	const { isLoadingLoans, loans } = useContext(ContextLoans);
	const { project } = useContext(ContextProject);

	const headers = [
		{
			label: t('id'),
			lg: 2,
			value: 'id',
		},
		{
			label: t('closing'),
			lg: 1.5,
			value: 'dateClosing',
		},
		{
			label: t('currency'),
			lg: 1,
			value: 'currency',
		},
		{
			label: t('amount'),
			lg: 1.5,
			value: 'amount',
		},
		{
			label: t('undisbursed'),
			lg: 1.5,
			value: 'undisbursed',
		},
		{
			label: t('traceability'),
			lg: 1,
			value: 'traceability',
		},
		{
			label: t('status'),
			lg: 2,
			value: 'status',
		},
	];

	const content = useMemo(() => {
		if (isLoadingLoans || !project || !loans.length) {
			return (
				<CardEmpty
					empty={{
						content: { children: t('empty.loans.content') },
						IconCircleProps: { icon: <IconPayments /> },
						title: { children: t('empty.loans.title') },
					}}
					isLoading={isLoadingLoans}
				/>
			);
		}

		return (
			<Grid container spacing={2}>
				{loans.map((loan, i) => {
					return (
						<Grid item key={`loan-${i}`} xs={12}>
							<CardLoan loan={loan} project={project} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [isLoadingLoans, loans, project]);

	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={{ xs: 0, lg: 2 }}>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
		</Grid>
	);
};
