// react
import { useContext, useMemo, useState } from 'react';

// types
import {
	Contract,
	Document,
	DocumentType,
	Entity,
	EntityType,
	Frame,
	Pagination,
} from '../../../../@types';

// i18n
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextProject } from '../../../pages/Project/Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { EmptyProps } from '../../../ui/Empty/Empty';
import { FilterProps } from '../../../ui/Filters/Filters';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// utils
import { translateType } from '../../../../utils/translations';

// cards
import { CardDocument } from '../../../cards/CardDocument/CardDocument';
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { InsertDriveFileOutlined as IconInsertDriveFile } from '@mui/icons-material';

// props
export interface TableDocumentsProps {
	documents: Document[];
	entityType: EntityType;
	frame?: Frame;
	isLoading: boolean;
	pagination: Pagination;
}

export const TableDocuments = ({
	documents,
	entityType,
	frame = 'PROJECT',
	isLoading,
	pagination,
}: TableDocumentsProps) => {
	// params
	const [searchParams] = useSearchParams();
	const paramQ = searchParams.get('q');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [docTypes, setDocTypes] = useState<DocumentType[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);

	// vars
	const isBeneficiary = entityType === 'BENEFICIARY';
	const isSupplier = entityType === 'SUPPLIER';
	let headerFileLg = 2;

	if (isBeneficiary) {
		headerFileLg = 3;
	}

	if (frame === 'SUPPLIER') {
		headerFileLg = 4;
	}

	// fetch doc types
	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	useFetch({
		isEnabled: Boolean(user),
		url: `${process.env.REACT_APP_API_URL}/document-types?entity=${paramEntity}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocTypes(res.data);
			}
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=${entityType}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// fetch contracts
	useFetch({
		isEnabled: Boolean(isSupplier && project?.id),
		url: `${process.env.REACT_APP_API_URL}/contracts?project=${project?.id}&fields=id,num`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setContracts(res.data);
			}
		},
	});

	// options
	const optionsAll = {
		label: t('all'),
		value: 'all',
	};

	const optionsContracts = contracts.map((contract) => {
		return {
			label: contract.num,
			value: String(contract.id),
		};
	});

	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			value: String(entity.id),
		};
	});

	const optionsType = docTypes.map((docType) => {
		return {
			label: docType.name,
			value: String(docType.id),
		};
	});

	// headers
	const headers = [
		{
			label: t('file'),
			lg: headerFileLg,
			value: 'file',
		},
	];
	if (frame === 'PROJECT')
		headers.push({
			label: translateType(entityType, t),
			lg: isBeneficiary ? 3 : 2,
			value: 'user.entity.type',
		});

	if (!isBeneficiary) {
		headers.push({
			label: t('contract'),
			lg: 2,
			value: 'contract',
		});
	}
	headers.push(
		{
			label: t('type'),
			lg: 2,
			value: 'type',
		},
		{
			label: t('created'),
			lg: 2,
			value: 'created',
		},
		{
			label: t('uploaded'),
			lg: 2,
			value: 'uploaded',
		}
	);
	const formFiltersId = 'form-documents-filters';
	const filters: FilterProps[] = [
		{
			label: t('type'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formFiltersId}-type`,
			name: 'type',
			options: [optionsAll, ...optionsType],
		},
	];
	if (isSupplier) {
		filters.push({
			label: t('contract'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formFiltersId}-contract`,
			name: 'contract',
			options: [optionsAll, ...optionsContracts],
		});
	}
	if (frame === 'PROJECT') {
		filters.push({
			label: translateType(entityType, t),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formFiltersId}-entity`,
			name: 'entity',
			options: [optionsAll, ...optionsEntities],
		});
	}

	// memo
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			content: { children: t('empty.documents.content') },
			IconCircleProps: { icon: <IconInsertDriveFile /> },
			title: { children: t('empty.documents.title') },
		};

		if (paramQ) {
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ]);

	const content = useMemo(() => {
		if (isLoading || !documents.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{documents.map((document, i) => {
					return (
						<Grid item key={`document-${i}`} xs={12}>
							<CardDocument document={document} entityType={entityType} frame={frame} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, frame, isLoading, documents, entityType]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item container xs={'auto'}>
						<BtnFilters filters={filters} formId={formFiltersId} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={documents.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
