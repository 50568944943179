// types
import { User } from '../@types';

export const getInitials = (user: User) => {
	if (user) {
		if (user.firstName && user.lastName) {
			return `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
		}
	}
	return '';
};

export interface TruncateParams {
	end: number;
	start?: number;
	str: string | undefined;
	suffix?: string;
}

export const truncate = ({ end, start = 0, str, suffix }: TruncateParams) => {
	if (!str) {
		return '';
	}

	if (str.length < end) {
		return str;
	}

	let res: string;
	res = str.slice(start, end);

	if (suffix) {
		res = res + suffix;
	}
	return res;
};

export const truncateHash = (hash: string | undefined) => {
	if (!hash) {
		return '';
	}

	const start = truncate({
		str: hash,
		end: 6,
	});

	const end = truncate({
		end: hash.length,
		start: hash.length - 4,
		str: hash,
	});

	return `${start}...${end}`;
};

export const toKebabCase = (str: string) => {
	if (!str) {
		return '';
	}

	const strArray = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);
	return strArray ? strArray.map((x) => x.toLowerCase()).join('-') : '';
};

// https://stackoverflow.com/a/2970667
export const toCamelCase = (str: string) => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};
