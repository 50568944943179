// mui
import { Link } from '@mui/material';

// props
export interface LinkEmailProps {
	email: string;
}

export const LinkEmail = ({ email }: LinkEmailProps) => {
	return (
		<Link href={`mailto:${email}`} sx={{ wordBreak: 'break-word' }} underline="hover">
			{email}
		</Link>
	);
};
