// react
import { useContext } from 'react';

// router
import { useSearchParams } from 'react-router-dom';

// types
import { EntityType, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextUser } from '../../../App.context';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';

// dialogs
import { DialogUpdateUserStatus } from '../../dialogs/DialogUpdateUserStatus/DialogUpdateUserStatus';

// ui
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';

// links
import { LinkEmail } from '../../links/LinkEmail/LinkEmail';
import { LinkPhone } from '../../links/LinkPhone/LinkPhone';

// icons
import {
	DoDisturbOutlined as IconDoDisturb,
	PowerSettingsNewOutlined as IconPowerSettingsNew,
} from '@mui/icons-material';

// utils
import { Time } from '../../ui/Time/Time';

// props
export interface CardUserProps {
	entityType?: EntityType;
	user: User;
}

export const CardUser = ({ entityType, user }: CardUserProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// context
	const { user: userLoggedIn } = useContext(ContextUser);

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogUser = searchParams.get('dialogUser');

	// vars
	const lastUpdated: Date = new Date(user.modified || user.created);

	const handleOpenDialogUpdateUser = () => {
		searchParams.set('dialog', 'updateUserStatus');
		searchParams.set('dialogUser', String(user.id));
		setSearchParams(searchParams);
	};

	const btnMenuItems: MenuItemProps[] = [];

	if (entityType === 'SUPPLIER') {
		if (userLoggedIn?.permissions?.entity?.update) {
			if (user.status !== 'DEACTIVATED') {
				btnMenuItems.push({
					children: t('deactivate'),
					icon: <IconDoDisturb />,
					onClick: handleOpenDialogUpdateUser,
				});
			} else {
				btnMenuItems.push({
					children: t('activate'),
					icon: <IconPowerSettingsNew />,
					onClick: handleOpenDialogUpdateUser,
				});
			}
		}
	}

	return (
		<>
			<Paper
				sx={(theme) => {
					return {
						border: `1px transparent solid`,
						position: 'relative',
					};
				}}>
				<Box
					sx={(theme) => {
						return {
							padding: theme.spacing(2),
						};
					}}>
					<Grid alignItems="center" container spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={3}>
							<Box
								sx={(theme) => {
									return {
										margin: theme.spacing(0, 0, 2, 0),
										[theme.breakpoints.up('lg')]: {
											margin: 0,
										},
									};
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type="user" />
									</Grid>
									<Grid item flex={1}>
										<Typography component="div" fontWeight={600}>
											{user.firstName} {user.lastName}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('jobTitle')}
								value={<Typography>{user.title || t('na')}</Typography>}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue label={t('email')} value={<LinkEmail email={user.email} />} />
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue label={t('phone')} value={<LinkPhone phone={user.phone} />} />
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue label={t('status')} value={<ChipStatus status={user.status} />} />
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue label={t('lastUpdated')} value={<Time date={lastUpdated} />} />
						</Grid>
						{btnMenuItems.length > 0 && (
							<Grid item xs={12} lg={true}>
								<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
									<Grid
										item
										sx={(theme) => {
											return {
												position: 'absolute',
												top: 0,
												right: theme.spacing(1),
												[theme.breakpoints.up('lg')]: {
													position: 'relative',
													top: 'auto',
													right: 'auto',
												},
											};
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper>
			<DialogUpdateUserStatus
				isOpen={paramDialog === 'updateUserStatus' && paramDialogUser === String(user.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogUser');
					setSearchParams(searchParams);
				}}
				user={user}
			/>
		</>
	);
};
