// react
import { useContext } from 'react';

// types
import { Account, Entity } from '../../../../@types';

// router
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Link, Typography } from '@mui/material';

// ui
import { Table } from '../../../ui/Table/Table';

// props
export interface EntityFinancing {
	idWbg: string;
	accounts: Account[];
	parent: Entity | null;
}
export interface TableBasicFinancingProps {
	data: EntityFinancing[];
}

export const TableBasicFinancing = ({ data = [] }: TableBasicFinancingProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);

	const headers = [
		{
			label: t('loan'),
			value: 'idWbg',
			render: (idWbg: string) => {
				return <Typography fontWeight={600}>{idWbg}</Typography>;
			},
		},
		{
			label: t('parentEntity'),
			value: 'parent',
			render: (parentEntity: Entity) => {
				return parentEntity ? (
					<Link
						component={RouterLink}
						fontWeight={600}
						to={`/project/${project?.idWbg}/entity/${parentEntity.id}`}
						underline="hover">
						{parentEntity.name}
					</Link>
				) : (
					<Typography>{t('na')}</Typography>
				);
			},
		},
		{
			label: t('accounts'),
			value: 'accounts',
			render: (accounts: Account[]) => {
				return accounts.map((account: Account) => account.name).join(', ');
			},
		},
	];
	return <Table data={data} headers={headers} />;
};
