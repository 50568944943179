// react
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

// types
import { CountryCode } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';
import { supportedLngs } from '../../../i18n';

// router
import { NavLink, Link as RouterLink } from 'react-router-dom';

// mui
import {
	Box,
	ClassNameMap,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';

// ui
import { Flag } from '../Flag/Flag';

// icons
import {
	ChevronLeft as IconChevronLeft,
	ChevronRight as IconChevronRight,
	ClearOutlined as IconClear,
	FolderOutlined as IconFolder,
	TranslateOutlined as IconTranslate,
} from '@mui/icons-material';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';

// styles
import useStyles from './Nav.styles';

// props
export interface NavItem {
	className?: string;
	icon: ReactNode;
	label: string;
	onClick?: MouseEventHandler;
	to?: string;
}

export interface NavProps {
	isOpen?: boolean;
	onClose?: MouseEventHandler;
}

export const Nav = ({ isOpen, onClose }: NavProps) => {
	// hooks
	const styles: ClassNameMap = useStyles();
	const { i18n, t } = useTranslation();

	// state
	const [anchorLang, setAnchorLang] = useState<null | HTMLElement>(null);
	const [isExpanded, setIsExpanded] = useState(false);

	// vars
	const classes = [styles.nav];
	const classesDrawer = [styles.drawer];
	const classesLogoWrapper = [styles.logoWrapper];
	const classesLogoType = [styles.logoType];
	const classesOverlay = [styles.overlay];

	const handleClickNavItem = (e: MouseEvent<HTMLButtonElement>) => {
		setIsExpanded(false);

		if (onClose) {
			onClose(e);
		}
	};

	const handleClickLang = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchorLang(e.currentTarget);
	};

	const handleChangeLang = (e: MouseEvent<HTMLLIElement>, lang: string) => {
		// change lang
		i18n.changeLanguage(lang);

		// close lang menu
		setAnchorLang(null);

		// close nav
		if (onClose) {
			onClose(e);
		}
	};

	const items = [
		{
			icon: <IconFolder />,
			label: t('projects'),
			to: '/projects',
		},
	];

	const itemsFooter = [
		{
			className: styles.btnItemToggle,
			icon: isExpanded ? <IconChevronLeft /> : <IconChevronRight />,
			label: isExpanded ? t('collapse') : t('expand'),
			onClick: () => {
				setIsExpanded(!isExpanded);
			},
		},
		{
			icon: <IconTranslate />,
			id: 'btn-language',
			label: t(`languages.${i18n.language}`),
			onClick: handleClickLang,
		},
	];

	const languageFlags: Record<string, CountryCode> = {
		en: 'US',
		es: 'ES',
		fil: 'PH',
		bn: 'BD',
		ja: 'JP',
		ro: 'MD',
		sw: 'KE',
	};

	const renderItems = (items: NavItem[]) => {
		return items.map((item, i) => {
			const { className, icon, label, ...itemProps } = item;
			const classesBtnItem = [styles.btnItem];
			const classesBtnItemText = [styles.btnItemText];

			// eslint-disable-next-line
			let Component: any = 'button';

			if (className) {
				classesBtnItem.push(className);
			}

			if (isExpanded) {
				classesBtnItem.push(styles.btnItemExpanded);
				classesBtnItemText.push(styles.btnItemTextExpanded);
			}

			if (item.to) {
				Component = NavLink;
			}

			const btn = (
				<Component
					className={classesBtnItem.join(' ')}
					key={`nav-item-${i}`}
					onClick={(e: MouseEvent<HTMLButtonElement>) => handleClickNavItem(e)}
					{...itemProps}>
					{icon}
					<Typography className={classesBtnItemText.join(' ')} component="div" fontWeight={600}>
						{label}
					</Typography>
				</Component>
			);

			if (!isExpanded) {
				return (
					<Tooltip key={`nav-item-tooltip-${i}`} placement="right" title={label}>
						{btn}
					</Tooltip>
				);
			}

			return btn;
		});
	};

	if (isExpanded) {
		classesDrawer.push(styles.drawerExpanded);
		classesLogoWrapper.push(styles.logoWrapperExpanded);
		classesLogoType.push(styles.logoTypeExpanded);
		classesOverlay.push(styles.overlayExpanded);
	}

	if (isOpen) {
		classesDrawer.push(styles.drawerOpen);
	}

	return (
		<div className={classes.join(' ')}>
			<div className={classesOverlay.join(' ')} onClick={() => setIsExpanded(false)} />
			<div className={classesDrawer.join(' ')}>
				<RouterLink className={classesLogoWrapper.join(' ')} to="/">
					<LogoWorldBank
						className={styles.logomark}
						hasGradient={false}
						hasLogoType={false}
						hasSubtitle={false}
					/>
					<Typography
						className={classesLogoType.join(' ')}
						component="div"
						variant={isExpanded ? 'h3' : 'h2'}>
						Funds
						<Box
							component="span"
							sx={(theme) => {
								return {
									color: theme.palette.brand.blue[400],
								};
							}}>
							Chain
						</Box>
					</Typography>
				</RouterLink>

				<nav className={styles.list}>
					<div className={styles.sublist}>{renderItems(items)}</div>
					<div className={styles.sublist}>{renderItems(itemsFooter)}</div>

					<Menu
						anchorEl={anchorLang}
						id="menu-language"
						MenuListProps={{
							'aria-labelledby': 'btn-language',
						}}
						onClose={() => setAnchorLang(null)}
						open={Boolean(anchorLang)}>
						{supportedLngs.map((lang) => {
							return (
								<MenuItem
									key={`lang-${lang}`}
									onClick={(e) => {
										handleChangeLang(e, lang);
									}}>
									<ListItemIcon>
										<Flag country={languageFlags[lang]} />
									</ListItemIcon>
									<ListItemText>{t(`languages.${lang}`)}</ListItemText>
								</MenuItem>
							);
						})}
					</Menu>
				</nav>

				<div className={styles.btnClose}>
					<IconButton color="white" onClick={onClose}>
						<IconClear />
					</IconButton>
				</div>
			</div>
		</div>
	);
};
