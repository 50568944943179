// react
import { useContext } from 'react';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableDocuments } from '../../../../tables/cards/TableDocuments/TableDocuments';

// context
import { ContextDocumentsBeneficiaries } from '../../Project.context';

export const Documents = () => {
	// context
	const {
		documentsBeneficiaries,
		isLoadingDocumentsBeneficiaries,
		paginationDocumentsBeneficiaries,
	} = useContext(ContextDocumentsBeneficiaries);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableDocuments
						documents={documentsBeneficiaries}
						entityType={'BENEFICIARY'}
						isLoading={isLoadingDocumentsBeneficiaries}
						pagination={paginationDocumentsBeneficiaries}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
