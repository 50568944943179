// react
import { forwardRef, Ref, WheelEvent } from 'react';

// mui
import { InputAdornment, InputLabel, TextField, TextFieldProps } from '@mui/material';

// props
export type InputProps = Omit<TextFieldProps, 'ref'> & {
	id?: string;
	currency?: string;
	ref?: Ref<HTMLInputElement>;
	type?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
	(
		{
			id,
			currency,
			defaultValue,
			disabled,
			fullWidth = true,
			label,
			required = false,
			size = 'small',
			type,
			variant = 'outlined',
			...props
		},
		ref
	) => {
		const textFieldProps = {
			id,
			defaultValue,
			disabled,
			fullWidth,
			inputRef: ref,
			required,
			size,
			type,
			variant,
			...props,
		};

		if (type === 'number') {
			textFieldProps.onWheel = (e: WheelEvent<HTMLDivElement>) => {
				const target = e.target as HTMLDivElement;
				return target.blur();
			};

			textFieldProps.InputProps = {
				endAdornment: currency && <InputAdornment position="end">{currency}</InputAdornment>,
				inputProps: {
					min: 0,
					step: '0.01',
				},
			};
		}
		return (
			<>
				{label && (
					<InputLabel disabled={disabled} htmlFor={id} required={required}>
						{label}
					</InputLabel>
				)}
				<TextField {...textFieldProps} />
			</>
		);
	}
);
