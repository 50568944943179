// i18n
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

export const supportedLngs = ['bn', 'en', 'es', 'fil', 'ja', 'ro', 'sw'];

i18n
	.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		lng: 'en',
		supportedLngs,
	});

export default i18n;
