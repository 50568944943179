// mui
import { Link } from '@mui/material';

// props
export interface LinkPhoneProps {
	phone: number | string;
}

export const LinkPhone = ({ phone }: LinkPhoneProps) => {
	return (
		<Link href={`tel:${phone}`} sx={{ wordBreak: 'break-word' }} underline="hover">
			{phone}
		</Link>
	);
};
