// react
import { MouseEvent, MouseEventHandler, ReactNode, useState } from 'react';

// mui
import { Fab, FabProps, Paper, Popover } from '@mui/material';

// icons
import { AddOutlined as IconAdd } from '@mui/icons-material';
import { Menu, MenuItemProps } from '../Menu/Menu';

// props
export interface BtnFabProps extends FabProps {
	icon?: ReactNode;
	items?: MenuItemProps[];
}

export const BtnFab = ({ icon, items, ...props }: BtnFabProps) => {
	// state
	const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);

	const handleClickMenuItem = (e: MouseEvent, onClick: MouseEventHandler<Element> | undefined) => {
		// close menu
		setAnchorMenu(null);

		if (onClick) {
			onClick(e);
		}
	};

	let itemsSanitized: MenuItemProps[] = [];

	if (items) {
		itemsSanitized = items.map((item) => {
			return {
				...item,
				onClick: (e: MouseEvent) => handleClickMenuItem(e, item.onClick),
			};
		});
	}

	return (
		<>
			<Fab
				aria-label="Open Menu"
				onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorMenu(e.currentTarget)}
				{...props}>
				{icon || <IconAdd />}
			</Fab>
			<Popover
				anchorEl={anchorMenu}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={() => setAnchorMenu(null)}
				open={Boolean(anchorMenu)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Paper>
					<Menu items={itemsSanitized} />
				</Paper>
			</Popover>
		</>
	);
};
