// react
import { ReactNode } from 'react';

// router
import { LinkProps } from 'react-router-dom';

// mui
import {
	ListItemIcon,
	ListItemText,
	ListItemTextProps,
	MenuItem,
	MenuItemProps as MuiMenuItemProps,
	MenuList,
	MenuListProps,
} from '@mui/material';

// props
export interface MenuItemProps extends MuiMenuItemProps {
	icon?: ReactNode;
	ListItemTextProps?: ListItemTextProps;
	to?: LinkProps['to'];
}

export interface MenuProps extends MenuListProps {
	items: MenuItemProps[];
}

export const Menu = ({ items }: MenuProps) => {
	return (
		<MenuList>
			{items.map((item, i) => {
				const { children, icon, ListItemTextProps, ...menuItemProps } = item;
				return (
					<MenuItem {...menuItemProps} key={`menu-item-${i}`}>
						{icon && <ListItemIcon>{icon}</ListItemIcon>}
						<ListItemText
							primary={children}
							primaryTypographyProps={{
								color: 'brand.grey.800',
							}}
							secondaryTypographyProps={{
								color: 'brand.grey.500',
								variant: 'caption',
							}}
							{...ListItemTextProps}
						/>
					</MenuItem>
				);
			})}
		</MenuList>
	);
};
