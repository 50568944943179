// react
import { SyntheticEvent, useState } from 'react';

// types
import { Entity, Loan, MetadataField, Payment, Transaction, User } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Box, Grid, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// tables
import { TableBasicUsers } from '../../tables/basic/TableBasicUsers/TableBasicUsers';
import { TableBasicPayments } from '../../tables/basic/TableBasicPayments/TableBasicPayments';
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';

// ui
import { BlockchainHash } from '../../ui/BlockchainHash/BlockchainHash';
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Metadata } from '../../ui/Metadata/Metadata';

// dialogs
import { DialogAddTransaction } from '../../dialogs/DialogAddTransaction/DialogAddTransaction';

// links
import { LinkEntity } from '../../links/LinkEntity/LinkEntity';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// utils
import { formatCurrency } from '../../../utils/currency';
import { translateType } from '../../../utils/translations';

// theme
import { theme } from '../../../theme';

// props
export interface CardBeneficiaryProps {
	beneficiary: Entity;
}

export const CardBeneficiary = ({ beneficiary }: CardBeneficiaryProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramDialog = searchParams.get('dialog');
	const paramDialogToEntity = searchParams.get('dialogToEntity');

	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [tabValue, setTabValue] = useState('payments');
	const [transactions, setTransactions] = useState<Transaction[]>([]);
	const [users, setUsers] = useState<User[]>([]);

	// fetch loans
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/loans?entity=${beneficiary.id}&fields=id,idWbg,entities&populate=entities`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch users
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/users?entity=${beneficiary.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}
		},
	});

	// fetch transactions
	useFetch({
		isEnabled: Boolean(beneficiary),
		url: `${process.env.REACT_APP_API_URL}/transactions?toEntity=${beneficiary.id}&populate=expenseType,payments.loan`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTransactions(res.data);
			}
		},
	});

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const associatedEntity = loans?.[0]?.entities?.[0]?.parent;

	// metadata vars
	let metadataTotalBenefits;

	const tabs = [
		{
			label: t('payments'),
			value: 'payments',
		},
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('users'),
			value: 'users',
		},
	];

	if (beneficiary.metadata && Array.isArray(beneficiary?.metadata)) {
		metadataTotalBenefits = beneficiary?.metadata?.find(
			(metadata) => metadata.name === 'totalBenefits'
		);
		tabs.push({
			label: t('metadata'),
			value: 'metadata',
		});
	}

	const payments: Payment[] = [];

	// for each transaction
	for (let i = 0; i < transactions.length; i++) {
		// for each payment
		for (let p = 0; p < transactions[i].payments.length; p++) {
			payments.push(transactions[i].payments[p]);
		}
	}

	const btnMenuItems: MenuItemProps[] = [
		{
			children: t('recordPayment'),
			icon: <IconPaid />,
			onClick: () => {
				searchParams.set('dialog', 'recordPayment');
				searchParams.set('dialogToEntity', String(beneficiary.id));
				setSearchParams(searchParams);
			},
		},
	];
	const currency = payments?.[0]?.currency;
	const totalPaid = payments.reduce((total, payment) => {
		return (total += payment.amount);
	}, 0);

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
						overflow: 'hidden',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={beneficiary.subtype?.toLowerCase()} />
									</Grid>
									<Grid item>
										<Typography
											color="brand.grey.500"
											component="div"
											fontWeight={600}
											variant="caption">
											{beneficiary.idExternal}
										</Typography>
										<Typography component="div" fontWeight={600}>
											{beneficiary.name}
										</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('wallet')}
								value={
									beneficiary.wallet?.address ? (
										<BlockchainHash hash={beneficiary.wallet.address} />
									) : (
										t('na')
									)
								}
							/>
						</Grid>

						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('type')}
								value={<Typography>{translateType(beneficiary.subtype, t)}</Typography>}
							/>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('entity')}
								value={
									associatedEntity ? (
										<LinkEntity entity={associatedEntity} />
									) : (
										<Typography>{t('na')}</Typography>
									)
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('paid')}
								value={formatCurrency({ currency, value: totalPaid })}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('totalBenefits')}
								value={metadataTotalBenefits ? metadataTotalBenefits.value : t('na')}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('traceability')}
								value={<ChipTraceability traceability={beneficiary.traceability} />}
							/>
						</Grid>

						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								<Grid
									item
									sx={{
										position: 'absolute',
										top: 0,
										right: theme.spacing(1),
										[theme.breakpoints.up('lg')]: {
											position: 'relative',
											top: 'auto',
											right: 'auto',
										},
									}}>
									<BtnMenu items={btnMenuItems} />
								</Grid>
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="payments">
							<TableBasicPayments payments={payments} />
						</TabPanel>
						<TabPanel value="documents">
							<TableBasicDocs entity={beneficiary.id} />
						</TabPanel>
						<TabPanel value="users">
							<TableBasicUsers data={users} />
						</TabPanel>
						<TabPanel value="metadata">
							<Box
								sx={(theme) => {
									return {
										backgroundColor: theme.palette.brand.blue[100],
									};
								}}>
								<Metadata data={beneficiary?.metadata as MetadataField[]} />
							</Box>
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogAddTransaction
				isOpen={paramDialog === 'recordPayment' && paramDialogToEntity === String(beneficiary.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogToEntity');
					setSearchParams(searchParams);
				}}
				type="CASH_BENEFIT"
			/>
		</>
	);
};
