// react
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';

// mui
import { Container, Grid, Typography } from '@mui/material';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBeneficiaries, ContextProject } from '../Project.context';

// router
import { Outlet, Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';

// ui
import { Tabs } from '../../../ui/Tabs/Tabs';

// dialogs
import { DialogAddBeneficiary } from '../../../dialogs/DialogAddBeneficiary/DialogAddBeneficiary';
import { DialogUploadBeneficiaries } from '../../../dialogs/DialogUploadBeneficiaries/DialogUploadBeneficiaries';
import { DialogUploadTransactions } from '../../../dialogs/DialogUploadTransactions/DialogUploadTransactions';

export const Beneficiaries = () => {
	// hooks
	const { t } = useTranslation();
	const location = useLocation();

	// context
	const { refetchBeneficiaries } = useContext(ContextBeneficiaries);
	const { project } = useContext(ContextProject);

	// defaults
	const defaultTabValue = useMemo(() => {
		if (location.pathname.includes('documents')) {
			return 'documents';
		}
		return 'details';
	}, [location]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState(defaultTabValue);

	// vars
	const paramDialog = searchParams.get('dialog');
	const urlBase = `/project/${project?.idWbg}/beneficiaries`;
	const tabs = [
		{
			component: RouterLink,
			label: t('details'),
			to: `${urlBase}/details`,
			value: 'details',
		},
		{
			component: RouterLink,
			label: t('documents'),
			to: `${urlBase}/documents`,
			value: 'documents',
		},
	];

	// handlers
	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// set active tab
	useEffect(() => {
		let tabValueByLocation = 'details';

		if (location.pathname.includes('documents')) {
			tabValueByLocation = 'documents';
		}
		setTabValue(tabValueByLocation);
	}, [location]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Container maxWidth={false}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h2">{t('beneficiaries')}</Typography>
							</Grid>
						</Grid>
					</Container>
				</Grid>
				<Grid item xs={12}>
					<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
				</Grid>
				<Grid item xs={12}>
					<Outlet />
				</Grid>
			</Grid>
			<DialogAddBeneficiary
				isOpen={paramDialog === 'addBeneficiary'}
				onClose={() => {
					searchParams.delete('dialog');
					setSearchParams(searchParams);
				}}
				refetchBeneficiaries={refetchBeneficiaries}
			/>
			<DialogUploadBeneficiaries
				isOpen={paramDialog === 'uploadBeneficiaries'}
				onClose={() => {
					searchParams.delete('dialog');
					setSearchParams(searchParams);
				}}
			/>
			<DialogUploadTransactions
				isOpen={paramDialog === 'uploadTransactions'}
				onClose={() => {
					searchParams.delete('dialog');
					setSearchParams(searchParams);
				}}
			/>
		</>
	);
};
