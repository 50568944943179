// mui
import { Typography, TypographyProps } from '@mui/material';

// utils
import { formatDate } from '../../../utils/dates';

// props
export interface TimeProps {
	date: Date;
	TypographyProps?: TypographyProps;
}

export const Time = ({ date, TypographyProps }: TimeProps) => {
	if (!date) {
		return <></>;
	}

	return (
		<Typography color="brand.grey.500" component="span" {...TypographyProps}>
			<time dateTime={date.toISOString()}>{formatDate({ date })}</time>
		</Typography>
	);
};
