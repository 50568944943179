// types
import { Document, User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Paper } from '@mui/material';

// ui
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Time } from '../../ui/Time/Time';
import { UserAvatar } from '../../ui/UserAvatar/UserAvatar';

// icons
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// links
import { LinkDocument } from '../../links/LinkDocument/LinkDocument';

// props
export interface CardHistoricalTransactionProps {
	document: Document;
}

export const CardHistoricalTransaction = ({ document }: CardHistoricalTransactionProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<>
			<Paper
				sx={() => {
					return {
						border: `1px transparent solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={3}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={'document'} />
									</Grid>
									<Grid item>
										<LinkDocument document={document} underline="hover" />
									</Grid>
								</Grid>
							</Box>
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue label={t('date')} value={<Time date={new Date(document.created)} />} />
						</Grid>

						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('startDate')}
								value={<Time date={new Date(document.metadata.dateStart as Date)} />}
							/>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('endDate')}
								value={<Time date={new Date(document.metadata.dateEnd as Date)} />}
							/>
						</Grid>

						<Grid item xs={12} lg={3}>
							<RowKeyValue
								label={t('uploaded')}
								value={<UserAvatar user={document.user as User} />}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};
