// i18n
import { useTranslation } from 'react-i18next';

// types
import { User } from '../../../@types';

// material
import { Grid, Typography, Paper, Avatar } from '@mui/material';

// utils
import { getInitials } from '../../../utils/strings';

// props
interface CardUsersProps {
	title?: string;
	users: User[];
}

export const CardUsers = ({ title, users = [] }: CardUsersProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3">{title || t('users')}</Typography>
				</Grid>
				{users.length > 0 ? (
					users.map((user) => {
						return (
							<Grid item key={`user-${user.firstName}-${user.lastName}`} xs={12}>
								<Grid container spacing={1} alignItems={'center'}>
									<Grid item>
										<Avatar>{getInitials(user)}</Avatar>
									</Grid>
									<Grid item>{user.firstName}</Grid>
									<Grid item>{user.lastName}</Grid>
								</Grid>
							</Grid>
						);
					})
				) : (
					<Grid item xs={12}>
						<Typography>{t('na')}</Typography>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};
