// react
import { ReactNode } from 'react';

// types
import { Document } from '../../../@types';

// mui
import { Link, Typography } from '@mui/material';

// props
export interface LinkDocumentProps {
	children?: ReactNode;
	document: Document;
	[x: string]: unknown;
}

export const LinkDocument = ({ children, document: doc, ...props }: LinkDocumentProps) => {
	const content = children || doc.name;

	if (!doc.blob || !doc.metadata.type) {
		return <Typography component="span">{content}</Typography>;
	}

	return (
		<Link
			download={doc.name}
			fontWeight={600}
			href={`data:${doc.metadata.type};base64,${doc.blob}`}
			sx={{ wordBreak: 'break-word' }}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
