// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Grid, Typography } from '@mui/material';

// ui
import { CopyToClipboard } from '../CopyToClipboard/CopyToClipboard';

// utils
import { truncateHash } from '../../../utils/strings';

// props
export interface BlockchainHashProps {
	hash: string | undefined;
}

export const BlockchainHash = ({ hash }: BlockchainHashProps) => {
	// hooks
	const { t } = useTranslation();

	if (!hash) {
		return <Typography>{t('na')}</Typography>;
	}

	return (
		<Box
			sx={(theme) => {
				return {
					backgroundColor: theme.palette.brand.grey[100],
					border: `1px ${theme.palette.brand.grey[300]} solid`,
					borderRadius: theme.spacing(0.5),
					display: 'inline-flex',
					padding: theme.spacing(0.5, 1),
				};
			}}>
			<Grid alignItems="center" container spacing={0.5}>
				<Grid item>
					<Typography variant="caption">{truncateHash(hash)}</Typography>
				</Grid>
				<Grid item>
					<CopyToClipboard content={hash} />
				</Grid>
			</Grid>
		</Box>
	);
};
