// react
import { Fragment, MouseEvent, useState, useEffect, useContext } from 'react';

// types
import { Account, EntityUser, Loan, User } from '../../../@types';

// context
import { ContextBanner } from '../../../App.context';
import { ContextActivities, ContextLoans } from '../../pages/Project/Project.context';

// i18n
import { useTranslation } from 'react-i18next';

// material
import { Alert, Grid, Typography, Paper, Avatar, Box } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// utils
import { translateRole, translateType } from '../../../utils/translations';

// props
export interface DialogEnableLoanProps {
	isOpen: boolean;
	loan: Loan;
	onClose: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

// Remove data once users are fetched
export const DialogEnableLoan = ({ isOpen, loan, onClose }: DialogEnableLoanProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { refetchActivities } = useContext(ContextActivities);
	const { setBanner } = useContext(ContextBanner);
	const { refetchLoans } = useContext(ContextLoans);

	// state
	const [entities, setEntities] = useState<EntityUser[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// set loading
		setIsLoading(true);

		// define fetch requests array
		const fetchRequests: Promise<Response>[] = [];

		// define patch loan request
		const fetchPatchLoan = fetch(`${process.env.REACT_APP_API_URL}/loans/${loan.id}`, {
			method: 'PATCH',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify({ status: 'ENABLED' }),
		});

		// add patch loan request
		fetchRequests.push(fetchPatchLoan);

		// for each entity
		for (let t = 0; t < entities.length; t++) {
			// for each user
			for (let i = 0; i < entities[t].users.length; i++) {
				// define user
				const user = entities[t].users[i];

				// define patch user request
				const fetchPatchUser = fetch(`${process.env.REACT_APP_API_URL}/users/${user.id}`, {
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify({ status: 'INVITED' }),
				});

				// add patch user request to array
				fetchRequests.push(fetchPatchUser);
			}
		}

		console.log('FETCH REQUESTS', fetchRequests);

		// wait for all fetch requests
		Promise.all(fetchRequests)
			.then(async (responses) => {
				// define array of json responses
				const resJsonArray = [];

				// for each response
				for (let i = 0; i < responses.length; i++) {
					// read response as json
					const res = await responses[i].json();
					resJsonArray.push(res);
				}

				console.log('JSON', resJsonArray);

				// set loading
				setIsLoading(false);

				if (resJsonArray[0].data) {
					// refetch loans
					refetchLoans();

					// refetch activities
					refetchActivities();

					// set success banner
					setBanner({
						hasClose: true,
						message: t('alert.enableLoanSuccess', { id: loan.idWbg }),
						severity: 'success',
					});

					// close dialog
					if (onClose) {
						onClose(e);
					}
				}
			})
			.catch((error) => {
				let message = t('error.default');

				if (error.id === 'LOAN_UNAUTHORIZED_PATCH') {
					message = t('error.unauthorized');
				}

				// set error
				setError(message);
			});
	};

	// fetch users when loan is updated
	useEffect(() => {
		if (loan.entities) {
			const entitiesUsers: EntityUser[] = [];
			for (const entity of loan.entities) {
				if (entity.id) {
					// fetch users
					fetch(`${process.env.REACT_APP_API_URL}/users?entity=${entity.id}`, {
						headers: {
							Authorization: `Bearer ${accessToken}`,
							User: String(idToken),
						},
					})
						.then((res) => {
							return res.json();
						})
						.then((res) => {
							if (res.status === 200) {
								// define users
								const users = res.data as User[];

								// put users in entityUser
								entitiesUsers.push({
									...entity,
									users,
								});
							}
						});
				}
			}
			setEntities(entitiesUsers);
		}
	}, [accessToken, idToken, loan]);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
					variant: 'text',
				},
				{
					children: t('Enable'),
					loading: isLoading,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			content={
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography color="brand.grey.500">{t('enableLoan.intro')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={0} sx={{ overflow: 'hidden' }} variant="outlined">
							<Grid container sx={{ maxHeight: 200, overflowY: 'auto' }}>
								{entities.map((entity: EntityUser, i: number) => {
									return (
										<Grid item key={`entity-${i}`} xs={12}>
											<Box
												sx={(theme) => {
													return {
														backgroundColor: theme.palette.brand.grey[100],
														padding: theme.spacing(2),
													};
												}}>
												<Grid alignItems="center" container spacing={2}>
													<Grid item xs={6}>
														<Grid alignItems="center" container columnSpacing={1}>
															<Grid item>
																<IconCircle size="sm" type="entity" />
															</Grid>
															<Grid item flex={1}>
																<Typography fontWeight={600}>{entity.name}</Typography>
															</Grid>
														</Grid>
													</Grid>
													<Grid item xs={6}>
														<Typography>{translateType(entity.type, t)}</Typography>
													</Grid>
												</Grid>
											</Box>
											<Box
												sx={(theme) => {
													return {
														padding: theme.spacing(2),
													};
												}}>
												<Grid container alignItems="center" spacing={2}>
													{entity?.users?.length > 0 ? (
														entity.users.map((user: User, i: number) => {
															return (
																<Fragment key={`user-${i}`}>
																	<Grid item xs={6}>
																		<Grid alignItems="center" container columnSpacing={1}>
																			<Grid item>
																				<Avatar />
																			</Grid>
																			<Grid item flex={1}>
																				<Typography
																					fontWeight={
																						600
																					}>{`${user.firstName} ${user.lastName}`}</Typography>
																			</Grid>
																		</Grid>
																	</Grid>
																	<Grid item xs={6}>
																		<Typography>{translateRole(user.role, t)}</Typography>
																	</Grid>
																</Fragment>
															);
														})
													) : (
														<Grid item xs={12}>
															{t('noUsers')}
														</Grid>
													)}
												</Grid>
											</Box>
										</Grid>
									);
								})}
							</Grid>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper
							elevation={0}
							sx={(theme) => {
								return {
									overflow: 'hidden',
									padding: theme.spacing(2),
								};
							}}
							variant="outlined">
							{loan.accounts.map((account: Account, i) => {
								return (
									<Grid alignItems={'center'} container key={`account-${i}`} spacing={2}>
										<Grid item xs={6}>
											<Grid alignItems="center" container columnSpacing={1}>
												<Grid item>
													<IconCircle size="sm" type="account" />
												</Grid>
												<Grid item>
													<Typography fontWeight={600}>{account.name}</Typography>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={6}>
											<Typography>{account.institution}</Typography>
										</Grid>
									</Grid>
								);
							})}
						</Paper>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Alert severity="error">{error}</Alert>
						</Grid>
					)}
				</Grid>
			}
			IconCircleProps={{
				type: 'loan',
			}}
			isOpen={isOpen}
			onClose={onClose}
			title={{
				children: `${t('enableLoan')} ${loan.idWbg}`,
			}}
		/>
	);
};
