export const parseBase64 = (base64Str: string) => {
	const base64Url = base64Str.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64Url)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
};

// https://stackoverflow.com/a/38552302/2262604
export const parseJwt = (jwt: string) => {
	let header = null;
	let payload = null;
	let signature = null;

	// check if token has parts
	const hasParts = jwt.includes('.');

	if (hasParts) {
		// split jwt into array
		const tokenArray = jwt.split('.');

		header = parseBase64(tokenArray[0]);

		if (tokenArray[1]) {
			payload = parseBase64(tokenArray[1]);
		}

		if (tokenArray[2]) {
			signature = tokenArray[2];
		}
	} else {
		payload = parseBase64(jwt);
	}

	return { header, payload, signature };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toBase64 = (obj: any) => {
	// convert obj to string
	const str = JSON.stringify(obj);
	// convert to base64
	return window.btoa(str);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toBase64Url = (obj: any) => {
	const base64 = toBase64(obj);
	// replace chars
	return base64.replaceAll('+', '-').replaceAll('/', '_').replaceAll('=', '');
};
