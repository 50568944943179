// react
import { useContext, useMemo, useState } from 'react';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// types
import {
	Contract,
	Entity,
	ExpenseType,
	Frame,
	Loan,
	Pagination,
	Transaction,
} from '../../../../@types';

// router
import { useLocation, useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import { ContextProject } from '../../../pages/Project/Project.context';
import { ContextUser } from '../../../../App.context';

// mui
import { Grid } from '@mui/material';

// buttons
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// ui
import { EmptyProps } from '../../../ui/Empty/Empty';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardPayment } from '../../../cards/CardPayment/CardPayment';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// utils
import { translateStatus } from '../../../../utils/translations';

// unions
import { transactionStatuses } from '../../../../@types/unions';
import { FilterProps } from '../../../ui/Filters/Filters';

// props
export interface TablePaymentsProps {
	frame?: Frame;
	isLoading?: boolean;
	pagination: Pagination;
	transactions: Transaction[];
}

export const TablePayments = ({
	frame = 'PROJECT',
	isLoading,
	pagination,
	transactions,
}: TablePaymentsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const location = useLocation();

	// state
	const [contracts, setContracts] = useState<Contract[]>([]);
	const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);
	const [loans, setLoans] = useState<Loan[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();
	const [suppliers, setSuppliers] = useState<Entity[]>([]);

	// params
	const paramDialog = searchParams.get('dialog');

	// fetch expense types
	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	useFetch({
		isEnabled: Boolean(project?.id && user),
		url: `${process.env.REACT_APP_API_URL}/expense-types?project=${project?.id}&entity=${paramEntity}&populate=documentTypes`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setExpenseTypes(res.data);
			}
		},
	});

	// fetch contracts
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/contracts?fields=id,num&project=${project?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setContracts(res.data);
		},
	});

	// fetch suppliers
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/entities?fields=id,name&project=${project?.id}&type=SUPPLIER`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setSuppliers(res.data);
		},
	});

	// fetch loans
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/loans?fields=id,idWbg&project=${project?.id}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setLoans(res.data);
		},
	});

	// vars
	const headers = [
		{
			label: t('date'),
			lg: 2,
			value: 'date',
		},
		{
			label: t('expenseType'),
			lg: 1.5,
			value: 'expenseType',
		},
		{
			label: t('contract'),
			lg: 1,
			value: 'contact',
		},
	];

	if (frame === 'PROJECT') {
		headers.push({
			label: t('supplier'),
			lg: 1.5,
			value: 'toEntity',
		});
	}

	if (frame === 'SUPPLIER') {
		headers.push({
			label: t('entity'),
			lg: 1.5,
			value: 'fromEntity',
		});
	}

	headers.push(
		{
			label: t('status'),
			lg: 1.5,
			value: 'status',
		},
		{
			label: t('traceability'),
			lg: 1,
			value: 'traceability',
		},
		{
			label: t('account'),
			lg: 1,
			value: 'account',
		},
		{
			label: t('amount'),
			lg: 1.5,
			value: 'amount',
		}
	);

	const formId = 'form-payments-filters';
	const optionsContracts = contracts.map((contract) => {
		return {
			label: contract.num,
			value: String(contract.id),
		};
	});

	const optionsExpenseType = expenseTypes.map((expenseType) => {
		return {
			label: expenseType.name,
			value: String(expenseType.id),
		};
	});

	const optionsSuppliers = suppliers.map((supplier) => {
		return {
			label: supplier.name,
			value: String(supplier.id),
		};
	});

	const optionsStatus = transactionStatuses.map((status) => {
		return {
			label: translateStatus(status, t),
			value: status,
		};
	});

	const optionsLoans = loans.map((loan) => {
		return {
			label: loan.idWbg,
			value: String(loan.id),
		};
	});

	const filters: FilterProps[] = [
		{
			label: t('expenseType'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-expense-type`,
			name: 'expenseType',
			options: [
				{
					label: t('all'),
					value: 'all',
				},
				...optionsExpenseType,
			],
		},
		{
			label: t('contract'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-contract`,
			name: 'contract',
			options: [
				{
					label: t('all'),
					value: 'all',
				},
				...optionsContracts,
			],
		},
	];

	if (frame === 'PROJECT') {
		filters.push({
			label: t('supplier'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-supplier`,
			name: 'supplier',
			options: [
				{
					label: t('all'),
					value: 'all',
				},
				...optionsSuppliers,
			],
		});
	}

	filters.push(
		{
			label: t('status'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-status`,
			name: 'status',
			options: [
				{
					label: t('all'),
					value: 'all',
				},
				...optionsStatus,
			],
		},
		{
			label: t('financingSource'),
			defaultValue: 'all',
			hasNoneOption: false,
			hasSearchParam: true,
			id: `${formId}-loan`,
			name: 'loan',
			options: [
				{
					label: t('all'),
					value: 'all',
				},
				...optionsLoans,
			],
		}
	);

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [],
			content: {
				children: t('empty.payments.content'),
			},
			IconCircleProps: {
				icon: <IconPaid />,
			},
			title: { children: t('empty.payments.title') },
		};

		if (
			emptyProps.actions &&
			user?.permissions.transaction.create &&
			!location.pathname.includes('entity')
		) {
			emptyProps.actions.push({
				children: t('recordPayment'),
				onClick: () => {
					searchParams.set('dialog', 'recordPayment');
					setSearchParams(searchParams);
				},
				variant: 'contained',
			});
		}

		// redefine empty content if search params exist
		if ([...searchParams].length && !paramDialog) {
			emptyProps.content = {
				children: t('empty.default.content'),
			};
			emptyProps.IconCircleProps = {
				icon: null,
			};
		}

		return emptyProps;
	}, [location, paramDialog, searchParams, setSearchParams, user]);

	const content = useMemo(() => {
		if (isLoading || !transactions.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{transactions.map((transaction, i) => {
					return (
						<Grid item key={`payment-${i}`} xs={12}>
							<CardPayment frame={frame} payment={transaction} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, frame, isLoading, transactions]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item container xs={'auto'}>
						<BtnFilters filters={filters} formId={formId} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={transactions.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
