// material
import { Container, Grid } from '@mui/material';

// tables
import { TableTransfers } from '../../../../tables/cards/TableTransfers/TableTransfers';

export const Transfers = () => {
	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableTransfers />
				</Grid>
			</Grid>
		</Container>
	);
};
