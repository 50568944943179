// react
import { ReactNode, useContext } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Entity, Project } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Link } from '@mui/material';

// props
export interface LinkSupplierProps {
	children?: ReactNode;
	project?: Project;
	supplier: Entity;
	[x: string]: unknown;
}

export const LinkSupplier = ({ children, project, supplier, ...props }: LinkSupplierProps) => {
	// context
	const { project: projectContext } = useContext(ContextProject);

	// vars
	const content = children || supplier.name;
	const projectId = project ? project.idWbg : projectContext?.idWbg;

	return (
		<Link
			component={RouterLink}
			fontWeight={600}
			sx={{ wordBreak: 'break-word' }}
			to={`/project/${projectId}/supplier/${supplier.id}`}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
