// material
import { Container } from '@mui/material';

// forms
import { FormSearchProjects } from '../../../forms/FormSearchProjects/FormSearchProjects';

export const Onboard = () => {
	return (
		<section>
			<Container maxWidth={false}>
				<FormSearchProjects />
			</Container>
		</section>
	);
};
