// react
import { useContext } from 'react';

// context
import { ContextLoans } from '../../Project.context';

// types
import { Project } from '../../../../../@types';

// i18next
import { useTranslation } from 'react-i18next';

// material
import { Alert, Container, Grid, Typography } from '@mui/material';

// tables
import { TableLoans } from '../../../../tables/cards/TableLoans/TableLoans';

// pages
import { Summary } from './Summary/Summary';

// props
export interface LoansProps {
	project: Project;
}

export const Loans = ({ project }: LoansProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { loans } = useContext(ContextLoans);

	const numOfLoansToEnable = loans.reduce((num, loan) => {
		return loan.status === 'DISABLED' ? num + 1 : num;
	}, 0);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('financingSources')}</Typography>
				</Grid>
				{project.status === 'PENDING' && (
					<Grid item xs={12}>
						<Alert severity="warning" variant="outlined">
							{t('alert.loansCannotBeEnabled')}
						</Alert>
					</Grid>
				)}
				{project.status === 'ENABLED' && numOfLoansToEnable > 0 && (
					<Grid item xs={12}>
						<Alert severity="info" variant="outlined">
							{t('alert.loansToEnable')}
						</Alert>
					</Grid>
				)}
				<Grid item xs={12}>
					<Summary loans={loans} />
				</Grid>
				<Grid item xs={12}>
					<TableLoans />
				</Grid>
			</Grid>
		</Container>
	);
};
