// material
import { Container, Grid } from '@mui/material';

// tables
import { TableOpEx } from '../../../../tables/cards/TableOpEx/TableOpEx';

export const OperatingExpenses = () => {
	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableOpEx />
				</Grid>
			</Grid>
		</Container>
	);
};
