// react
import { useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Project } from '../../../@types';

// context
import { ContextActivities } from '../../pages/Project/Project.context';

// material
import { Grid, Typography } from '@mui/material';

// ui
import { CardLineItems } from '../../cards/CardLineItems/CardLineItems';
import { LineItemProps } from '../LineItem/LineItem';

// icons
import { TimerOutlined as IconTimer } from '@mui/icons-material';

// utils
import { constructActivityItems } from '../../../utils/activities';

// props
export interface ActivityProps {
	project: Project;
}

export const Activity = ({ project }: ActivityProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { activities, isLoadingActivities } = useContext(ContextActivities);

	// items
	const activityItems: LineItemProps[] = constructActivityItems({ activities, t });

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h2">{t('activity.title')}</Typography>
			</Grid>
			<Grid item xs={12}>
				<CardLineItems
					empty={{
						content: { children: t('empty.activity.content') },
						IconCircleProps: { icon: <IconTimer /> },
						title: { children: t('empty.activity.title') },
					}}
					isLoading={isLoadingActivities}
					items={activityItems}
				/>
			</Grid>
		</Grid>
	);
};
