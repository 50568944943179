// i18n
import { useTranslation } from 'react-i18next';

// material
import { Container, Grid } from '@mui/material';

// layouts
import { Authenticated } from '../../layouts/Authenticated/Authenticated';

// ui
import { Breadcrumbs } from '../../ui/Breadcrumbs/Breadcrumbs';
import { Title } from '../../ui/Title/Title';

// forms
import { FormSearchProjects } from '../../forms/FormSearchProjects/FormSearchProjects';

export const PageOnboard = () => {
	// hooks
	const { t } = useTranslation();

	return (
		<Authenticated>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Breadcrumbs
						crumbs={[
							{
								children: t('projects'),
								to: '/projects',
							},
						]}
						page={t('onboardProject')}
					/>
				</Grid>
				<Grid item xs={12}>
					<Title color="purple" overline={t('projects')} title={t('onboardProject')} />
				</Grid>
				<Grid item xs={12}>
					<section>
						<Container maxWidth={false}>
							<FormSearchProjects />
						</Container>
					</section>
				</Grid>
			</Grid>
		</Authenticated>
	);
};
