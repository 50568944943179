// react
import { useContext } from 'react';

// context
import { ContextProject } from '../../Project.context';

// material
import { Container, Grid } from '@mui/material';

// tables
import { TableEntities } from '../../../../tables/cards/TableEntities/TableEntities';

export const Details = () => {
	// context
	const { project } = useContext(ContextProject);

	if (!project) {
		return <></>;
	}

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableEntities project={project}></TableEntities>
				</Grid>
			</Grid>
		</Container>
	);
};
