// react
import { useContext } from 'react';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableDocuments } from '../../../../tables/cards/TableDocuments/TableDocuments';

// context
import { ContextDocumentsSuppliers } from '../../Project.context';

export const Documents = () => {
	// context
	const { documentsSuppliers, isLoadingDocumentsSuppliers, paginationDocumentsSuppliers } =
		useContext(ContextDocumentsSuppliers);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableDocuments
						documents={documentsSuppliers}
						entityType={'SUPPLIER'}
						isLoading={isLoadingDocumentsSuppliers}
						pagination={paginationDocumentsSuppliers}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
