// react
import { ReactNode, useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Form, Project } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Link, Typography } from '@mui/material';

// utils
import { translateFormName } from '../../../utils/translations';

// props
export interface LinkFormProps {
	children?: ReactNode;
	form: Form;
	project?: Project;
	[x: string]: unknown;
}

export const LinkForm = ({ children, project, form, ...props }: LinkFormProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { project: projectContext } = useContext(ContextProject);

	// vars
	const name = translateFormName(form.name, t);
	const content = children || name;
	const projectId = project ? project.idWbg : projectContext?.idWbg;

	if (!form.id) {
		return <Typography fontWeight={600}>{name}</Typography>;
	}

	return (
		<Link
			component={RouterLink}
			fontWeight={600}
			sx={{ wordBreak: 'break-word' }}
			to={`/project/${projectId}/settings/form/${form.id}`}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
