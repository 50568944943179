// material
import { Grid, Paper } from '@mui/material';

// ui
import { Empty, EmptyProps } from '../../ui/Empty/Empty';
import { LineItem, LineItemProps } from '../../ui/LineItem/LineItem';

// props
export interface CardLineItemsProps {
	empty?: EmptyProps;
	isLoading?: boolean;
	items: LineItemProps[];
}

export const CardLineItems = ({ empty, isLoading, items = [] }: CardLineItemsProps) => {
	// vars
	const emptyProps: EmptyProps = { ...empty, isLoading };

	if (isLoading) {
		emptyProps.hasContent = false;
		emptyProps.hasTitle = false;
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					height: {
						xs: 220,
						lg: 370,
					},
					padding: items.length ? 0 : theme.spacing(4, 0),
					overflowY: 'auto',
				};
			}}>
			{items.length > 0 ? (
				items.map((item: LineItemProps, i) => {
					return <LineItem {...item} isAlt={i % 2 !== 0} key={`line-item-${i}`} />;
				})
			) : (
				<Grid alignItems="center" container height="100%" justifyContent="center">
					<Grid item>
						<Empty {...emptyProps} />
					</Grid>
				</Grid>
			)}
		</Paper>
	);
};
