// material
import { CircularProgress, CircularProgressProps, Grid } from '@mui/material';

// props
const loadingVariants = ['default', 'page'];

export type LoadingVariant = (typeof loadingVariants)[number];

export interface LoadingProps {
	size?: CircularProgressProps['size'];
	variant?: LoadingVariant;
}

export const Loading = ({ size, variant = 'default' }: LoadingProps) => {
	const circularProgress = <CircularProgress color="secondary" size={size} />;

	if (variant === 'default') {
		return circularProgress;
	}

	return (
		<Grid
			alignItems="center"
			container
			justifyContent="center"
			spacing={2}
			sx={(theme) => {
				if (variant === 'page') {
					return {
						height: `calc(100vh - ${theme.variables?.headerHeight})`,
					};
				}
				return {};
			}}>
			<Grid item>{circularProgress}</Grid>
		</Grid>
	);
};
