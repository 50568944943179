// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { ActionButton, Entity, TransactionType } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';

// forms
import { FormAddTransaction } from '../../forms/FormAddTransaction/FormAddTransaction';

// props
export interface DialogAddTransactionProps {
	isOpen: boolean;
	onClose: () => void;
	type?: TransactionType;
}

export const DialogAddTransaction = ({ isOpen, onClose, type }: DialogAddTransactionProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// state
	const [entity, setEntity] = useState<Entity | null>(null);
	const [isLoading, setIsLoading] = useState(false);
	const [searchParams] = useSearchParams();

	// params
	const paramDialogToEntity = searchParams.get('dialogToEntity');

	// fetch entity
	useFetch({
		isEnabled: Boolean(paramDialogToEntity),
		url: `${process.env.REACT_APP_API_URL}/entities/${paramDialogToEntity}?fields=id,name`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntity(res.data[0]);
			}
		},
	});

	// vars
	const propsDialogActions: Partial<DialogActionsProps> = {
		actions: [
			{
				children: t('cancel'),
				onClick: onClose,
			},
		],
		title: {
			children: t('recordPayment'),
			variant: 'h3',
		},
	};

	const actionSubmit: ActionButton = {
		children: t('recordPayment'),
		form: 'form-add-payment',
		loading: isLoading,
		type: 'submit',
		variant: 'contained',
	};

	if (type === 'OPEX') {
		actionSubmit.children = t('recordOpEx');

		if (propsDialogActions.title) {
			propsDialogActions.title.children = t('recordOpEx');
		}
	}

	if (propsDialogActions.actions) {
		propsDialogActions.actions.push(actionSubmit);
	}

	if (entity) {
		propsDialogActions.subtitle = {
			children: entity.name,
		};
	}

	return (
		<DialogActions hasClose={true} isOpen={isOpen} onClose={onClose} {...propsDialogActions}>
			<FormAddTransaction onClose={onClose} setIsLoading={setIsLoading} type={type} />
		</DialogActions>
	);
};
