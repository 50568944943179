// react
import { createContext, Dispatch, SetStateAction } from 'react';

// types
import { Contract, Document, Pagination, Transaction, User } from '../../../@types';

// defaults
import { defaultPagination } from '../../../utils/defaults';

// documents
export interface ContextSupplierDocumentsProps {
	documents: Document[];
	isLoadingDocuments: boolean;
	paginationDocuments: Pagination;
	setDocuments: Dispatch<SetStateAction<Document[]>>;
	setPaginationDocuments: Dispatch<SetStateAction<Pagination>>;
	refetchDocuments: () => void;
}

export const ContextSupplierDocuments = createContext<ContextSupplierDocumentsProps>({
	documents: [],
	isLoadingDocuments: false,
	paginationDocuments: defaultPagination,
	setPaginationDocuments: () => {
		console.log('SET PAGINATION');
	},
	setDocuments: () => {
		console.log('SET DOCUMENTS');
	},
	refetchDocuments: () => {
		console.log('REFETCH DOCUMENTS');
	},
});

// contracts
export interface ContextSupplierContractsProps {
	contracts: Contract[];
	isLoadingContracts: boolean;
	paginationContracts: Pagination;
	setContracts: Dispatch<SetStateAction<Contract[]>>;
	setPaginationContracts: Dispatch<SetStateAction<Pagination>>;
	refetchContracts: () => void;
}

export const ContextSupplierContracts = createContext<ContextSupplierContractsProps>({
	contracts: [],
	isLoadingContracts: false,
	paginationContracts: defaultPagination,
	setPaginationContracts: () => {
		console.log('SET PAGINATION');
	},
	setContracts: () => {
		console.log('SET CONTRACTS');
	},
	refetchContracts: () => {
		console.log('REFETCH CONTRACTS');
	},
});

// payments
export interface ContextSupplierPaymentsProps {
	isLoadingPayments: boolean;
	paginationPayments: Pagination;
	payments: Transaction[];
	refetchPayments: () => void;
	setPaginationPayments: Dispatch<SetStateAction<Pagination>>;
	setPayments: Dispatch<SetStateAction<Transaction[]>>;
}

export const ContextSupplierPayments = createContext<ContextSupplierPaymentsProps>({
	isLoadingPayments: false,
	paginationPayments: defaultPagination,
	payments: [],
	refetchPayments: () => {
		console.log('REFETCH PAYMENTS');
	},
	setPaginationPayments: () => {
		console.log('SET PAGINATION');
	},
	setPayments: () => {
		console.log('SET PAYMENTS');
	},
});

// users
export interface ContextSupplierUsersProps {
	paginationUsers: Pagination;
	users: User[];
	isLoadingUsers: boolean;
	setPaginationUsers: Dispatch<SetStateAction<Pagination>>;
	setUsers: Dispatch<SetStateAction<User[]>>;
	refetchUsers: () => void;
}

export const ContextSupplierUsers = createContext<ContextSupplierUsersProps>({
	paginationUsers: defaultPagination,
	users: [],
	isLoadingUsers: false,
	setPaginationUsers: () => {
		console.log('SET PAGINATION');
	},
	setUsers: () => {
		console.log('SET USERS');
	},
	refetchUsers: () => {
		console.log('REFETCH USERS');
	},
});
