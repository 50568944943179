// mui
import {
	Box,
	Checkbox as MuiCheckbox,
	CheckboxProps as MuiCheckboxProps,
	Grid,
	InputLabel,
} from '@mui/material';

// props
export interface CheckboxProps extends MuiCheckboxProps {
	label?: string;
}

export const Checkbox = ({ disabled, id, label, required, ...props }: CheckboxProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					borderColor: theme.palette.divider,
					borderRadius: 2,
					borderStyle: 'solid',
					borderWidth: 1,
					padding: theme.spacing(1, 1, 1, 1.5),
				};
			}}>
			<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
				<Grid item>
					<InputLabel disabled={disabled} htmlFor={id} required={required} sx={{ margin: 0 }}>
						{label}
					</InputLabel>
				</Grid>
				<Grid item>
					<MuiCheckbox
						disabled={disabled}
						id={id}
						required={required}
						sx={{ padding: 0 }}
						{...props}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
