// react
import { MouseEvent, ReactNode } from 'react';

// types
import { ActionButton } from '../../../@types';

// material
import {
	Button,
	Dialog,
	IconButton,
	Grid,
	Typography,
	TypographyProps,
	useMediaQuery,
	DialogProps,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// icons
import { ClearOutlined as IconClear } from '@mui/icons-material';

// theme
import { theme } from '../../../theme';

// props
export interface DialogActionsProps
	extends Omit<DialogProps, 'content' | 'maxWidth' | 'open' | 'title'> {
	actions?: ActionButton[];
	alignItems?: CSSStyleDeclaration['alignItems'];
	children?: ReactNode;
	content?: ReactNode;
	hasClose?: boolean;
	IconCircleProps?: IconCircleProps;
	isOpen: boolean;
	maxWidth?: number;
	onClose: (e: MouseEvent<HTMLButtonElement>) => void;
	subtitle?: TypographyProps;
	title?: TypographyProps;
}

export const DialogActions = ({
	actions = [],
	alignItems = 'flex-start',
	children,
	content,
	hasClose = false,
	IconCircleProps,
	isOpen = false,
	maxWidth = 500,
	onClose,
	subtitle,
	title,
	...props
}: DialogActionsProps) => {
	// vars
	const isFullScreen = useMediaQuery(theme.breakpoints.down('lg'));

	const renderTitle = ({ id, title }: { id: string; title: TypographyProps }) => {
		if (!title) {
			return false;
		}

		const { children, fontWeight, variant, ...titleProps } = title;

		return (
			<Typography fontWeight={fontWeight || 600} id={id} variant={variant || 'h2'} {...titleProps}>
				{children}
			</Typography>
		);
	};

	return (
		<Dialog
			{...props}
			open={isOpen}
			onClose={onClose}
			aria-labelledby="dialog-actions-title"
			aria-describedby="dialog-actions-description"
			fullScreen={isFullScreen}
			PaperProps={{
				sx: (theme) => {
					return {
						padding: {
							xs: theme.spacing(2),
							md: theme.spacing(4),
						},
						maxWidth: isFullScreen ? '100%' : maxWidth,
					};
				},
			}}>
			<Grid container spacing={2}>
				{(IconCircleProps || title) && (
					<Grid item xs={12}>
						<Grid container direction="column" alignItems={alignItems} spacing={1}>
							{IconCircleProps && (
								<Grid item>
									<IconCircle size="lg" {...IconCircleProps} />
								</Grid>
							)}
							{(title || subtitle) && (
								<Grid item sx={{ textAlign: alignItems === 'center' ? 'center' : 'left' }}>
									{title && renderTitle({ id: 'dialog-actions-title', title })}
									{subtitle &&
										renderTitle({
											id: 'dialog-actions-subtitle',
											title: {
												color: 'brand.grey.500',
												fontWeight: 500,
												variant: 'h4',
												...subtitle,
											},
										})}
								</Grid>
							)}
						</Grid>
					</Grid>
				)}

				<Grid id="dialog-actions-description" item xs={12}>
					{children}
					{typeof content === 'string' ? <Typography>{content}</Typography> : content}
				</Grid>

				{actions.length > 0 && (
					<Grid item xs={12}>
						<Grid alignItems="center" container justifyContent="flex-end" spacing={2}>
							{actions.map((action, i) => {
								const { children, ...propsAction } = action;
								const Component = 'loading' in action ? LoadingButton : Button;
								return (
									<Grid item key={`dialog-action-btn-${i}`}>
										<Component {...propsAction}>{children}</Component>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				)}
			</Grid>
			{hasClose && (
				<IconButton
					aria-label="Close"
					onClick={onClose}
					size="small"
					sx={(theme) => {
						return {
							position: 'absolute',
							right: theme.spacing(1),
							top: theme.spacing(1),
							color: theme.palette.brand.grey[500],
						};
					}}>
					<IconClear />
				</IconButton>
			)}
		</Dialog>
	);
};
