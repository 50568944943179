// react
import { useContext } from 'react';

// context
import { ContextEntityUsers } from '../Entity.context';

// mui
import { Container } from '@mui/material';

// tables
import { TableUsers } from '../../../tables/cards/TableUsers/TableUsers';

export const Users = () => {
	// context
	const { isLoadingUsers, paginationUsers, users } = useContext(ContextEntityUsers);

	return (
		<section>
			<Container maxWidth={false}>
				<TableUsers isLoading={isLoadingUsers} pagination={paginationUsers} users={users} />
			</Container>
		</section>
	);
};
