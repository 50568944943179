// react
import { ChangeEvent, useCallback, useEffect, useState } from 'react';

// types
import { Entity, User } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Box, Checkbox, Grid, InputLabel, SxProps } from '@mui/material';

// fields
import { Select } from '../../../fields/Select/Select';

// ui
import { BtnExpand } from '../../../ui/BtnExpand/BtnExpand';
import { Table } from '../../../ui/Table/Table';
import { TableHeaderProps } from '../../../ui/TableHeaders/TableHeader/TableHeader';

// options
import { getOptionsEntityTypes } from '../../../../utils/options';

// props
export interface EntityOnboardProps {
	disabled?: boolean;
	entity: Entity;
	error?: string | null;
	onChangeEntity?: (e: ChangeEvent<HTMLInputElement>, checked: boolean, entity: Entity) => void;
	onChangeUser?: (
		e: ChangeEvent<HTMLInputElement>,
		checked: boolean,
		user: User,
		entity: Entity
	) => void;
}

export const EntityOnboard = ({
	disabled,
	entity,
	error,
	onChangeEntity,
	onChangeUser,
}: EntityOnboardProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [isChecked, setIsChecked] = useState(true);
	const [users, setUsers] = useState<number[]>([]);

	// vars
	const userEntities = entity.users as User[];
	const checkAllUsers = useCallback(() => {
		if (entity.users) {
			const usersDefault = userEntities.map((user) => Number(user.idWbg));

			setUsers(usersDefault);
		}
	}, [userEntities, entity]);
	const id = `entity-${entity.idWbg}`;

	const optionsEntityTypes = getOptionsEntityTypes(t);
	const headersUsers = [
		{
			label: t('name'),
			render: (firstName: string, header: TableHeaderProps, user: User, index: number) => {
				const id = `entity-user-${user.idWbg}`;

				return (
					<Grid alignItems="center" container spacing={1}>
						<Grid item xs="auto">
							<Checkbox
								checked={disabled ? false : users.includes(Number(user.idWbg))}
								disabled={disabled || !isChecked}
								disableRipple={true}
								id={id}
								name={id}
								onChange={(e, checked) => {
									let usersNew = [...users];

									if (checked) {
										usersNew.push(Number(user.idWbg));
									} else {
										usersNew = usersNew.filter((idWbg) => idWbg !== Number(user.idWbg));
									}

									if (onChangeUser) {
										onChangeUser(e, checked, user, entity);
									}

									setUsers(usersNew);
								}}
								sx={(theme) => {
									return {
										padding: 0,
									};
								}}
								value={user.idWbg}
							/>
						</Grid>
						<Grid item xs={true}>
							<InputLabel
								disabled={disabled}
								htmlFor={id}
								sx={(theme) => {
									return {
										margin: 0,
										whiteSpace: 'normal',
									};
								}}>
								{user.firstName} {user.lastName}
							</InputLabel>
						</Grid>
					</Grid>
				);
			},
			value: 'firstName',
		},
		{
			label: t('email'),
			value: 'email',
		},
	];

	useEffect(() => {
		checkAllUsers();
	}, [checkAllUsers]);

	return (
		<Box>
			<Box
				sx={(theme) => {
					const sx: SxProps = {
						padding: theme.spacing(2),
						minWidth: '100%',
					};

					return sx;
				}}>
				<Grid alignItems="center" container spacing={2}>
					<Grid item xs={6}>
						<Grid alignItems="center" container spacing={1}>
							<Grid item xs="auto">
								<Checkbox
									checked={disabled ? false : isChecked}
									disabled={disabled}
									disableRipple={true}
									id={id}
									name={id}
									onChange={(e, checked) => {
										if (checked) {
											checkAllUsers();
										} else {
											setUsers([]);
										}

										if (onChangeEntity) {
											onChangeEntity(e, checked, entity);
										}

										setIsChecked(!isChecked);
									}}
									sx={(theme) => {
										return {
											padding: 0,
										};
									}}
									value={entity.idWbg}
								/>
							</Grid>
							<Grid item xs={true}>
								<InputLabel
									disabled={disabled}
									htmlFor={disabled ? '' : id}
									sx={(theme) => {
										return {
											margin: 0,
											whiteSpace: 'normal',
										};
									}}>
									{entity.name}
								</InputLabel>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={6} md={5}>
						<Select
							aria-label={t('type')}
							defaultValue="PIU"
							disabled={disabled}
							hasNoneOption={false}
							id={`${id}-type`}
							name={`${id}-type`}
							options={optionsEntityTypes}
							required={true}
						/>
					</Grid>
					<Grid item xs={12} md={1}>
						<BtnExpand
							isExpanded={isExpanded}
							label={isExpanded ? t('hideUsers') : t('seeUsers')}
							onClick={() => {
								setIsExpanded(!isExpanded);
							}}
						/>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Alert severity="error">{error}</Alert>
						</Grid>
					)}
				</Grid>
			</Box>
			{isExpanded && (
				<Table
					color="grey"
					data={userEntities.sort((a, b) => {
						const aName = `${a.firstName}${a.lastName}${a.idWbg}`.replaceAll(' ', '').toLowerCase();
						const bName = `${b.firstName}${b.lastName}${b.idWbg}`.replaceAll(' ', '').toLowerCase();
						return aName > bName ? 1 : -1;
					})}
					headers={headersUsers}
				/>
			)}
		</Box>
	);
};
