// types
import { User } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Avatar, Grid, Typography } from '@mui/material';

// props
export interface UserAvatarProps {
	hasEmail?: boolean;
	user: User;
}

export const UserAvatar = ({ hasEmail, user }: UserAvatarProps) => {
	// hooks
	const { t } = useTranslation();

	let name = t('na');

	if (user) {
		if (user.firstName && user.lastName) {
			name = `${user.firstName} ${user.lastName}`;
		} else if (user.firstName) {
			name = user.firstName;
		}
	}

	return (
		<Grid alignItems="center" container spacing={1} wrap="nowrap">
			<Grid item>
				<Avatar sx={{ width: 24, height: 24 }} />
			</Grid>
			<Grid item>
				<Typography component="div">{name}</Typography>
				{hasEmail && (
					<Typography color="brand.grey.500" component="div" variant="caption">
						{user.email}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};
