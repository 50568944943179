// material
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

// material
import { Container, Link, LinkProps, Typography } from '@mui/material';

// icons
import { ChevronRight as IconChevronRight } from '@mui/icons-material';

// styles
import useStyles from './Breadcrumbs.styles';

// props

export type Crumb = LinkProps & RouterLinkProps;

export interface BreadcrumbsProps {
	crumbs: Crumb[];
	page: string | undefined;
}

export const Breadcrumbs = ({ crumbs, page }: BreadcrumbsProps) => {
	const styles = useStyles();

	// vars
	const classes = [styles.breadcrumbs];

	return (
		<nav aria-label="Breadcrumb" className={classes.join(' ')}>
			<Container maxWidth={false}>
				<ul className={styles.list}>
					{crumbs.map((link) => {
						return (
							<li className={styles.listItem} key={`crumb-${link.to}`}>
								<Link component={RouterLink} fontWeight={600} to={link.to} underline="hover">
									{link.children}
								</Link>
								<IconChevronRight />
							</li>
						);
					})}
					{page && (
						<li className={styles.listItem}>
							<Typography fontWeight={600}>{page}</Typography>
						</li>
					)}
				</ul>
			</Container>
		</nav>
	);
};
