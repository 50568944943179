// react
import { ReactNode, useContext, useMemo, useState } from 'react';

// types
import { ActionButton, Entity } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// context
import { ContextOpEx, ContextProject } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardPayment } from '../../../cards/CardPayment/CardPayment';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { PaidOutlined as IconPaid } from '@mui/icons-material';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

export const TableOpEx = () => {
	// context
	const { project } = useContext(ContextProject);
	// state
	const [entities, setEntities] = useState<Entity[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramEntity = searchParams.get('entity');
	const paramQ = searchParams.get('q');

	// context
	const { isLoadingOpEx, opEx, paginationOpEx } = useContext(ContextOpEx);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch entities
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// vars
	const formIdFilters = 'form-opex-filters';
	const headers = [
		{
			label: t('date'),
			lg: 3,
			value: 'date',
		},
		{
			label: t('entity'),
			lg: 2,
			value: 'entity',
		},
		{
			label: t('status'),
			lg: 2,
			value: 'status',
		},
		{
			label: t('account'),
			lg: 2,
			value: 'account',
		},
		{
			label: t('amount'),
			lg: 2,
			value: 'amount',
		},
	];

	// options
	const optionsEntities = entities.map((entity) => {
		return {
			label: entity.name,
			value: String(entity.id),
		};
	});

	optionsEntities.unshift({
		label: t('all'),
		value: 'all',
	});

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('recordOpEx'),
					onClick: () => {
						searchParams.set('dialog', 'recordOpEx');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.opex.content') },
			IconCircleProps: { icon: (<IconPaid />) as ReactNode },
			title: { children: t('empty.opex.title') },
		};

		if (paramEntity || paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramEntity, paramQ, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoadingOpEx || !opEx.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingOpEx} />;
		}

		return (
			<Grid container spacing={2}>
				{opEx.map((payment, i) => {
					return (
						<Grid item key={`entity-${i}`} xs={12}>
							<CardPayment payment={payment} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoadingOpEx, opEx]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item xs="auto">
						<BtnFilters
							filters={[
								{
									label: t('entity'),
									defaultValue: 'all',
									hasNoneOption: false,
									hasSearchParam: true,
									id: `${formIdFilters}-entity`,
									name: 'fromEntity',
									options: optionsEntities,
								},
							]}
							formId={formIdFilters}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={opEx.length} pagination={paginationOpEx} />
			</Grid>
		</Grid>
	);
};
