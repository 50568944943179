// i18n
import { useTranslation } from 'react-i18next';

// react
import { MouseEvent, useContext } from 'react';

// mui
import { Button, Grid } from '@mui/material';

// fields
import { Input, InputProps } from '../../fields/Input/Input';

// types
import { Entity, ErrorCause } from '../../../@types';

// context
import { ContextBanner, ContextUser } from '../../../App.context';

// props
type FormAddDocTypeProps = InputProps & {
	refetchDocumentTypes?: () => void;
};
export const FormAddDocType = ({ name, refetchDocumentTypes, ...props }: FormAddDocTypeProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);

	// vars
	const formId = 'form-add-document-type';
	let userEntity: Entity | null;
	if (user?.entity) {
		userEntity = user.entity as Entity;
	}

	// handlers
	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());
		const { name } = entries;

		if (name) {
			try {
				// fetch doc types
				const body = {
					...entries,
					entity: userEntity?.id,
				};

				const fetchDocumentType = await fetch(`${process.env.REACT_APP_API_URL}/document-types`, {
					method: 'POST',
					headers: {
						Authorization: `Bearer ${accessToken}`,
						User: String(idToken),
					},
					body: JSON.stringify(body),
				});
				const resAddDocumentType = await fetchDocumentType.json();

				if (resAddDocumentType.error) {
					throw new Error(resAddDocumentType.error.message, {
						cause: {
							id: resAddDocumentType.error.id,
						},
					});
				}

				// refetch document types
				if (refetchDocumentTypes) {
					refetchDocumentTypes();
				}

				// set success banner
				setBanner({
					hasClose: true,
					message: t('alert.documentTypeCreated', { name }),
					severity: 'success',
				});
			} catch (error) {
				const err = error as Error;
				const cause = err.cause as ErrorCause;

				let message = t('error.default');

				if (cause.id === 'DOCUMENT_TYPE_NAME_NON_EXISTENT') {
					message = t('error.documentTypeNameNonExistent');
				}

				if (cause.id === 'DOCUMENT_TYPE_UNAUTHORIZED_POST') {
					message = t('error.documentTypeUnauthorized');
				}

				// set error banner
				setBanner({
					hasClose: true,
					message: message,
					severity: 'error',
				});
			} finally {
				// reset form
				form.reset();
			}
		}
	};

	return (
		<form id={formId} name="formAddDocumentType" onSubmit={handleSubmit}>
			<Grid container spacing={2} item xs={12}>
				<Grid item xs={12} md={true}>
					<Input
						id={`${formId}-name`}
						aria-label={t('addDocumentType')}
						fullWidth
						name={'name'}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md="auto">
					<Button fullWidth={true} type="submit" variant="outlined">
						{t('addDocumentType')}
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
