// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Transaction } from '../../../@types';

// mui
import { Grid } from '@mui/material';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';
import { TableBasicTransactions } from '../../tables/basic/TableBasicTransactions/TableBasicTransactions';

// ui
import { BoxEntity } from '../../ui/BoxEntity/BoxEntity';
import { formatCurrency } from '../../../utils/currency';

// props
export interface DialogTransactionsProps extends DialogActionsProps {
	fromEntity: Entity;
	isOpen: boolean;
	onClose: () => void;
	toEntity: Entity;
	transactions: Transaction[];
}

export const DialogTransactions = ({
	fromEntity,
	isOpen,
	onClose,
	toEntity,
	transactions,
	...props
}: DialogTransactionsProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	const total = transactions.reduce((total, transaction) => {
		return (total += transaction.amount);
	}, 0);

	return (
		<DialogActions
			{...props}
			hasClose={true}
			isOpen={isOpen}
			maxWidth={800}
			onClose={onClose}
			subtitle={{
				children: `${formatCurrency({
					currency: transactions?.[0]?.currency,
					value: total,
				})} Total`,
				variant: 'body2',
			}}
			title={{
				children: t('transactions'),
				variant: 'h3',
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<BoxEntity entity={fromEntity} label={t('sending')} />
				</Grid>
				<Grid item xs={12} md={6}>
					<BoxEntity entity={toEntity} label={t('receiving')} />
				</Grid>
				<Grid item xs={12}>
					<TableBasicTransactions
						borderRadius={2}
						color="grey"
						hasBorder={true}
						transactions={transactions}
					/>
				</Grid>
			</Grid>
		</DialogActions>
	);
};
