// react
import { useState } from 'react';

// types
import { Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// headers
import { getTransactionsHeaders } from './TableBasicTransactions.headers';

// ui
import { Table, TableHeader, TableProps } from '../../../ui/Table/Table';

// props
export interface TableBasicTransactionsProps extends Omit<TableProps, 'data' | 'headers'> {
	contract?: number;
	loan?: number;
	transactions?: Transaction[];
}

export const TableBasicTransactions = ({
	loan,
	contract,
	transactions = [],
	...props
}: TableBasicTransactionsProps) => {
	// state
	const [tx, setTx] = useState<Transaction[]>(transactions);

	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const headers: TableHeader[] = getTransactionsHeaders({ t });

	// fetch documents
	let urlFetchTransactions = `${process.env.REACT_APP_API_URL}/transactions?page=1&limit=5&populate=expenseType`;

	if (contract) {
		urlFetchTransactions += `&contract=${contract}`;
	}

	if (loan) {
		urlFetchTransactions += `&loan=${loan}`;
	}

	const { isLoading } = useFetch({
		isEnabled: Boolean(contract || loan),
		url: urlFetchTransactions,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setTx(res.data);
			}
		},
	});

	return <Table {...props} data={tx} headers={headers} isLoading={isLoading} />;
};
