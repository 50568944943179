// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Account, Contract, Entity, ExpenseType, Frame, Transaction } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// context
import { ContextProject } from '../../pages/Project/Project.context';
import { ContextUser } from '../../../App.context';

// mui
import { Alert, Box, Grid, Link, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { LineItem } from '../../ui/LineItem/LineItem';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// tables
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';
import { TableBasicPayments } from '../../tables/basic/TableBasicPayments/TableBasicPayments';

// links
import { LinkSupplier } from '../../links/LinkSupplier/LinkSupplier';

// icons
import { InsertDriveFileOutlined as IconInsertDriveFile } from '@mui/icons-material';

// utils
import { formatCurrency } from '../../../utils/currency';

// theme
import { theme } from '../../../theme';
import { DialogUploadDocuments } from '../../dialogs/DialogUploadDocuments/DialogUploadDocuments';

// props
export interface CardPaymentProps {
	frame?: Frame;
	payment: Transaction;
}

export const CardPayment = ({ frame, payment }: CardPaymentProps) => {
	// params
	const [searchParams, setSearchParams] = useSearchParams();
	const paramDialog = searchParams.get('dialog');
	const paramDialogTransaction = searchParams.get('dialogTransaction');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [tabValue, setTabValue] = useState('financing');

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const isOpEx = payment.type === 'OPEX';
	const paymentFromAccount = payment.fromAccount as Account;
	const paymentContract = payment.contract as Contract;
	const paymentFromEntity = payment.fromEntity as Entity;
	const paymentToEntity = payment.toEntity as Entity;
	const tabs = [
		{
			label: t('financing'),
			value: 'financing',
		},
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('comments'),
			value: 'comments',
		},
	];
	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.document?.create && user?.permissions?.transaction?.update) {
		btnMenuItems.push({
			icon: <IconInsertDriveFile />,
			children: t('uploadDocuments'),
			onClick: () => {
				searchParams.set('dialog', 'uploadDocuments');
				searchParams.set('dialogTransaction', String(payment.id));
				setSearchParams(searchParams);
			},
		});
	}

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={isOpEx ? 3 : 2}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={isOpEx ? 'opex' : 'payment'} />
									</Grid>
									<Grid item>
										<RowKeyValue
											label={t('date')}
											value={
												payment.date ? (
													<Time date={new Date(payment.date)} />
												) : (
													<Typography>{t('na')}</Typography>
												)
											}
										/>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						{isOpEx ? (
							<Grid item xs={12} lg={2}>
								<RowKeyValue
									label={t('entity')}
									value={
										payment.fromEntity ? (
											<Link
												component={RouterLink}
												fontWeight={600}
												to={`/project/${project?.idWbg}/entity/${paymentFromEntity.id}`}
												underline="hover">
												{paymentFromEntity.name}
											</Link>
										) : (
											<Typography>{t('na')}</Typography>
										)
									}
								/>
							</Grid>
						) : (
							<>
								<Grid item xs={12} lg={1.5}>
									<RowKeyValue
										label={t('expenseType')}
										value={
											<Typography color="brand.grey.500">
												{payment.expenseType ? (payment.expenseType as ExpenseType).name : t('na')}
											</Typography>
										}
									/>
								</Grid>
								<Grid item xs={12} lg={1}>
									<RowKeyValue
										label={t('contract')}
										value={
											payment.contract ? (
												<Link
													component={RouterLink}
													fontWeight={600}
													to={`/project/${project?.idWbg}/contracts`}
													underline="hover">
													{paymentContract.num}
												</Link>
											) : (
												<Typography>{t('na')}</Typography>
											)
										}
									/>
								</Grid>
								{frame === 'SUPPLIER' ? (
									<Grid item xs={12} lg={1.5}>
										<RowKeyValue
											label={t('entity')}
											value={
												payment.fromEntity ? (
													<Link
														component={RouterLink}
														fontWeight={600}
														to={`/project/${project?.idWbg}/entity/${paymentFromEntity.id}`}
														underline="hover">
														{paymentFromEntity.name}
													</Link>
												) : (
													<Typography>{t('na')}</Typography>
												)
											}
										/>
									</Grid>
								) : (
									<Grid item xs={12} lg={1.5}>
										<RowKeyValue
											label={t('supplier')}
											value={
												payment.toEntity ? (
													<LinkSupplier supplier={paymentToEntity} />
												) : (
													<Typography>{t('na')}</Typography>
												)
											}
										/>
									</Grid>
								)}
							</>
						)}

						<Grid item xs={12} lg={isOpEx ? 2 : 1.5}>
							<RowKeyValue label={t('status')} value={<ChipStatus status={payment.status} />} />
						</Grid>
						{!isOpEx && (
							<Grid item xs={12} lg={1}>
								<RowKeyValue
									label={t('traceability')}
									value={<ChipTraceability traceability={payment.traceability} />}
								/>
							</Grid>
						)}
						<Grid item xs={12} lg={isOpEx ? 2 : 1}>
							<RowKeyValue
								label={t('account')}
								value={<Typography>{paymentFromAccount.name}</Typography>}
							/>
						</Grid>
						<Grid item xs={12} lg={isOpEx ? 2 : 1.5}>
							<RowKeyValue
								label={t('amount')}
								value={
									<Typography>
										{formatCurrency({
											currency: payment.currency,
											value: payment.amount,
										})}
									</Typography>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={{
											position: 'absolute',
											top: 0,
											right: theme.spacing(1),
											[theme.breakpoints.up('lg')]: {
												position: 'relative',
												top: 'auto',
												right: 'auto',
											},
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="financing">
							<TableBasicPayments payments={payment.payments} transaction={payment} />
						</TabPanel>
						<TabPanel value="documents">
							<TableBasicDocs transaction={payment} />
						</TabPanel>
						<TabPanel value="comments">
							{payment?.metadata?.comments ? (
								<LineItem
									content={payment?.metadata?.comments}
									IconCircleProps={{ size: 'sm', type: 'comment' }}></LineItem>
							) : (
								<Alert severity="info">{t('alert.empty')}</Alert>
							)}
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			<DialogUploadDocuments
				isOpen={paramDialog === 'uploadDocuments' && paramDialogTransaction === String(payment.id)}
				onClose={() => {
					searchParams.delete('dialog');
					searchParams.delete('dialogTransaction');
					setSearchParams(searchParams);
				}}
				transaction={payment}
			/>
		</>
	);
};
