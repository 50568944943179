// i18n
import { useTranslation } from 'react-i18next';

// material
import { Grid, Typography } from '@mui/material';

// ui
import { CardLineItems } from '../../cards/CardLineItems/CardLineItems';
import { LineItemProps } from '../LineItem/LineItem';

// icons
import { AssignmentOutlined as IconAssignment } from '@mui/icons-material';

// props
export interface TasksProps {
	items: LineItemProps[];
}

export const Tasks = ({ items = [] }: TasksProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h2">{t('tasks.title')}</Typography>
			</Grid>
			<Grid item xs={12}>
				<CardLineItems
					empty={{
						content: { children: t('empty.tasks.content') },
						IconCircleProps: { icon: <IconAssignment /> },
						title: { children: t('empty.tasks.title') },
					}}
					items={items}
				/>
			</Grid>
		</Grid>
	);
};
