// react
import { ChangeEvent, DragEvent, MouseEvent, ReactNode, useRef, useState } from 'react';

// types
import { Option } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Box, Button, Grid, InputLabel, SelectChangeEvent, Typography } from '@mui/material';

// fields
import { Select } from '../Select/Select';
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// utils
import { formatBytes } from '../../../utils/format';

// icons
import { DeleteOutlined as IconDelete } from '@mui/icons-material';

// props
export type FileEvent =
	| DragEvent<HTMLDivElement>
	| ChangeEvent<HTMLInputElement>
	| MouseEvent<HTMLButtonElement>;

export interface FilePickerProps {
	label?: ReactNode;
	maxSize?: number;
	onChange?: (e: FileEvent, files: File[]) => void;
	onChangeDocType?: (e: SelectChangeEvent<unknown>, index: number) => void;
	onRemove?: (e: MouseEvent<HTMLButtonElement>, index: number) => void;
	optionsDocTypes?: Option[];
	required?: boolean;
}

export interface FilterFilesParams {
	files: FileList | File[];
	isValid?: boolean;
}

export interface FilteredFiles {
	file: File;
	error?: 'error.maxFileSize' | 'error.fileNameInvalid';
}

export const FilePicker = ({
	label,
	maxSize = 100000000,
	onChange,
	onChangeDocType,
	onRemove,
	optionsDocTypes = [],
	required = false,
}: FilePickerProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [files, setFiles] = useState<File[]>([]);
	const [isDragOver, setIsDragOver] = useState(false);

	// refs
	const refFiles = useRef<HTMLInputElement>(null);

	const filterFiles = ({ files: filesSelected, isValid = true }: FilterFilesParams) => {
		const filesFiltered: FilteredFiles[] = [];

		// check size of each file
		for (let i = 0; i < filesSelected.length; i++) {
			const file = filesSelected[i];

			// check if files are valid
			if (isValid && file.size <= maxSize && file.name.split('.').length <= 2) {
				filesFiltered.push({ file });
			}
			// check if files are invalid
			if (!isValid && file.size > maxSize) {
				filesFiltered.push({ file, error: 'error.maxFileSize' });
			} else if (!isValid && file.name.split('.').length > 2) {
				filesFiltered.push({ file, error: 'error.fileNameInvalid' });
			}
		}

		return filesFiltered;
	};

	const handleFiles = (e: FileEvent, filesSelected: FileList) => {
		// define all files
		const filesAll = [...files].concat(Array.from(filesSelected));

		// define valid files
		const filesValid = filterFiles({
			files: filesAll,
		});

		// pass valid files to onChange
		if (onChange) {
			onChange(
				e,
				filesValid.map((value) => value.file)
			);
		}

		// set files
		setFiles(filesAll);
	};

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		if (e.dataTransfer.files) {
			handleFiles(e, e.dataTransfer.files);
		}

		setIsDragOver(false);
	};

	const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragOver(true);
	};

	const handleDragExit = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setIsDragOver(false);
	};

	const handleRemoveFile = (e: MouseEvent<HTMLButtonElement>, index: number) => {
		// remove file by index
		const filesFilteredAll = files.filter((f, i) => i !== index);

		const filesFilteredValid = filterFiles({
			files: filesFilteredAll,
		});

		// pass valid files to onChange
		if (onChange) {
			onChange(
				e,
				filesFilteredValid.map((value) => value.file)
			);
		}

		if (onRemove) {
			onRemove(e, index);
		}

		setFiles(filesFilteredAll);
	};

	const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			handleFiles(e, e.target.files);
		}
	};

	const filesValid = filterFiles({
		files,
	});

	const filesInvalid = filterFiles({
		files,
		isValid: false,
	});

	const hasDocTypes = optionsDocTypes.length > 0;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<InputLabel required={required}>{label || t('files')}</InputLabel>
				<Box
					onDragExit={handleDragExit}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
					sx={(theme) => {
						return {
							transition: `all 200ms`,
							backgroundColor: isDragOver ? theme.palette.brand.grey['200'] : null,
							border: `1px ${theme.palette.brand.grey['400']} dashed`,
							borderRadius: 1.5,
							position: 'relative',
							padding: theme.spacing(3, 2),
							textAlign: 'center',
							cursor: 'pointer',
						};
					}}>
					<Grid
						alignItems="center"
						container
						direction="column"
						justifyContent="center"
						spacing={1}>
						<Grid item>
							<IconCircle type="upload" />
						</Grid>
						<Grid item>
							<Typography fontWeight={600}>{t('uploadFiles')}</Typography>
							<Typography color="brand.grey.500" variant="caption">
								{formatBytes(maxSize)} {t('maxSize')}
							</Typography>
						</Grid>
						<Grid item>
							<Button variant="outlined">{t('browseFiles')}</Button>
						</Grid>
					</Grid>
					<InputLabel
						htmlFor="files"
						style={{
							opacity: 0,
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							margin: 0,
						}}>
						{t('files')}
					</InputLabel>
					<input
						accept=".csv, .doc, .docx, image/*, .pdf, .pptx, .rtf, .tar, .txt, .webp, .xls, .xlsx, .xml"
						id="files"
						multiple
						name="files"
						onChange={handleChangeInput}
						ref={refFiles}
						style={{ visibility: 'hidden', position: 'fixed', left: '-9999px' }}
						type="file"
					/>
				</Box>
			</Grid>
			{filesValid.length > 0 && (
				<Grid item xs={12}>
					<Box
						sx={(theme) => {
							return {
								border: `1px ${theme.palette.brand.grey['400']} solid`,
								borderRadius: 1.5,
							};
						}}>
						<Grid container>
							{filesValid.map((value, i) => {
								const file = value.file;
								const isLast = i === files.length - 1;
								return (
									<Grid item key={`file-${file.size}-${i}`} xs={12}>
										<Box
											sx={(theme) => {
												return {
													borderBottom: !isLast
														? `1px ${theme.palette.brand.grey['400']} solid`
														: null,
													padding: theme.spacing(2),
												};
											}}>
											<Grid
												alignItems="center"
												container
												justifyContent="space-between"
												spacing={2}>
												<Grid item xs={hasDocTypes ? 5 : 10}>
													<Typography fontWeight={600} variant="caption">
														{file.name}
													</Typography>
													<Typography color="brand.grey.500" component="div" variant="caption">
														{formatBytes(file.size)}
													</Typography>
												</Grid>
												<Grid item xs={hasDocTypes ? 7 : 2}>
													<Grid container justifyContent="flex-end" spacing={2}>
														{hasDocTypes && (
															<Grid item xs={9}>
																<Select
																	defaultValue={optionsDocTypes?.[0]?.value}
																	fullWidth={true}
																	hasNoneOption={false}
																	name={`file-${i}-doc-type`}
																	onChange={(e) => {
																		if (onChangeDocType) {
																			onChangeDocType(e, i);
																		}
																	}}
																	options={optionsDocTypes}
																	required={true}
																	size="small"
																/>
															</Grid>
														)}
														<Grid item xs="auto">
															<Button
																className="icon"
																onClick={(e) => handleRemoveFile(e, i)}
																sx={{ padding: `5px` }}
																variant="outlined">
																<IconDelete />
															</Button>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										</Box>
									</Grid>
								);
							})}
						</Grid>
					</Box>
				</Grid>
			)}
			{filesInvalid.length > 0 && (
				<Grid item xs={12}>
					<Alert
						onClose={() => {
							// remove invalid files
							setFiles(filesValid.map((value) => value.file));
						}}
						severity="error">
						{['error.maxFileSize', 'error.fileNameInvalid'].map((error) => {
							return (
								filesInvalid.filter((value) => value.error == error).length > 0 && (
									<>
										<Typography>{t(error)}</Typography>

										<Box
											component="ul"
											sx={(theme) => {
												return {
													margin: theme.spacing(1, 0, 0, 0),
												};
											}}>
											{filesInvalid
												.filter((value) => value.error == error)
												.map((value) => {
													return <li>{value.file.name}</li>;
												})}
										</Box>
									</>
								)
							);
						})}
					</Alert>
				</Grid>
			)}
		</Grid>
	);
};
