// i18n
import { useTranslation } from 'react-i18next';

// fields
import { Select } from '../Select/Select';
import { SelectProps as MuiSelectProps } from '@mui/material';

// options
import { optionsCurrency } from '../../../utils/options';

export type SelectCurrencyProps = {
	hasLabel?: boolean;
} & MuiSelectProps;

export const SelectCurrency = ({ hasLabel = true, ...props }: SelectCurrencyProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Select
			aria-label={hasLabel ? undefined : t('currency')}
			label={hasLabel ? props.label || t('currency') : null}
			options={optionsCurrency}
			{...props}
		/>
	);
};
