// react
import { Dispatch, Fragment, SetStateAction } from 'react';

// types
import { CsvField, CsvRow, Option } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Divider, Grid, InputLabel, SelectChangeEvent, Typography } from '@mui/material';

// fields
import { Select } from '../../fields/Select/Select';

// theme
import { theme } from '../../../theme';

export interface FormTableFieldsProps {
	formId: string;
	fields: CsvField[];
	options: Option[];
	rows: CsvRow[];
	setFields: Dispatch<SetStateAction<CsvField[]>>;
	titleTotal?: string;
}

export const FormTableFields = ({
	formId,
	fields,
	options = [],
	rows,
	setFields,
	titleTotal,
}: FormTableFieldsProps) => {
	// hooks
	const { t } = useTranslation();

	const handleSelectField = (e: SelectChangeEvent<unknown>) => {
		const fieldName = e.target.name;
		const fieldValue = e.target.value as string;
		const field = fields.find((field) => field.name === fieldName);

		if (field) {
			// get up to 3 examples from beneficiaries
			const examples: string[] = [];
			const limit = rows.length >= 3 ? 3 : rows.length;

			// for number of examples
			for (let i = 0; i < limit; i++) {
				const example = rows[i][fieldValue];

				if (example) {
					examples.push(String(example));
				}
			}

			const updatedFields: CsvField[] = fields.map((field) => {
				if (field.name === fieldName) {
					return {
						...field,
						header: fieldValue,
						examples,
					};
				}
				return field;
			});

			setFields(updatedFields);
		}
	};

	const total = rows.length;

	return (
		<Grid container spacing={2}>
			<Grid item xs={3}>
				<Typography component="div" variant="h6">
					{t('field')}
				</Typography>
			</Grid>
			<Grid item xs={4.5}>
				<Typography component="div" variant="h6">
					{t('column')}
				</Typography>
			</Grid>

			<Grid item xs={4.5}>
				<Typography component="div" variant="h6">
					{t('preview')}
				</Typography>
			</Grid>

			{fields.map((field, index) => {
				return (
					<Fragment key={`field-${index}-${field.name}`}>
						<Grid item xs={12}>
							<Divider />
						</Grid>

						<Grid item xs={12}>
							<Grid container alignItems={'center'} spacing={2}>
								<Grid item xs={3}>
									<InputLabel htmlFor={`${formId}-${field.name}`} required={field.isRequired}>
										{field.label}
									</InputLabel>
								</Grid>
								<Grid item xs={4.5}>
									<Select
										id={`${formId}-${field.name}`}
										hasNoneOption={!field.isRequired}
										helperText={field.helper}
										name={field.name}
										onChange={handleSelectField}
										options={options}
										required={field.isRequired}
									/>
								</Grid>
								<Grid item xs={4.5}>
									<Typography color="brand.grey.500" variant="caption">
										{field.examples.length ? `${field.examples.join(', ')}...` : '-'}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						{index === fields.length - 1 && (
							<Grid item xs={12}>
								<Divider />
							</Grid>
						)}
					</Fragment>
				);
			})}
			<Grid item xs={12}>
				<Typography fontWeight={600} display="inline">
					{total}
				</Typography>{' '}
				<Typography color={theme.palette.brand.grey[500]} display="inline">
					{titleTotal || t('total')}
				</Typography>
			</Grid>
		</Grid>
	);
};
