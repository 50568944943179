// react
import { useContext } from 'react';

// context
import { ContextSupplierContracts } from '../Supplier.context';

// mui
import { Container } from '@mui/material';

// tables
import { TableContracts } from '../../../tables/cards/TableContracts/TableContracts';

export const Contracts = () => {
	// context
	const { contracts, paginationContracts } = useContext(ContextSupplierContracts);

	return (
		<section>
			<Container maxWidth={false}>
				<TableContracts contracts={contracts} frame="SUPPLIER" pagination={paginationContracts} />
			</Container>
		</section>
	);
};
