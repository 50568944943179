// types
import { CsvRow } from '../@types';

// props
export interface CsvToArrProps {
	string: string;
	separator: string;
}

export const csvToArray = ({ string, separator }: CsvToArrProps) => {
	let array = string
		.trim()
		.split('\n')
		.filter((item) => {
			// filter out empty lines
			const itemWithoutCommas = item.trim().replaceAll(',', '');
			return itemWithoutCommas !== '';
		})
		.map((item) => item.split(separator));

	array = array.map((a) => {
		return a.map((b) => {
			return b.trim();
		});
	});

	return array;
};

export interface ParseCsvProps {
	string: string;
	separator: string;
}

export const parseCsv = ({ string, separator }: ParseCsvProps) => {
	const array = csvToArray({ string, separator });
	const headers = array.shift() as string[];
	const rows: CsvRow[] = [];

	if (array) {
		// for each row
		for (let a = 0; a < array.length; a++) {
			const rowArray = array[a];
			const row: CsvRow = {};

			// for each header
			for (let h = 0; h < headers.length; h++) {
				const header = headers[h] as string;

				// add header and array value to row
				row[header] = rowArray[h];
			}

			// add row
			rows.push(row);
		}
	}

	return { rows, headers };
};
