// types
import { CountryCode } from '../../../@types';
import { countryCodes } from '../../../@types/unions';

// mui
import { Box } from '@mui/material';

// props
export interface FlagProps {
	country: CountryCode;
}

export const Flag = ({ country }: FlagProps) => {
	if (!countryCodes.includes(country)) {
		return <></>;
	}

	return (
		<Box
			component="span"
			sx={{
				'& img': {
					display: 'block',
					width: 'auto',
					height: 18,
				},
			}}>
			<img alt={country} src={`/img/flags/${country.toLowerCase()}.svg`} />
		</Box>
	);
};
