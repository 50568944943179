// react
import { ReactNode, useContext } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Entity, Project } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// mui
import { Link, Typography } from '@mui/material';

// props
export interface LinkEntityProps {
	children?: ReactNode;
	entity: Entity;
	project?: Project;
	[x: string]: unknown;
}

export const LinkEntity = ({ children, entity, project, ...props }: LinkEntityProps) => {
	// context
	const { project: projectContext } = useContext(ContextProject);

	// vars
	const content = children || entity.name;
	const projectId = project ? project.idWbg : projectContext?.idWbg;

	if (!entity.id) {
		return <Typography fontWeight={600}>{entity.name}</Typography>;
	}

	return (
		<Link
			component={RouterLink}
			fontWeight={600}
			sx={{ wordBreak: 'break-word' }}
			to={`/project/${projectId}/entity/${entity.id}`}
			underline="hover"
			{...props}>
			{content}
		</Link>
	);
};
