// react
import { useContext } from 'react';

// context
import { ContextSupplierDocuments } from '../Supplier.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TableDocuments } from '../../../tables/cards/TableDocuments/TableDocuments';

export const Documents = () => {
	// context
	const { documents, isLoadingDocuments, paginationDocuments } =
		useContext(ContextSupplierDocuments);

	return (
		<section>
			<Container maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TableDocuments
							documents={documents}
							entityType={'SUPPLIER'}
							frame={'SUPPLIER'}
							isLoading={isLoadingDocuments}
							pagination={paginationDocuments}
						/>
					</Grid>
				</Grid>
			</Container>
		</section>
	);
};
