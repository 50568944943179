// types
import { TraceabilityTransactions } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// material
import { Grid, Paper, Typography } from '@mui/material';

// props
interface CardTraceabilityProps {
	traceability: TraceabilityTransactions;
}

export const CardTraceability = ({ traceability }: CardTraceabilityProps) => {
	// hooks
	const { t } = useTranslation();

	// define default colors
	const colors = {
		background: 'brand.grey.100',
		border: 'brand.grey.300',
		color: 'brand.grey.700',
	};

	let documented = 0;
	let total = 0;

	if (traceability) {
		if (traceability.value) {
			if (traceability.value >= 1 && traceability.value <= 49) {
				colors.background = `brand.orange.100`;
				colors.border = `brand.orange.300`;
				colors.color = `brand.orange.700`;
			} else if (traceability.value >= 50 && traceability.value <= 70) {
				colors.background = `brand.yellow.100`;
				colors.border = `brand.yellow.300`;
				colors.color = `brand.yellow.700`;
			} else if (traceability.value >= 71) {
				colors.background = `brand.green.100`;
				colors.border = `brand.green.300`;
				colors.color = `brand.green.700`;
			}
		}

		if (traceability.transactions) {
			documented = traceability.transactions.documented;
			total = traceability.transactions.total;
		}
	}

	// vars
	return (
		<Paper
			elevation={0}
			sx={(theme) => {
				return {
					color: colors.color,
					backgroundColor: colors.background,
					border: `2px transparent solid`,
					borderColor: colors.border,
					height: '100%',
					padding: {
						xs: theme.spacing(2),
						lg: theme.spacing(3),
					},
				};
			}}>
			<Grid container direction="column" justifyContent="flex-end" height="100%">
				<Grid item>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="display">{`${traceability.value || 0}%`}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h3">{t('traceabilityIndex')}</Typography>
							<Typography>
								{documented} {t('outOf')} {total}{' '}
								{traceability.value === 100 || traceability.value === 0
									? t('transactionsDocumented')
									: t('transactionsPartiallyDocumented')}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};
