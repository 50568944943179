interface FormatCurrencyParams {
	currency?: string;
	decimals?: number;
	format?: 'compact' | 'standard';
	lang?: string;
	value: number;
}

export const formatCurrency = ({
	currency = 'USD',
	decimals = 2,
	format = 'compact',
	lang = navigator.language,
	value,
}: FormatCurrencyParams) => {
	if (format === 'compact') {
		return new Intl.NumberFormat(lang, {
			maximumFractionDigits: decimals,
			notation: 'compact',
			compactDisplay: 'short',
			style: 'currency',
			currency: currency,
		}).format(value);
	}

	return new Intl.NumberFormat(lang, {
		maximumFractionDigits: decimals,
		style: 'currency',
		currency: currency,
	}).format(value);
};
