// react
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// context
import { ContextBanner, ContextUser } from '../../../App.context';
import { ContextSuppliers, ContextProject } from '../../pages/Project/Project.context';

// types
import {
	CsvField,
	CsvRow,
	Entity,
	EntityLoan,
	EntityPayload,
	ErrorCause,
	Loan,
	Metadata,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';

// forms
import { FormTableFields } from '../FormTableFields/FormTableFields';

// fields
import { InputFile } from '../../fields/InputFile/InputFile';
import { Select } from '../../fields/Select/Select';

// ui
import { BannerProps } from '../../ui/Banner/Banner';

// util
import { parseCsv } from '../../../utils/files';

// props
export interface FormUploadSuppliersProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormUploadSuppliers = ({ onClose, setIsLoading }: FormUploadSuppliersProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// vars
	const formId = 'form-upload-suppliers';

	const fieldsSupplier: CsvField[] = [
		{
			examples: [],
			header: null,
			helper: t('idExternal.helper'),
			isEditable: false,
			isRequired: false,
			label: t('uniqueIdentifier'),
			name: 'idExternal',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('name'),
			name: 'name',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: `${t('address')} 1`,
			name: 'metadata-address1',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: false,
			label: `${t('address')} 2`,
			name: 'metadata-address2',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('city'),
			name: 'metadata-city',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('country'),
			name: 'metadata-country',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			helper: t('stateOrProvince'),
			isEditable: false,
			isRequired: false,
			label: t('region'),
			name: 'metadata-region',
			type: 'TEXT',
		},
		{
			examples: [],
			header: null,
			isEditable: false,
			isRequired: true,
			label: t('postcode'),
			name: 'metadata-postcode',
			type: 'TEXT',
		},
	];

	// context
	const { project } = useContext(ContextProject);
	const { refetchSuppliers } = useContext(ContextSuppliers);
	const { setBanner } = useContext(ContextBanner);
	const { user } = useContext(ContextUser);
	const userEntity = user ? (user.entity as Entity) : null;

	// state
	const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
	const [csvRows, setCsvRows] = useState<CsvRow[]>([]);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [error, setError] = useState<{ message: string; errors: string[] } | null>(null);
	const [fields, setFields] = useState<CsvField[]>(fieldsSupplier);
	const [loans, setLoans] = useState<Loan[]>([]);

	// vars
	const isWfa = user?.role === 'WFA';

	// fetch project loans
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg,status`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch project entities
	useFetch({
		isEnabled: Boolean(project && isWfa),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	// options
	const optionsLoans = loans.map((loan) => {
		return {
			disabled: loan.status === 'DISABLED',
			label: loan.idWbg,
			value: loan.id,
		};
	});

	const optionsEntities = entities
		.map((entity) => {
			return {
				label: entity.name,
				value: String(entity.id),
			};
		})
		.sort((a, b) => (a.label > b.label ? 1 : -1));

	const optionsHeaders = csvHeaders.map((header: string) => {
		return {
			label: header,
			value: header,
		};
	});

	// handlers
	const handleSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files?.length === 1) {
			const file = e.target.files[0];
			const reader = new FileReader();

			// process csv
			reader.onload = handleCsv;
			reader.readAsText(file);
		}
	};

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		// set loading
		setIsLoading(true);

		try {
			// define form data
			const form = document.querySelector(`#${formId}`) as HTMLFormElement;
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());

			if (entries.loan === '') {
				throw new Error('Financing source must be selected.', {
					cause: {
						id: 'FINANCING_SOURCE_NOT_SELECTED',
					},
				});
			}

			// define suppliers
			const suppliers = [];

			// define loans
			const supplierLoans: EntityLoan[] = [];
			const entryLoans = (entries.loans as string).split(',').filter((val) => val !== 'all');

			// for each loan entry
			for (let i = 0; i < entryLoans.length; i++) {
				let parent = null;

				if (isWfa && entries.entity) {
					parent = Number(entries.entity);
				} else if (userEntity) {
					parent = userEntity.id;
				}

				// add loan
				supplierLoans.push({
					id: Number(entryLoans[i]),
					parent,
				});
			}

			// for each row
			for (let i = 0; i < csvRows.length; i++) {
				const row = csvRows[i];
				const supplier: EntityPayload = {
					isKycVerified: true,
					loans: supplierLoans,
					metadata: {},
					project: project?.id,
					type: 'SUPPLIER',
					users: [],
				};

				// for each field
				for (let f = 0; f < fields.length; f++) {
					const field = fields[f];

					if (!field.header && field.required) {
						throw new Error('Select corresponding fields for all the required fields.', {
							cause: {
								id: 'BULK_UPLOAD_FIELD_EMPTY',
							},
						});
					}

					if (field.header) {
						const value = row[field.header];
						let key: string;

						if (
							field.name === 'metadata-country' &&
							field.isRequired &&
							String(value).length !== 2
						) {
							throw new Error(
								'Countries must be formatted using two-letter ISO 3166-1 alpha-2 codes.',
								{
									cause: {
										id: 'COUNTRY_CODE_INVALID',
									},
								}
							);
						}

						if (field.name.includes('metadata-')) {
							// group metadata
							key = field.name.replace('metadata-', '');
							(supplier.metadata as Metadata)[key as keyof Metadata] = value;
						} else {
							// set supplier key and value
							key = field.name;
							supplier[key] = value;
						}
					}
				}

				// add supplier
				suppliers.push(supplier);
			}

			const fetchPostSuppliers = await fetch(`${process.env.REACT_APP_API_URL}/entities`, {
				method: 'post',
				body: JSON.stringify(suppliers),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resPostSuppliers = await fetchPostSuppliers.json();

			if (resPostSuppliers.error) {
				throw new Error(resPostSuppliers.error.message, {
					cause: {
						id: resPostSuppliers.error.id,
					},
				});
			}

			const failedLength = resPostSuppliers.data.failed.length;
			const succeededLength = resPostSuppliers.data.succeeded.length;

			const banner: BannerProps = {
				hasClose: true,
				message: t('alert.suppliersUploadedPartial', {
					failed: failedLength,
					succeeded: succeededLength,
				}),
				severity: 'info',
			};

			const allSucceed = failedLength === 0;
			const allFailed = failedLength === suppliers.length;

			// refetch
			refetchSuppliers();

			if (resPostSuppliers.data.failed.length) {
				let msg = t('alert.suppliersUploadedError');
				if (allFailed) {
					msg = t('alert.suppliersUploadedError');
				} else if (succeededLength === 1 && failedLength === 1) {
					msg = t('alert.suppliersUploadedPartialOneSucceededOneFailed');
				} else if (succeededLength === 1) {
					msg = t('alert.suppliersUploadedPartialOneSucceeded', {
						failed: failedLength,
					});
				} else if (failedLength === 1) {
					msg = t('alert.suppliersUploadedPartialOneFailed', {
						succeeded: succeededLength,
					});
				} else {
					msg = t('alert.suppliersUploadedPartial', {
						failed: failedLength,
						succeeded: succeededLength,
					});
				}
				throw new Error(msg, {
					cause: {
						id: 'BULK_UPLOAD_INCOMPLETE',
						res: resPostSuppliers,
					},
				});
			}

			if (allSucceed) {
				banner.message = t('alert.suppliersUploadedSuccess');
				banner.severity = 'success';
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// set banner
			setBanner(banner);
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;
			const errors = [];

			let message = t('error.default');

			if (cause.id === 'BULK_UPLOAD_INCOMPLETE') {
				message = err.message;
				for (const err of cause.res.data.failed) {
					if (err.error.id === 'ENTITY_DUPLICATE') {
						errors.push(t('error.entityDuplicate', { id: err.payload.idExternal }));
					}
				}
			}

			if (cause.id === 'BULK_UPLOAD_FIELD_EMPTY') {
				message = t('error.bulkUploadFieldEmpty');
			}

			if (cause.id === 'COUNTRY_CODE_INVALID') {
				message = t('error.countryCodeInvalid');
			}

			if (cause.id === 'FINANCING_SOURCE_NOT_SELECTED') {
				message = t('error.financingSourceNotSelected');
			}

			// set error
			setError({ message, errors });
		} finally {
			setIsLoading(false);
		}
	};

	const handleCsv = (e: ProgressEvent<FileReader>) => {
		// define result as string
		const result = e.target?.result as string;

		if (result) {
			const { rows, headers } = parseCsv({ string: result, separator: ',' });

			// set csv headers and rows
			setCsvHeaders(headers);
			setCsvRows(rows);
		}
	};

	return (
		<form id={formId} name="formUploadSuppliers" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} lg={isWfa ? 4 : 8}>
					<InputFile
						helperText={t('acceptedFileFormats', { formats: '.csv' })}
						id={`${formId}-file-picker`}
						inputProps={{ accept: '.csv' }}
						label={t('selectFile')}
						onChange={handleSelectFile}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Select
						hasAllOption={true}
						id={`${formId}-loans`}
						label={t('financingSources')}
						multiple={true}
						name="loans"
						options={optionsLoans}
						required={true}
					/>
				</Grid>
				{isWfa && (
					<Grid item xs={12} lg={4}>
						<Select
							id={`${formId}-entity`}
							label={t('entity')}
							name="entity"
							options={optionsEntities}
							required={true}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<FormTableFields
						fields={fields}
						formId={formId}
						options={optionsHeaders}
						rows={csvRows}
						setFields={setFields}
						titleTotal={t('totalSuppliers')}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Checkbox
								defaultChecked={true}
								defaultValue="on"
								id={`${formId}-is-kyc-verified`}
								name="isKycVerified"
								required={true}
							/>
						}
						label={t('formUploadSuppliers.kycAml')}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error" sx={{ maxHeight: 300, overflowY: 'auto' }}>
							<Typography fontWeight={600}>{error.message}</Typography>
							{error.errors.length && (
								<Box component="ul">
									{error.errors.map((err) => {
										return <Box component="li">{err}</Box>;
									})}
								</Box>
							)}
						</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
