// react
import { MouseEvent, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Button } from '@mui/material';

// icons
import {
	Fullscreen as IconFullscreen,
	FullscreenExit as IconFullscreenExit,
} from '@mui/icons-material';

// props
export interface BtnFullscreenProps {
	element?: HTMLElement | null;
	onEnter?: () => void;
	onExit?: () => void;
}

export const BtnFullscreen = ({ element, onEnter, onExit }: BtnFullscreenProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isFullscreen, setIsFullscreen] = useState(false);

	// vars
	const el = element || document.documentElement;

	return (
		<Button
			aria-label={t('toggleFullscreen')}
			color="primary"
			fullWidth
			onClick={(e: MouseEvent<HTMLButtonElement>) => {
				if (!document.fullscreenElement) {
					el.requestFullscreen();
					setIsFullscreen(true);

					if (onEnter) {
						onEnter();
					}
				} else if (document.exitFullscreen) {
					document.exitFullscreen();
					setIsFullscreen(false);

					if (onExit) {
						onExit();
					}
				}
			}}
			variant="outlined">
			{isFullscreen ? <IconFullscreenExit /> : <IconFullscreen />}
		</Button>
	);
};
