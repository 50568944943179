// material
import { Grid, LinearProgress, Paper, Typography } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';
import { Loading } from '../../ui/Loading/Loading';

// props
interface CardKeyValueProps {
	IconCircleProps?: IconCircleProps;
	isLoading?: boolean;
	label: string;
	percent?: number;
	value: string | number;
}

export const CardKeyValue = ({
	IconCircleProps,
	isLoading,
	label,
	percent,
	value,
}: CardKeyValueProps) => {
	// vars
	let alignItems = 'flex-start';

	if (percent) {
		alignItems = 'flex-end';
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					height: '100%',
				};
			}}>
			<Grid alignItems={alignItems} container justifyContent="space-between" spacing={2}>
				<Grid item xs={percent ? 'auto' : true}>
					{isLoading ? (
						<Loading size={30} />
					) : (
						<Typography color="brand.blue.500" component="div" fontWeight={600} variant="h2">
							{value}
						</Typography>
					)}

					<Typography component="div" variant="body2">
						{label}
					</Typography>
				</Grid>
				{IconCircleProps && (
					<Grid item xs="auto">
						<IconCircle {...IconCircleProps} />
					</Grid>
				)}
				{percent && (
					<Grid item xs={true}>
						<div>
							<Typography
								color="brand.blue.700"
								component="div"
								style={{ marginLeft: `${percent}%` }}
								variant="body2">
								{percent}%
							</Typography>
							<LinearProgress value={percent} variant="determinate" />
						</div>
					</Grid>
				)}
			</Grid>
		</Paper>
	);
};
