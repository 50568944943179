// react
import { useContext } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextProject, ContextUsersPermissions } from '../../Project.context';

// mui
import { Container, Grid, Paper, Typography } from '@mui/material';

// forms
import { FormManageUserPermissions } from '../../../../forms/FormManageUserPermissions/FormManageUserPermissions';

// tables
import { TableBasicUsersPermissions } from '../../../../tables/basic/TableBasicUsersPermissions/TableBasicUsersPermissions';

export const Permissions = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { usersPermissions } = useContext(ContextUsersPermissions);
	const { project } = useContext(ContextProject);

	return (
		<Container maxWidth={false}>
			<Paper
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
					};
				}}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h3">{t('permissions')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormManageUserPermissions />
					</Grid>
					<Grid item xs={12}>
						<Typography fontWeight={600}>{t('userPermissions')}</Typography>
					</Grid>
					<Grid item xs={12}>
						<TableBasicUsersPermissions
							borderRadius={2}
							color="grey"
							data={usersPermissions}
							hasBorder={true}
							project={project?.id}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Container>
	);
};
