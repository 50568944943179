import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	accountHeader: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap: theme.spacing(1),
		padding: theme.spacing(3),
		textAlign: 'center',
	},
	accountPopover: {
		width: 260,
	},
	btnAccount: {
		display: 'none !important',
		[`${theme.breakpoints.up('md')}`]: {
			display: 'inline-flex !important',
		},
	},
	btnMenu: {
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'none',
		},
	},
	grid: {
		justifyContent: 'space-between',
		[`${theme.breakpoints.up('lg')}`]: {
			justifyContent: 'flex-end',
		},
	},
	header: {
		flexDirection: 'row',
		alignItems: 'center',
		borderRadius: `0 !important`,
		height: theme.variables?.headerHeight,
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		textDecoration: 'none',
		color: theme.palette.common.white,
	},
	logomark: {
		width: 30,
		height: 30,
	},
	logotype: {
		marginTop: `${theme.spacing(1)} !important`,
	},
	logoWrapper: {
		[`${theme.breakpoints.up('lg')}`]: {
			display: 'none',
		},
	},
	notificationsPopover: {
		maxWidth: 300,
		maxHeight: 300,
	},
	search: {
		display: 'none !important',
		[`${theme.breakpoints.up('md')}`]: {
			display: 'inline-flex !important',
		},
	},
	toolbar: {
		justifyContent: 'space-between',
		padding: theme.spacing(0, 2),
		width: '100%',
		height: '100%',
		minHeight: `0 !important`,
	},
}));

export default useStyles;
