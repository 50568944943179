// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useMemo, useState } from 'react';

// types
import {
	Account,
	AccountBalancePayload,
	AccountEntityPayload,
	AccountPayload,
	AccountSource,
	AccountType,
	Entity,
	EntityType,
	ErrorCause,
} from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// context
import { ContextBanner } from '../../../App.context';
import { ContextAccounts, ContextLoans, ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// icons
import { FlagOutlined as IconFlag } from '@mui/icons-material';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';

// fields
import { Input } from '../../fields/Input/Input';
import { RadioBoxes } from '../../fields/RadioBoxes/RadioBoxes';
import { Select } from '../../fields/Select/Select';

// utils
import { translateType } from '../../../utils/translations';
import { Amounts } from '../../fields/Amounts/Amounts';

// props
export interface FormAddAccountProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
	type?: EntityType;
}

export const FormAddAccount = ({ onClose, setIsLoading, type }: FormAddAccountProps) => {
	// hooks
	const { t } = useTranslation();

	// search params
	const [searchParams] = useSearchParams();

	// defaults
	const defaultType = searchParams.get('type') || 'DESIGNATED';

	// state
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [accountType, setAccountType] = useState(defaultType);
	const [entities, setEntities] = useState<Entity[]>([]);
	const [error, setError] = useState<string | null>(null);

	// context
	const { loans } = useContext(ContextLoans);
	const { refetchAccounts } = useContext(ContextAccounts);
	const { setBanner } = useContext(ContextBanner);
	const { project } = useContext(ContextProject);

	// vars
	const formId = 'form-add-account';
	const isDesignated = accountType === 'DESIGNATED';

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');
	const sessionUser = sessionStorage.getItem('user');
	const user = sessionUser ? JSON.parse(sessionUser) : null;

	// memos
	const sources: AccountSource[] = useMemo(() => {
		const sourcesArray: AccountSource[] = loans.map((loan) => {
			return {
				loan: loan.id,
				source: 'LOAN',
			};
		});

		sourcesArray.push({
			loan: null,
			source: 'OTHER',
		});

		if (accountType === 'LOCAL') {
			sourcesArray.push({
				loan: null,
				source: 'GOVERNMENT',
			});
		}
		return sourcesArray;
	}, [accountType, loans]);

	// fetch accounts
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/accounts?project=${project?.id}&populate=loan&type=DESIGNATED`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setAccounts(res.data);
		},
	});

	// fetch entities
	useFetch({
		isEnabled: Boolean(project),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name,type,wallet&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU&populate=wallet`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			setEntities(res.data);
		},
	});

	// options
	const optionsAccounts = accounts.map((account) => {
		return {
			label: account.name,
			secondary: account.institution,
			value: account.id,
		};
	});

	const optionsEntities = entities
		.filter((entity) => entity.id !== user.entity?.id)
		.map((entity) => {
			return {
				disabled: entity.wallet?.status === 'INACTIVE',
				label: entity.name,
				secondary: translateType(entity.type, t),
				value: entity.id,
			};
		});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector(`#${formId}`) as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		// define balances using sources
		const balances: AccountBalancePayload[] = [];
		let total = 0;

		for (let s = 0; s < sources.length; s++) {
			const balance = Number(entries[`source-${s}`]);
			if (balance > 0) {
				total += balance;
				balances.push({
					...sources[s],
					currency: entries.currency,
					balance,
				});
			}
		}

		// define entities
		let entities: AccountEntityPayload[] = [];

		if (entries.entities) {
			const entitiesStr = entries.entities as string;
			entities = entitiesStr.split(',').map((id) => {
				return {
					id: Number(id),
					relationship: 'ASSOCIATE',
				};
			});
		}

		// add owner entity
		entities.push({
			id: user.entity.id,
			relationship: 'OWNER',
		});

		// define accounts
		let accounts: number[] = [];
		if (entries.accounts) {
			const accountsStr = entries.accounts as string;
			accounts = accountsStr.split(',').map((id) => Number(id));
		}

		try {
			// clear error
			setError(null);

			// set loading
			setIsLoading(true);

			if (total === 0) {
				throw new Error('The account needs to have an initial balance.', {
					cause: {
						id: 'ACCOUNT_BALANCES_NON_EXISTENT',
					},
				});
			}

			const body: AccountPayload = {
				accounts,
				balances,
				currency: String(entries.currency),
				entities,
				institution: String(entries.institution),
				name: String(entries.name),
				num: String(entries.num),
				project: project?.id,
				type: entries.type as AccountType,
			};

			if (accounts.length) {
				body.accounts = accounts;
			}

			const fetchAddAccount = await fetch(`${process.env.REACT_APP_API_URL}/accounts`, {
				method: 'post',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			});
			const resAddAccount = await fetchAddAccount.json();

			if (resAddAccount.error) {
				throw new Error(resAddAccount.error.message, {
					cause: {
						id: resAddAccount.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch accounts
			refetchAccounts();

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.accountCreated', { name: entries.name }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'ACCOUNT_BALANCES_NON_EXISTENT') {
				message = t('error.accountBalancesNonExistent');
			}

			if (cause.id === 'ACCOUNT_UNAUTHORIZED_POST') {
				message = t('error.unauthorized');
			}

			// set error
			setError(message);
		} finally {
			// set loading
			setIsLoading(false);
		}
	};

	return (
		<form id={`${formId}`} name="formAddAccount" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<RadioBoxes
						defaultValue={defaultType}
						id={`${formId}-type`}
						inputs={[
							{
								icon: (
									<LogoWorldBank
										hasGradient={false}
										hasLogoType={false}
										hasSubtitle={false}
										width={20}
									/>
								),
								label: t('designatedAccount'),
								value: 'DESIGNATED',
							},
							{
								icon: <IconFlag />,
								label: t('localAccount'),
								value: 'LOCAL',
							},
						]}
						label={t('type')}
						name="type"
						onChange={(e, value) => {
							setAccountType(value);
						}}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input id={`${formId}-name`} label={t('name')} name="name" required={true} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input id={`${formId}-number`} label={t('accountNumber')} name="num" required={true} />
				</Grid>
				<Grid item xs={12} md={isDesignated ? 12 : 6}>
					<Input
						id={`${formId}-financial-institution`}
						label={t('financialInstitution')}
						name="institution"
						required={true}
					/>
				</Grid>
				{!isDesignated && (
					<Grid item xs={12} md={6}>
						<Select
							id={`${formId}-designated-accounts`}
							label={t('designatedAccounts')}
							multiple={true}
							name="accounts"
							options={optionsAccounts}
						/>
					</Grid>
				)}
				{user.entity && (
					<Grid item xs={12}>
						<Select
							defaultValue={String(user.entity.id)}
							disabled={true}
							id={`${formId}-owner`}
							label="Owner"
							name="owner"
							options={[
								{
									label: user.entity.name,
									value: String(user.entity.id),
								},
							]}
						/>
					</Grid>
				)}
				{optionsEntities.length > 0 && (
					<Grid item xs={12}>
						<Select
							helperText={t('additionalEntities.helper')}
							id={`${formId}-entities`}
							label={t('additionalEntities')}
							multiple={true}
							name="entities"
							options={optionsEntities}
						/>
					</Grid>
				)}
				<Grid item xs={12}>
					<Amounts formId={formId} loans={loans} sources={sources} title={t('balances')} />
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
