// material
import { Container } from '@mui/material';

// tables
import { TableProjects } from '../../../tables/cards/TableProjects/TableProjects';

export const Table = () => {
	return (
		<section>
			<Container maxWidth={false}>
				<TableProjects />
			</Container>
		</section>
	);
};
