import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	bgDark: {
		backgroundColor: theme.palette.secondary.dark,
		borderRadius: 8,
		padding: theme.spacing(4),
		color: theme.palette.common.white,
	},
	logo: {
		width: '100%',
		maxWidth: 260,
	},
	logoMark: {
		width: 50,
		color: theme.palette.secondary.main,
	},
	subtitle: {
		textTransform: 'capitalize',
	},
	swatch: {
		backgroundColor: theme.palette.common.black,
		borderRadius: 4,
		width: `100%`,
		height: 100,
	},
	theme: {
		margin: `${theme.spacing(3)} 0`,
	},
}));

export default useStyles;
