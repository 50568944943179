// react
import { ReactNode, useContext } from 'react';

// router
import { Link as RouterLink } from 'react-router-dom';

// types
import { Form, User } from '../../../@types';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Box, Button, Grid, Paper, Typography } from '@mui/material';

// theme
import { theme } from '../../../theme';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Time } from '../../ui/Time/Time';
import { UserAvatar } from '../../ui/UserAvatar/UserAvatar';

// links
import { LinkForm } from '../../links/LinkForm/LinkForm';

export interface RenderKeyValueParams {
	label: string;
	value: ReactNode;
}

export interface CardFormProps {
	form: Form;
}

export const CardForm = ({ form }: CardFormProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);

	return (
		<Paper
			sx={(theme) => {
				return {
					border: `1px transparent solid`,
					position: 'relative',
				};
			}}>
			<Box
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
					};
				}}>
				<Grid alignItems="center" container spacing={{ xs: 1, md: 2 }}>
					<Grid item xs={12} lg={4}>
						<Box
							sx={(theme) => {
								return {
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								};
							}}>
							<Grid alignItems="center" container spacing={2} wrap="nowrap">
								<Grid item>
									<IconCircle type="form" />
								</Grid>
								<Grid item>
									<LinkForm form={form} />
								</Grid>
							</Grid>
						</Box>
					</Grid>

					<Grid item xs={12} lg={2}>
						<RowKeyValue label={t('created')} value={<Time date={new Date(form.created)} />} />
					</Grid>
					<Grid item xs={12} lg={2}>
						<RowKeyValue
							label={t('updated')}
							value={
								form.modified ? (
									<Time date={new Date(form.modified)} />
								) : (
									<Typography>{t('na')}</Typography>
								)
							}
						/>
					</Grid>
					<Grid item xs={12} lg={2}>
						<RowKeyValue label={t('user')} value={<UserAvatar user={form.user as User} />} />
					</Grid>
					<Grid item xs={12} lg={2}>
						<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={2}>
							<Grid
								item
								sx={{
									position: 'absolute',
									top: 0,
									right: theme.spacing(2),
									[theme.breakpoints.up('lg')]: {
										position: 'relative',
										top: 'auto',
										right: 'auto',
									},
								}}>
								<Button
									color="primary"
									component={RouterLink}
									to={`/project/${project?.idWbg}/settings/form/${form.id}`}
									variant="outlined">
									{t('edit')}
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Box>
		</Paper>
	);
};
