// react
import { useContext, useState } from 'react';

// types
import { Entity, ExpenseType } from '../../../@types';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// context
import { ContextUser } from '../../../App.context';
import { ContextProject } from '../../pages/Project/Project.context';
import { SelectProps } from '../Select/Select';

// fields
import { Select } from '../Select/Select';

// props
export type SelectExpenseTypeProps = Omit<SelectProps, 'options'>;

export const SelectExpenseType = ({ ...props }: SelectExpenseTypeProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { project } = useContext(ContextProject);
	const { user } = useContext(ContextUser);

	// state
	const [expenseTypes, setExpenseTypes] = useState<ExpenseType[]>([]);

	// fetch expense types
	let paramEntity = 'null';

	if (user?.entity) {
		const userEntity = user.entity as Entity;
		paramEntity += `,${userEntity.id}`;
	}

	// fetch expense types
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/expense-types?project=${project?.id}&entity=${paramEntity}&populate=documentTypes`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setExpenseTypes(res.data);
			}
		},
	});

	const optionsExpenseTypes = expenseTypes.map((expenseType) => {
		return {
			label: expenseType.name,
			value: String(expenseType.id),
		};
	});

	return <Select {...props} options={optionsExpenseTypes} />;
};
