// types
import {
	EntitySubtype,
	EntityType,
	FinancingSource,
	FormName,
	LoanStatus,
	ProjectStatus,
	TransactionStatus,
	TransactionType,
	UserRole,
	UserStatus,
} from '../@types';

// i18
import { TFunction } from 'i18next';

export const translateFormName = (name: FormName, t: TFunction) => {
	switch (name) {
		case 'BENEFICIARY_INDIVIDUAL':
			return t('forms.beneficiaryIndividual');
		case 'BENEFICIARY_ORGANIZATION':
			return t('forms.beneficiaryOrganization');
		default:
			return name;
	}
};

export const translateRole = (role: UserRole, t: TFunction) => {
	const roles: Record<UserRole, string> = {
		GENERAL: t('general'),
		LIAISON: t('liaison'),
		STAFF: t('staff'),
		TASK_LEAD: t('taskLead'),
		WFA: t('wfa'),
	};

	return roles[role] || role;
};

export const translateStatus = (
	status: LoanStatus | ProjectStatus | TransactionStatus | UserStatus,
	t: TFunction
) => {
	const statuses: Record<LoanStatus | ProjectStatus | TransactionStatus | UserStatus, string> = {
		ACTIVE: t('active'),
		COMPLETED: t('completed'),
		CREATED: t('created'),
		DISABLED: t('disabled'),
		DONE: t('done'),
		ENABLED: t('enabled'),
		FAILED: t('failed'),
		INACTIVE: t('inactive'),
		IN_PROGRESS: t('inProgress'),
		INVITED: t('invited'),
		PENDING: t('pending'),
		STARTED: t('started'),
		WAITING: t('waiting'),
	};

	return statuses[status] || status;
};

export const translateTransactionType = (type: TransactionType, t: TFunction) => {
	const types: Record<TransactionType, string> = {
		CASH_BENEFIT: t('cashBenefit'),
		INITIAL: t('initialDeposit'),
		OPEX: t('operatingExpense'),
		PAYMENT: t('payment'),
		REPLENISHMENT: t('replenishment'),
		TRANSFER: t('transfer'),
	};

	return types[type] || type;
};

export const translateType = (type: EntityType | EntitySubtype, t: TFunction) => {
	const types: Record<EntityType | EntitySubtype, string> = {
		BENEFICIARY: t('beneficiary'),
		BORROWER: t('borrower'),
		INDIVIDUAL: t('individual'),
		INTERMEDIARY: t('intermediary'),
		ORGANIZATION: t('organization'),
		PIU: t('piu'),
		SUB_PIU: t('subPiu'),
		SUPPLIER: t('supplier'),
	};

	return types[type] || type;
};

export const translateFinancingSource = (source: FinancingSource, t: TFunction) => {
	const sources: Record<FinancingSource, string> = {
		GOVERNMENT: t('government'),
		LOAN: t('loan'),
		OTHER: t('other'),
	};

	return sources[source] || source;
};
