// fields
import { Select, SelectProps } from '../Select/Select';

import { countries } from './SelectCountry.countries';

// props
export type SelectCountryProps = Omit<SelectProps, 'options'>;

export const SelectCountry = ({ ...props }: SelectCountryProps) => {
	const optionsCountries = countries.map((country) => {
		return {
			label: country.name,
			value: country.code,
		};
	});

	return <Select defaultValue="" {...props} options={optionsCountries} />;
};
