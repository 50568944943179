import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	circle: {
		backgroundColor: theme.palette.brand.grey[200],
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.brand.grey[700],
	},
	/* sizes */
	xs: {
		width: 24,
		height: 24,
		'& svg': {
			width: 14,
			maxHeight: 14,
		},
	},
	sm: {
		width: 32,
		height: 32,
		'& svg': {
			width: 18,
			maxHeight: 18,
		},
	},
	md: {
		width: 42,
		height: 42,
		'& svg': {
			width: 24,
			maxHeight: 24,
		},
	},
	lg: {
		width: 64,
		height: 64,
		'& svg': {
			width: 32,
			maxHeight: 32,
		},
	},
	/* colors */
	blue: {
		backgroundColor: theme.palette.brand.blue[200],
		color: theme.palette.brand.blue[700],
	},
	green: {
		backgroundColor: theme.palette.brand.green[100],
		color: theme.palette.brand.green[700],
	},
	grey: {
		backgroundColor: theme.palette.brand.grey[200],
		color: theme.palette.brand.grey[700],
	},
	magenta: {
		backgroundColor: theme.palette.brand.magenta[100],
		color: theme.palette.brand.magenta[700],
	},
	orange: {
		backgroundColor: theme.palette.brand.orange[100],
		color: theme.palette.brand.orange[700],
	},
	purple: {
		backgroundColor: theme.palette.brand.purple[100],
		color: theme.palette.brand.purple[700],
	},
	teal: {
		backgroundColor: theme.palette.brand.teal[100],
		color: theme.palette.brand.teal[700],
	},
	yellow: {
		backgroundColor: theme.palette.brand.yellow[100],
		color: theme.palette.brand.yellow[700],
	},
}));

export default useStyles;
