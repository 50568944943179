// react
import { ReactNode } from 'react';

// mui
import { Box, Container, Grid, Paper, Typography } from '@mui/material';

// logos
import { LogoWorldBank } from '../../img/LogoWorldBank';

// props
export interface GatewayProps {
	children: ReactNode;
	title?: string;
}

export const Gateway = ({ children, title }: GatewayProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					backgroundColor: theme.palette.secondary.main,
					width: '100%',
					minHeight: '100vh',
				};
			}}>
			<Grid
				container
				spacing={0}
				direction="row"
				alignItems="center"
				justifyContent="center"
				sx={{ minHeight: '100vh' }}>
				<Grid item xs={12} md={5.5} lg={4} xl={3.5}>
					<Container>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
									<Grid item>
										<Box
											sx={(theme) => {
												return {
													'& svg': {
														width: 40,
														color: theme.palette.grey[100],
													},
												};
											}}>
											<LogoWorldBank hasGradient={false} hasLogoType={false} hasSubtitle={false} />
										</Box>
									</Grid>
									<Grid item>
										<Typography
											component="h3"
											sx={(theme) => {
												return {
													color: theme.palette.brand.grey[100],
												};
											}}
											variant="h3">
											Funds
											<Box
												component="span"
												sx={(theme) => {
													return {
														color: theme.palette.brand.blue[400],
													};
												}}>
												Chain
											</Box>
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<Paper
									sx={(theme) => {
										return {
											padding: theme.spacing(2),
											[theme.breakpoints.up('md')]: {
												padding: theme.spacing(3),
											},
										};
									}}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography
												component="h3"
												sx={{
													textTransform: 'capitalize',
												}}
												variant="h4">
												{title}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											{children}
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
		</Box>
	);
};
