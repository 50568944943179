// react
import { MouseEvent, MouseEventHandler, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink } from 'react-router-dom';

// hooks
import { useAuth } from '../../../hooks/useAuth';
import { useFetch } from '../../../hooks/useFetch';

// mui
import {
	AppBar,
	Avatar,
	Box,
	Button,
	Chip,
	IconButton,
	InputAdornment,
	Grid,
	ListItemText,
	MenuItem,
	MenuList,
	Popover,
	TextField,
	Toolbar,
	Typography,
} from '@mui/material';

// types
import { Activity } from '../../../@types';

// ui
import { Notifications } from './Notifications/Notifications';

// icons
import {
	ExpandMoreOutlined as IconExpandMore,
	MenuOutlined as IconMenu,
	NotificationsOutlined as IconNotifications,
	SearchOutlined as IconSearch,
} from '@mui/icons-material';

// img
import { LogoWorldBank } from '../../img/LogoWorldBank';

// utils
import { constructActivityItems } from '../../../utils/activities';
import { translateRole, translateType } from '../../../utils/translations';

// styles
import useStyles from './Header.styles';

// props
import { LineItemProps } from '../LineItem/LineItem';

// props
export interface HeaderProps {
	onOpenNav?: MouseEventHandler;
}

// types
export type Anchor = HTMLButtonElement | null;

export const Header = ({ onOpenNav }: HeaderProps) => {
	// hooks
	const { logout } = useAuth();
	const styles = useStyles();
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [activities, setActivities] = useState<Activity[]>([]);

	// fetch activities
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/activities?limit=10&populate=account,fromAccount,toAccount,contract,document,entity,loan,project,user,transaction&orderBy=created`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setActivities(res.data);
			}
		},
	});

	// items
	const activityItems: LineItemProps[] = constructActivityItems({
		activities,
		t,
	});

	// state
	const [anchorElAccount, setAnchorElAccount] = useState<Anchor>(null);
	const [anchorElNotifications, setAnchorElNotifications] = useState<Anchor>(null);

	const sessionUser = sessionStorage.getItem('user');
	const user = sessionUser ? JSON.parse(sessionUser) : null;

	let userName = 'Unknown User';
	let userEntity = null;
	let userChipLabel = null;

	if (user) {
		userName = `${user.firstName} ${user.lastName}`;

		if (user.entity) {
			userEntity = user.entity.name;
			userChipLabel = translateType(user.entity.type, t);
		} else {
			userEntity = 'World Bank Group';
			userChipLabel = translateRole(user.role, t);
		}
	}

	const handleOpenAccount = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchorElAccount(e.currentTarget);
	};

	const handleOpenNotifications = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchorElNotifications(e.currentTarget);
	};

	const handleCloseAccount = () => {
		setAnchorElAccount(null);
	};

	const handleCloseNotifications = () => {
		setAnchorElNotifications(null);
	};

	const handleLogout = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchorElAccount(null);
		logout();
	};

	return (
		<AppBar className={styles.header} color="secondary" elevation={0} position="static">
			<Toolbar className={styles.toolbar} disableGutters={true}>
				<Grid className={styles.grid} alignItems="center" container columnSpacing={2}>
					<Grid className={styles.logoWrapper} item xs={4} md={3} lg={5}>
						<RouterLink className={styles.logo} to="/">
							<LogoWorldBank
								className={styles.logomark}
								hasGradient={false}
								hasLogoType={false}
								hasSubtitle={false}
							/>
							<Typography className={styles.logotype} component="div" variant="h3">
								Funds
								<Box
									component="span"
									sx={(theme) => {
										return {
											color: theme.palette.brand.blue[400],
										};
									}}>
									Chain
								</Box>
							</Typography>
						</RouterLink>
					</Grid>

					<Grid item xs={8} md={9} lg={7}>
						<Grid
							alignItems="center"
							container
							columnSpacing={{ xs: 1, md: 2 }}
							justifyContent="flex-end">
							<Grid hidden item xs="auto" md={true}>
								<TextField
									className={styles.search}
									color="white"
									fullWidth={true}
									InputProps={{
										disableUnderline: true,
										hiddenLabel: true,
										startAdornment: (
											<InputAdornment position="start">
												<IconSearch />
											</InputAdornment>
										),
									}}
									placeholder={t('search')}
									size="small"
									variant="filled"
								/>
							</Grid>

							<Grid item xs="auto">
								<div>
									<IconButton color="white" onClick={handleOpenNotifications}>
										<IconNotifications />
									</IconButton>

									<Popover
										anchorEl={anchorElNotifications}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										onClose={handleCloseNotifications}
										open={Boolean(anchorElNotifications)}>
										<div className={styles.notificationsPopover}>
											<Notifications items={activityItems} />
										</div>
									</Popover>
								</div>
							</Grid>

							<Grid item xs="auto">
								<Grid alignItems="center" container columnSpacing={1}>
									<Grid item xs="auto">
										<button onClick={handleOpenAccount}>
											<Avatar />
										</button>
									</Grid>

									<Grid className={styles.btnAccount} item xs="auto">
										<Button
											color="white"
											endIcon={<IconExpandMore />}
											onClick={handleOpenAccount}
											variant="text">
											{userName}
										</Button>

										<Popover
											anchorEl={anchorElAccount}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left',
											}}
											onClose={handleCloseAccount}
											open={Boolean(anchorElAccount)}>
											<div className={styles.accountPopover}>
												<div className={styles.accountHeader}>
													<Avatar className="xlarge" />
													<Grid container spacing={1}>
														<Grid item xs={12}>
															<Typography variant="subtitle2">{userName}</Typography>
															{userEntity && (
																<Typography color="brand.grey.500" variant="caption">
																	{userEntity}
																</Typography>
															)}
														</Grid>
														{userChipLabel && (
															<Grid item xs={12}>
																<Chip
																	label={userChipLabel}
																	size="small"
																	sx={{ display: 'inline-flex' }}
																	variant="twoTone"
																/>
															</Grid>
														)}
													</Grid>
												</div>
												<MenuList disablePadding={true}>
													<MenuItem
														component="button"
														onClick={handleLogout}
														sx={{
															width: '100%',
															textAlign: 'left',
														}}>
														<ListItemText>{t('logout')}</ListItemText>
													</MenuItem>
												</MenuList>
											</div>
										</Popover>
									</Grid>

									<Grid className={styles.btnMenu} item xs="auto">
										<IconButton color="white" onClick={onOpenNav}>
											<IconMenu />
										</IconButton>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};
