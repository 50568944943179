// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormAddBeneficiary } from '../../forms/FormAddBeneficiary/FormAddBeneficiary';

// props
export interface DialogAddBeneficiaryProps {
	isOpen: boolean;
	onClose: () => void;
	refetchBeneficiaries?: () => void;
}

export const DialogAddBeneficiary = ({
	isOpen,
	onClose,
	refetchBeneficiaries,
}: DialogAddBeneficiaryProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
				},
				{
					children: t('addBeneficiary'),
					form: 'form-add-beneficiary',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			hasClose={true}
			isOpen={isOpen}
			maxWidth={580}
			onClose={onClose}
			title={{
				children: t('addBeneficiary'),
				variant: 'h3',
			}}>
			<FormAddBeneficiary
				onClose={onClose}
				refetchBeneficiaries={refetchBeneficiaries}
				setIsLoading={setIsLoading}
			/>
		</DialogActions>
	);
};
