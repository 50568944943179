// react
import { ReactNode, useContext, useMemo } from 'react';

// types
import { ActionButton, Project } from '../../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18next
import { t } from 'i18next';

// context
import { ContextEntities } from '../../../pages/Project/Project.context';

// mui
import { Grid } from '@mui/material';

// ui
import { BtnFilters } from '../../../ui/BtnFilters/BtnFilters';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardEntity } from '../../../cards/CardEntity/CardEntity';

// forms
import { FormSearch } from '../../../forms/FormSearch/FormSearch';

// icons
import { AccountBalanceOutlined as IconAccountBalance } from '@mui/icons-material';

// props
import { EmptyProps } from '../../../ui/Empty/Empty';

// props
export interface TableEntitiesProps {
	project: Project;
}

export const TableEntities = ({ project }: TableEntitiesProps) => {
	// state
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramQ = searchParams.get('q');
	const paramType = searchParams.get('type');

	// context
	const { entities, isLoadingEntities, paginationEntities } = useContext(ContextEntities);

	// vars
	const formIdFilters = 'form-entities-filters';
	const headers = [
		{
			label: t('name'),
			lg: 3,
			value: 'name',
		},
		{
			label: t('wallet'),
			lg: 2,
			value: 'wallet',
		},
		{
			label: t('created'),
			lg: 1.5,
			value: 'created',
		},
		{
			label: t('status'),
			lg: 1,
			value: 'status',
		},
		{
			label: t('type'),
			lg: 1.5,
			value: 'type',
		},
		{
			label: t('traceability'),
			lg: 3,
			value: 'traceability',
		},
	];

	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			actions: [
				{
					children: t('addEntity'),
					onClick: () => {
						searchParams.set('dialog', 'addEntity');
						setSearchParams(searchParams);
					},
					variant: 'contained' as ActionButton['variant'],
				},
			],
			content: { children: t('empty.entities.content') },
			IconCircleProps: { icon: (<IconAccountBalance />) as ReactNode },
			title: { children: t('empty.entities.title') },
		};

		if (paramQ || paramType) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ, paramType, searchParams, setSearchParams]);

	const content = useMemo(() => {
		if (isLoadingEntities || !entities.length) {
			return <CardEmpty empty={empty} isLoading={isLoadingEntities} />;
		}

		return (
			<Grid container spacing={2}>
				{entities.map((entity, i) => {
					return (
						<Grid item key={`entity-${i}`} xs={12}>
							<CardEntity entity={entity} project={project} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, entities, isLoadingEntities, project]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					<Grid item xs={true}>
						<FormSearch />
					</Grid>
					<Grid item xs="auto">
						<BtnFilters
							filters={[
								{
									label: t('type'),
									defaultValue: 'all',
									hasNoneOption: false,
									hasSearchParam: true,
									id: `${formIdFilters}-type`,
									name: 'type',
									options: [
										{
											label: 'All',
											value: 'all',
										},
										{
											label: t('borrower'),
											value: 'BORROWER',
										},
										{
											label: t('intermediary'),
											value: 'INTERMEDIARY',
										},
										{
											label: t('piu'),
											value: 'PIU',
										},
										{
											label: t('subPiu'),
											value: 'SUB_PIU',
										},
									],
								},
							]}
							formId={formIdFilters}
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={entities.length} pagination={paginationEntities} />
			</Grid>
		</Grid>
	);
};
