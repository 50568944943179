// react
import { useContext } from 'react';

// context
import { ContextBanner } from '../../../App.context';

// mui
import { Box } from '@mui/material';

// icons
import { ContentCopyOutlined as IconContentCopy } from '@mui/icons-material';

// props
export interface CopyToClipboardProps {
	content: string;
}

export const CopyToClipboard = ({ content }: CopyToClipboardProps) => {
	// context
	const { setBanner } = useContext(ContextBanner);

	const handleCopy = () => {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(content);

			// set banner
			setBanner({
				hasClose: true,
				message: 'Copied to clipboard!',
				severity: 'success',
			});

			// scroll to top
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	return (
		<Box component="button" sx={{ display: 'block' }}>
			<IconContentCopy
				onClick={handleCopy}
				sx={(theme) => {
					return {
						width: 18,
						color: theme.palette.brand.grey[500],
					};
				}}
			/>
		</Box>
	);
};
