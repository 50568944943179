// react
import { useContext, useEffect, useState } from 'react';

// types
import { Entity } from '../../../../@types';

// context
import { ContextProject } from '../../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// mui
import { Button, ButtonProps, Grid, SelectChangeEvent } from '@mui/material';

// fields
import { Select, SelectProps } from '../../../fields/Select/Select';

// icons
import { DeleteOutlined as IconDelete } from '@mui/icons-material';

export type RepeatableFieldsProps = {
	ButtonDeleteProps: ButtonProps;
	entity?: Entity;
	SelectLoanProps: SelectProps;
	SelectParentProps: SelectProps;
};

export const RepeatableFields = ({
	ButtonDeleteProps,
	entity,
	SelectLoanProps,
	SelectParentProps,
}: RepeatableFieldsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [entities, setEntities] = useState<Entity[]>([]);
	const [loan, setLoan] = useState<string>(
		SelectLoanProps.defaultValue ? String(SelectLoanProps.defaultValue) : ''
	);
	const [parent, setParent] = useState<string>(
		SelectParentProps.defaultValue ? String(SelectParentProps.defaultValue) : ''
	);

	// context
	const { project } = useContext(ContextProject);

	// fetch loan entities
	const { fetchRequest: fetchLoanEntities } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&loan=${loan}&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setEntities(res.data);
			}
		},
	});

	const handleChangeLoan = async (e: SelectChangeEvent<unknown>) => {
		const { value } = e.target as HTMLInputElement;

		// fetch entities for loan
		const fetchEntitiesByLoan = await fetch(
			`${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&loan=${value}&type=BORROWER,INTERMEDIARY,PIU,SUB_PIU`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			}
		);

		const resEntitiesByLoan = await fetchEntitiesByLoan.json();

		// set entities
		if (resEntitiesByLoan.data) {
			setEntities(resEntitiesByLoan.data);
		}

		setLoan(value);
	};

	const handleChangeParent = (e: SelectChangeEvent<unknown>) => {
		const { value } = e.target as HTMLInputElement;
		setParent(value);
	};

	// define default parent entity options
	let optionsEntities = SelectParentProps.options;

	// if entities exist
	if (entities.length) {
		// redefine parent entity options
		optionsEntities = entities
			// filter out active entity
			.filter((ent) => ent.id !== entity?.id)
			.map((ent) => {
				return {
					label: ent.name,
					value: String(ent.id),
				};
			});
	}

	// fetch entities when loan changes
	useEffect(() => {
		if (loan) {
			fetchLoanEntities();
		}
	}, [fetchLoanEntities, loan]);

	return (
		<Grid alignItems="flex-end" container spacing={2}>
			<Grid item xs={6} md={4}>
				<Select {...SelectLoanProps} onChange={handleChangeLoan} value={loan} />
			</Grid>
			<Grid item xs={6} md={6.5}>
				<Select
					{...SelectParentProps}
					disabled={Boolean(!loan)}
					onChange={handleChangeParent}
					options={optionsEntities}
					value={parent}
				/>
			</Grid>
			<Grid item xs={12} md={1.5}>
				<Button
					{...ButtonDeleteProps}
					className="icon"
					color="error"
					fullWidth={true}
					variant="outlined">
					<IconDelete />
				</Button>
			</Grid>
		</Grid>
	);
};
