// azure
import { LogLevel } from '@azure/msal-browser';

export const config = {
	auth: {
		clientId: String(process.env.REACT_APP_AZURE_CLIENT_ID),
		authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
		redirectUri: process.env.REACT_APP_AZURE_REDIRECT_URI,
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
				if (containsPii) {
					return;
				}

				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
		},
	},
};
