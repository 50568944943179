// react
import { ReactNode } from 'react';

// material
import { Box, Grid } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../IconCircle/IconCircle';
import { Time } from '../Time/Time';

// props
export interface LineItemProps {
	content: ReactNode;
	date?: Date;
	IconCircleProps?: IconCircleProps;
	isAlt?: boolean;
	type?: IconCircleProps['type'];
}

export const LineItem = ({
	content,
	date,
	IconCircleProps,
	isAlt = false,
	type,
}: LineItemProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					backgroundColor: isAlt ? theme.palette.brand.grey[100] : 'transparent',
					padding: theme.spacing(2),
				};
			}}>
			<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
				<Grid item xs={date ? 10 : 12}>
					<Grid alignItems="center" container spacing={1}>
						<Grid item>
							<IconCircle type={type} {...IconCircleProps} />
						</Grid>
						<Grid item flex={1}>
							{content}
						</Grid>
					</Grid>
				</Grid>

				{date && (
					<Grid item xs={2}>
						<Time date={date} TypographyProps={{ variant: 'caption' }} />
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
