// types
import {
	LoanStatus,
	ProjectStatus,
	TransactionStatus,
	UserStatus,
	WalletStatus,
} from '../../../@types';

// router
import { useTranslation } from 'react-i18next';

// mui
import { Chip, ChipProps, Typography } from '@mui/material';

// utils
import { translateStatus } from '../../../utils/translations';

// props
export interface ChipStatusProps {
	color?: ChipProps['color'] | null;
	status: LoanStatus | ProjectStatus | TransactionStatus | UserStatus | WalletStatus;
}

export const ChipStatus = ({ color, status }: ChipStatusProps) => {
	// hooks
	const { t } = useTranslation();

	const colors: Record<ChipStatusProps['status'], ChipProps['color']> = {
		ACTIVE: 'success',
		COMPLETED: 'success',
		CREATED: 'success',
		DEACTIVATED: 'error',
		DISABLED: 'error',
		DONE: 'success',
		ENABLED: 'success',
		FAILED: 'error',
		INACTIVE: 'default',
		IN_PROGRESS: 'warning',
		INVITED: 'info',
		PENDING: 'default',
		STARTED: 'info',
		WAITING: 'warning',
	};

	if (!status) {
		return <Typography>{t('na')}</Typography>;
	}

	return (
		<Chip
			color={color || colors[status]}
			label={translateStatus(status, t)}
			size="small"
			variant="twoTone"
		/>
	);
};
