// mui
import {
	Box,
	Grid,
	InputLabel,
	Switch as MuiSwitch,
	SwitchProps as MuiSwitchProps,
} from '@mui/material';

// props
export interface SwitchProps extends MuiSwitchProps {
	label?: string;
}

export const Switch = ({ disabled, id, label, required, ...props }: SwitchProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					borderColor: theme.palette.divider,
					borderRadius: 2,
					borderStyle: 'solid',
					borderWidth: 1,
					padding: theme.spacing(1, 1, 1, 1.5),
				};
			}}>
			<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
				<Grid item>
					<InputLabel disabled={disabled} htmlFor={id} required={required} sx={{ margin: 0 }}>
						{label}
					</InputLabel>
				</Grid>
				<Grid item>
					<MuiSwitch disabled={disabled} id={id} required={required} {...props} />
				</Grid>
			</Grid>
		</Box>
	);
};
