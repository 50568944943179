// react
import { ReactNode, useEffect, useState } from 'react';

// azure
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';

// router
import { useNavigate, useSearchParams } from 'react-router-dom';

// hooks
import { useAuth } from '../../../hooks/useAuth';
import { useIdle } from '../../../hooks/useIdle';

// mui
import { Box } from '@mui/material';

// ui
import { Header } from '../../ui/Header/Header';
import { Nav } from '../../ui/Nav/Nav';

// dialogs
import { DialogIdleWarning } from '../../dialogs/DialogIdleWarning/DialogIdleWarning';

export interface AuthenticatedProps {
	children: ReactNode;
}

export const Authenticated = ({ children }: AuthenticatedProps) => {
	// hooks
	const { logout } = useAuth();
	const isAuthenticated = useIsAuthenticated();
	const navigate = useNavigate();

	// logout after 30 mins
	useIdle({
		onIdle: () => {
			logout();
		},
		onPrompt: () => {
			searchParams.set('dialog', 'idleWarning');
			setSearchParams(searchParams);
		},
		promptTimeout: 1200000,
		timeout: 1800000,
	});

	// state
	const [isNavOpen, setIsNavOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramDialog = searchParams.get('dialog');

	const handleOpenNav = () => {
		setIsNavOpen(true);
	};

	const handleCloseNav = () => {
		setIsNavOpen(false);
	};

	// navigate to login if not authenticated
	useEffect(() => {
		if (!isAuthenticated) {
			navigate('/');
		}
	}, [isAuthenticated, navigate]);

	return (
		<AuthenticatedTemplate>
			<Box
				sx={(theme) => {
					return {
						backgroundColor: theme.palette.brand.grey[200],
						minHeight: '100vh',
					};
				}}>
				<Header onOpenNav={handleOpenNav} />
				<Box
					component="main"
					sx={(theme) => {
						return {
							paddingBottom: theme.spacing(3),
							paddingLeft: {
								lg: theme.variables?.navWidthCollapsed,
							},
						};
					}}>
					{children}
				</Box>
				<Nav isOpen={isNavOpen} onClose={handleCloseNav} />
			</Box>
			<DialogIdleWarning
				isOpen={paramDialog === 'idleWarning'}
				onClose={(e, reason) => {
					if (reason !== 'backdropClick') {
						searchParams.delete('dialog');
						setSearchParams(searchParams);
					}
				}}
			/>
		</AuthenticatedTemplate>
	);
};
