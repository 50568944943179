// react
import { ReactNode, useContext, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Project } from '../../../../../../@types';

// context
import { ContextProject } from '../../../Project.context';

// hooks
import { useFetch } from '../../../../../../hooks/useFetch';

// material
import { Grid, Typography, Paper } from '@mui/material';

// ui
import { Flag } from '../../../../../ui/Flag/Flag';

// props
interface CardDetailsProps {
	project: Project;
}

export const CardDetails = ({ project }: CardDetailsProps) => {
	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// context
	const { isOnboarded: isProjectOnboarded } = useContext(ContextProject);

	// state
	const [borrowers, setBorrowers] = useState<Entity[]>([]);

	// vars
	let borrower: ReactNode = t('na');

	// GET borrowers
	useFetch({
		isEnabled: Boolean(project.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project.id}&type=BORROWER`,
		options: {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBorrowers(res.data);
			}
		},
	});

	if (isProjectOnboarded && borrowers.length) {
		borrower = borrowers.map((borrower, i) => {
			return (
				<Typography key={`project-borrower-${i}`}>
					{borrower.name}
					{i !== borrowers.length - 1 && ', '}
				</Typography>
			);
		});
	}

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					[theme.breakpoints.up('md')]: {
						padding: theme.spacing(3),
					},
					height: '100%',
				};
			}}>
			<Grid container spacing={4}>
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('countryPortfolio')}
					</Typography>
					<Grid container alignItems="center" spacing={1}>
						<Grid item>
							<Flag country={project.country.code}></Flag>
						</Grid>
						<Grid item>
							<Typography>{project.country.name}</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6} md={8}>
					<Typography variant="h3" color="brand.grey.500">
						{t('region')}
					</Typography>
					<Typography>{project.region ? project.region.name : t('na')}</Typography>
				</Grid>
				<Grid item xs={6} md={4}>
					<Typography variant="h3" color="brand.grey.500">
						{t('borrower')}
					</Typography>
					<Typography>{borrower}</Typography>
				</Grid>
				<Grid item xs={6} md={8}>
					<Typography variant="h3" color="brand.grey.500">
						{t('globalPractice')}
					</Typography>
					<Typography>{project.practice ? project.practice.name : t('na')}</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};
