import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	btn: {
		width: '100%',
		'&:hover': {
			'& svg': {
				opacity: 1,
			},
		},
	},
	icon: {
		'& svg': {
			transition: 'all 200ms',
			display: 'block !important',
			width: `${theme.spacing(2)} !important`,
			height: 'auto !important',
			opacity: 0,
		},
	},
	/* active sort */
	activeSort: {
		'& svg': {
			opacity: 1,
		},
	},
}));

export default useStyles;
