// react
import { useContext } from 'react';

// types
import { ActionButton } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextUser } from '../../../App.context';

// material
import { Box, BoxProps, Button, Grid, SxProps, Typography, TypographyProps } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../IconCircle/IconCircle';
import { Loading } from '../Loading/Loading';

// props
export const emptyPositions = ['absolute', 'relative'];
export const emptyVariants = ['container', 'page'];

export type EmptyPosition = (typeof emptyPositions)[number];
export type EmptyVariant = (typeof emptyVariants)[number];

export interface EmptyProps extends Omit<BoxProps, 'content' | 'position' | 'title'> {
	actions?: ActionButton[];
	content?: TypographyProps;
	hasContent?: boolean;
	hasTitle?: boolean;
	IconCircleProps?: IconCircleProps;
	isLoading?: boolean;
	position?: EmptyPosition;
	title?: TypographyProps;
	variant?: EmptyVariant;
}

export const Empty = ({
	actions = [],
	content,
	hasContent = true,
	hasTitle = true,
	IconCircleProps,
	isLoading,
	position = 'relative',
	title,
	variant = 'container',
	...props
}: EmptyProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { user } = useContext(ContextUser);

	// vars
	const iconCircleSize = IconCircleProps?.size || 'lg';
	const prohibitedRoles = ['STAFF', 'BORROWER'];

	return (
		<Box
			sx={(theme) => {
				let sx: SxProps = {};

				if (position === 'absolute') {
					sx = {
						...sx,
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					};
				}

				if (variant === 'page') {
					sx = {
						...sx,
						height: `calc(100vh - ${theme.variables?.headerHeight})`,
					};
				}

				return sx;
			}}
			{...props}>
			<Grid
				alignItems="center"
				container
				direction="column"
				justifyContent="center"
				spacing={['md', 'sm'].includes(iconCircleSize) ? 1 : 2}
				sx={{ height: '100%', textAlign: 'center' }}>
				<Grid item>
					{isLoading ? (
						<Loading />
					) : (
						<IconCircle
							{...IconCircleProps}
							size={iconCircleSize}
							type={IconCircleProps?.type || 'search'}
						/>
					)}
				</Grid>
				{(hasContent || hasTitle) && (
					<Grid item>
						<Box sx={{ maxWidth: 260 }}>
							{hasTitle && (
								<Typography
									variant="h3"
									{...title}
									children={isLoading ? t('loading') : title?.children || t('empty.default.title')}
								/>
							)}
							{hasContent && (
								<Typography
									{...content}
									children={
										isLoading
											? t('empty.loading.content')
											: content?.children || t('empty.default.content')
									}
								/>
							)}
						</Box>
					</Grid>
				)}

				{!isLoading && actions.length > 0 && !prohibitedRoles.includes(user?.role as string) && (
					<Grid item>
						<Grid container spacing={2}>
							{actions.map((action, i) => {
								const { children, ...actionProps } = action;
								return (
									<Grid item key={`empty-action-button-${i}`}>
										<Button {...actionProps}>{children}</Button>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};
