// react
import { useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { DocumentType } from '../../../@types';

// mui
import { Box, Grid, IconButton, Typography } from '@mui/material';

// ui
import { ChipTraceability } from '../ChipTraceability/ChipTraceability';
import { IconCircle } from '../IconCircle/IconCircle';

// icons
import {
	ExpandLessOutlined as IconExpandLess,
	ExpandMoreOutlined as IconExpandMore,
} from '@mui/icons-material';

// props
export interface DocsTraceabilityProps {
	docTypes: DocumentType[];
	docTypesChecked: string[];
}

export const DocsTraceability = ({ docTypes, docTypesChecked }: DocsTraceabilityProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(true);

	// vars
	const docTypesRequired = docTypes.filter((docType) => docType.isRequired);
	const docTypesCheckedAndRequired = docTypesRequired.filter((docType) =>
		docTypesChecked.includes(String(docType.id))
	);
	const hasDocs = docTypes.length > 0;
	const hasRequiredDocs = docTypesRequired.length > 0;
	const traceability = !hasRequiredDocs
		? 100
		: Math.round((docTypesCheckedAndRequired.length / docTypesRequired.length) * 100);

	return (
		<Box
			sx={(theme) => {
				return {
					border: `1px ${theme.palette.brand.grey[400]} solid`,
					borderRadius: 2,
				};
			}}>
			<Box
				sx={(theme) => {
					return {
						padding: theme.spacing(2),
					};
				}}>
				<Grid alignItems="center" container justifyContent="space-between" spacing={2}>
					<Grid item>
						<Grid alignItems="center" container spacing={2}>
							<Grid item>
								<ChipTraceability traceability={traceability} />
							</Grid>
							<Grid item>
								<Typography>
									{t('traceabilityDocuments', {
										required: docTypesRequired.length,
										uploaded: docTypesCheckedAndRequired.length,
									})}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					{hasDocs && (
						<Grid item>
							<IconButton
								aria-label={isExpanded ? t('collapse') : t('expand')}
								onClick={() => setIsExpanded(!isExpanded)}
								size="small">
								{isExpanded ? <IconExpandLess /> : <IconExpandMore />}
							</IconButton>
						</Grid>
					)}
				</Grid>
			</Box>
			{isExpanded && (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Grid container>
							{docTypes.map((docType) => {
								const isChecked = docTypesChecked.includes(String(docType.id));

								return (
									<Grid item key={`document-${docType.id}`} xs={12}>
										<Box
											sx={(theme) => {
												return {
													borderTop: `1px ${theme.palette.brand.grey[400]} solid`,
													padding: theme.spacing(1, 2),
												};
											}}>
											<Grid
												alignItems="center"
												container
												justifyContent="space-between"
												spacing={2}>
												<Grid item>
													<Typography color="brand.grey.500" variant="caption">
														{docType.name}
													</Typography>
													{docType.isRequired && (
														<Typography color="brand.orange.500" component="span">
															*
														</Typography>
													)}
												</Grid>
												<Grid item>{isChecked && <IconCircle size="xs" type="check" />}</Grid>
											</Grid>
										</Box>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				</Grid>
			)}
		</Box>
	);
};
