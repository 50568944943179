// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { Entity, User } from '../../../@types';

// context
import { ContextUser } from '../../../App.context';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useNavigate } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Box, Button, Grid, InputLabel } from '@mui/material';

// forms
import { Select } from '../../fields/Select/Select';

// utils
import { parseJwt, toBase64Url } from '../../../utils/jwt';
import { translateRole, translateType } from '../../../utils/translations';

export const FormLoginPersona = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const navigate = useNavigate();
	const { t } = useTranslation();

	// context
	const { setUser } = useContext(ContextUser);

	// state
	const [error, setError] = useState<string | null>(null);
	const [users, setUsers] = useState<User[]>([]);

	// fetch users
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/users?populate=entity`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setUsers(res.data);
			}
		},
	});

	const handleSubmitPersona = (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-login-persona') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		const user = users.find((u) => u.id === Number(entries.user));

		if (user) {
			if (user.status === 'DEACTIVATED') {
				setError(t('error.userDeactivated'));
			} else {
				if (accessToken && idToken) {
					// get payloads
					const { payload: accessTokenPayload } = parseJwt(accessToken);
					const { payload: idTokenPayload } = parseJwt(idToken);

					// define token arrays
					const accessTokenArray = accessToken.split('.');
					const idTokenArray = idToken.split('.');

					// construct new access token payload
					const accessTokenPayloadNew = toBase64Url({
						...accessTokenPayload,
						// non sso users have email in token instead of upn
						email: user.email,
					});

					// construct new id token payload
					const idTokenPayloadNew = toBase64Url({
						...idTokenPayload,
						roles: [process.env.REACT_APP_AZURE_ROLE_GENERAL],
					});

					// define new tokens (header and payload)
					let accessTokenNew = `${accessTokenArray[0]}.${accessTokenPayloadNew}`;
					let idTokenNew = `${idTokenArray[0]}.${idTokenPayloadNew}`;

					// add signatures if they exist
					if (accessTokenArray[2]) {
						accessTokenNew += `.${accessTokenArray[2]}`;
					}

					if (idTokenArray[2]) {
						idTokenNew += `.${idTokenArray[2]}`;
					}

					// set user and jwt in session storage
					sessionStorage.setItem('accessToken', accessTokenNew);
					sessionStorage.setItem('idToken', idTokenNew);
					sessionStorage.setItem(
						'user',
						JSON.stringify({
							...user,
							isPersona: true,
						})
					);

					setUser(user);

					// navigate to project
					navigate(`/projects`);
				}
			}
		}
	};

	const optionsUsers = users.map((user) => {
		const entity = user.entity as Entity;
		return {
			label: `${user.firstName} ${user.lastName}`,
			secondary: entity ? translateType(entity.type, t) : translateRole(user.role, t),
			value: user.id,
		};
	});

	return (
		<form id="form-login-persona" onSubmit={handleSubmitPersona}>
			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<InputLabel htmlFor="form-login-persona-user" required={true}>
							{t('selectPersona')}
						</InputLabel>
						<Select
							id="form-login-persona-user"
							name="user"
							options={optionsUsers}
							required={true}
						/>
					</Grid>
					{error && (
						<Grid item xs={12}>
							<Alert severity="error">{error}</Alert>
						</Grid>
					)}
					<Grid item xs={12}>
						<Button fullWidth={true} type="submit" variant="contained">
							{t('signIn')}
						</Button>
					</Grid>
				</Grid>
			</Box>
		</form>
	);
};
