// react
import { FormEvent, useCallback, useEffect, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// types
import { Project } from '../../../@types/index';

// router
import { useSearchParams } from 'react-router-dom';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// material
import { Alert, Grid, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// cards
import { CardEmpty } from '../../cards/CardEmpty/CardEmpty';
import { CardProject } from '../../cards/CardProject/CardProject';

// icons
import {
	SearchOutlined as IconSearch,
	SearchOffOutlined as IconSearchOff,
} from '@mui/icons-material';

// props
import { EmptyProps } from '../../ui/Empty/Empty';

export const FormSearchProjects = () => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// state
	const [error, setError] = useState<string | null>(null);
	const [hasSearched, setHasSearched] = useState(false);
	const [results, setResults] = useState<Project[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramIsMock = searchParams.get('isMock');
	const paramQ = searchParams.get('q');

	let urlSearch = `${process.env.REACT_APP_API_URL}/wbg?q=${paramQ}`;

	if (paramIsMock) {
		urlSearch += `&resource=mock`;
	}

	const getProjectsOnboarded = useCallback(async () => {
		const fetchProjectsOnboarded = await fetch(
			`${process.env.REACT_APP_API_URL}/projects?fields=idWbg`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
			}
		);

		const resProjectsOnboarded = await fetchProjectsOnboarded.json();

		if (resProjectsOnboarded.data) {
			return resProjectsOnboarded.data.map((project: Project) => project.idWbg.toLowerCase());
		}

		return [];
	}, [accessToken, idToken]);

	// lazy search fetch
	const { fetchRequest: fetchResults, isLoading } = useFetch({
		isLazy: true,
		url: urlSearch,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: async (res) => {
			if (res.data) {
				setResults(res.data);
			}
			setHasSearched(true);
		},
	});

	// vars
	let empty: EmptyProps = {
		content: { children: t('empty.projects.content') },
		IconCircleProps: { icon: <IconSearch /> },
		title: { children: t('empty.projects.title') },
	};

	if (hasSearched && !isLoading) {
		empty = {
			content: { children: t('empty.default.content') },
			IconCircleProps: { icon: <IconSearchOff /> },
			title: { children: t('empty.default.title') },
		};
	}

	const handleSearch = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		// clear error
		setError(null);

		const form = document.querySelector('#form-search-projects') as HTMLFormElement;

		if (form) {
			const fd = new FormData(form);
			const entries = Object.fromEntries(fd.entries());
			const q = String(entries.q);

			searchParams.set('q', q);
			setSearchParams(searchParams);
		}
	};

	useEffect(() => {
		const search = async () => {
			if (paramQ) {
				const projectsOnboarded = await getProjectsOnboarded();
				const isOnboarded = projectsOnboarded.includes(paramQ.toLowerCase());

				if (isOnboarded) {
					const message = t('error.projectAlreadyOnboarded', {
						idWbg: paramQ,
					});

					setError(message);
				} else {
					fetchResults();
				}
			}
		};

		search();
	}, [fetchResults, getProjectsOnboarded, paramQ, t]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h2">{t('search')}</Typography>
			</Grid>
			<Grid item xs={12}>
				<form id="form-search-projects" onSubmit={handleSearch}>
					<Paper
						sx={(theme) => {
							return {
								padding: {
									xs: theme.spacing(2),
									md: theme.spacing(3),
								},
							};
						}}>
						<Grid container spacing={2}>
							<Grid item xs={12} md={true}>
								<TextField
									defaultValue={paramQ}
									fullWidth
									helperText={t('formSearchProjects.q.helper')}
									id="q"
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<IconSearch />
											</InputAdornment>
										),
									}}
									name="q"
									required
									size="small"
								/>
							</Grid>
							<Grid item xs={12} md="auto">
								<LoadingButton fullWidth loading={isLoading} type="submit" variant="contained">
									{t('search')}
								</LoadingButton>
							</Grid>
							{error && (
								<Grid item xs={12}>
									<Alert severity="error">{error}</Alert>
								</Grid>
							)}
						</Grid>
					</Paper>
				</form>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h2">{t('results')}</Typography>
			</Grid>

			<Grid item xs={12}>
				{results.length > 0 && !isLoading ? (
					<Grid container spacing={2}>
						{results.map((project, i: number) => {
							return (
								<Grid item key={`result-${i}`} xs={12}>
									<CardProject
										isOnboarded={false}
										isMock={Boolean(paramIsMock)}
										project={project}
									/>
								</Grid>
							);
						})}
					</Grid>
				) : (
					<CardEmpty empty={empty} isLoading={isLoading} />
				)}
			</Grid>
		</Grid>
	);
};
