// material
import { Container, Grid } from '@mui/material';

// tables
import { TableHistoricalTransactions } from '../../../../tables/cards/TableHistoricalTransactions/TableHistoricalTransactions';

export const HistoricalTransactions = () => {
	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TableHistoricalTransactions />
				</Grid>
			</Grid>
		</Container>
	);
};
