// react
import { MouseEvent, useState } from 'react';

// types
import { Project } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// forms
import { FormOnboardProject } from '../../forms/FormOnboardProject/FormOnboardProject';

// props
export interface DialogOnboardProjectProps {
	isOpen: boolean;
	onClose: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement | HTMLFormElement>) => void;
	onSuccess: (e: MouseEvent<HTMLFormElement>, project: Project) => void;
	project: Project;
}

export const DialogOnboardProject = ({
	isOpen,
	onClose,
	onSuccess,
	project,
}: DialogOnboardProjectProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isLoading, setIsLoading] = useState(false);

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: onClose,
					variant: 'text',
				},
				{
					children: t('onboard'),
					form: 'form-onboard-project',
					loading: isLoading,
					type: 'submit',
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'project',
			}}
			isOpen={isOpen}
			maxWidth={700}
			onClose={onClose}
			title={{
				children: t('onboardProjectId', { id: project.idWbg }),
			}}>
			<FormOnboardProject
				onClose={onClose}
				onSuccess={onSuccess}
				project={project}
				setIsLoading={setIsLoading}
			/>
		</DialogActions>
	);
};
