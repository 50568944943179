// ui
import { Empty } from '../../Empty/Empty';
import { Notification } from '../../Notification/Notification';

// material
import { Box } from '@mui/material';

// i18n
import { useTranslation } from 'react-i18next';

// icons
import { TimerOutlined as IconTimer } from '@mui/icons-material';

// props
import { LineItemProps } from '../../LineItem/LineItem';

// theme;
import { theme } from '../../../../theme';

export interface NotificationsProps {
	items: LineItemProps[];
}

export const Notifications = ({ items }: NotificationsProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<>
			{items.length > 0 ? (
				items.map((item) => {
					return (
						<Notification date={item.date} type={item.type}>
							{item.content}
						</Notification>
					);
				})
			) : (
				<Box sx={{ padding: theme.spacing(3) }}>
					<Empty
						content={{
							children: t('empty.activity.content'),
							variant: 'caption',
						}}
						IconCircleProps={{
							icon: <IconTimer />,
							size: 'md',
						}}
						title={{
							children: t('empty.activity.title'),
							component: 'h3',
							variant: 'h6',
						}}
					/>
				</Box>
			)}
		</>
	);
};
