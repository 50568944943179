// react
import { MouseEvent, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Box, Button, Grid, Popover, Typography } from '@mui/material';

// icons
import { FilterAltOutlined as IconFilterAlt } from '@mui/icons-material';

// ui
import { FilterProps, Filters } from '../Filters/Filters';

// styles
import { theme } from '../../../theme';

export interface BtnFiltersProps {
	filters: FilterProps[];
	formId: string;
	onReset?: () => void;
}

export const BtnFilters = ({ filters, formId, onReset }: BtnFiltersProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [anchorMenu, setAnchorMenu] = useState<HTMLButtonElement | null>(null);
	const [searchParams, setSearchParams] = useSearchParams();

	// handlers
	const handleResetFilters = () => {
		// clear all search params
		searchParams.forEach((value, key) => {
			searchParams.delete(key);
		});

		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (onReset) {
			onReset();
		}

		setSearchParams(searchParams);
	};

	return (
		<>
			<Button
				aria-label={t('toggleFilters')}
				color="primary"
				disabled={!filters.length}
				fullWidth
				onClick={(e: MouseEvent<HTMLButtonElement>) => setAnchorMenu(e.currentTarget)}
				variant="outlined">
				{<IconFilterAlt />}
			</Button>
			<Popover
				anchorEl={anchorMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				disablePortal={true}
				onClose={() => setAnchorMenu(null)}
				open={Boolean(anchorMenu)}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Box sx={{ padding: theme.spacing(2), width: '350px' }}>
					<Grid container spacing={theme.spacing(2)}>
						<Grid item xs={12}>
							<Typography variant="h3">{t('filterBy')}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Filters filters={filters} id={`${formId}`} />
						</Grid>
						<Grid item xs={12}>
							<Button fullWidth variant="outlined" onClick={handleResetFilters}>
								<Typography>{t('reset')}</Typography>
							</Button>
						</Grid>
					</Grid>
				</Box>
			</Popover>
		</>
	);
};
