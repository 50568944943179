// react
import { MouseEvent } from 'react';

// mui
import { Button, Grid, Paper, Typography } from '@mui/material';

// icons
import { ArrowForward as IconArrowForward } from '@mui/icons-material';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// theme
import { theme } from '../../../theme';

// props
export type CardActionProps = {
	IconCircleProps: IconCircleProps;
	label?: string;
	onClick: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const CardAction = ({
	IconCircleProps: { icon, type = 'default' },
	label = 'label',
	onClick,
}: CardActionProps) => {
	// vars
	const labelArray = label.split(' ');
	const firstWord = labelArray.shift();

	return (
		<Paper
			sx={(theme) => {
				return {
					minHeight: '90px',
					height: '100%',
				};
			}}>
			<Button
				aria-label={label}
				color="primary"
				fullWidth={true}
				onClick={onClick}
				sx={{
					height: '100%',
					padding: theme.spacing(2),
					color: 'black',
				}}
				variant="outlined">
				<Grid container columnSpacing={1} justifyContent="space-between" height="100%">
					<Grid item xs={10}>
						<Grid alignItems="flex-start" container columnSpacing={1}>
							<Grid item xs="auto">
								<IconCircle icon={icon} type={type} size="sm" />
							</Grid>
							<Grid item xs={true}>
								<Typography fontWeight={600} align="left">
									{firstWord}
									<br />
									{labelArray.join(' ')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>

					<Grid alignSelf="flex-end" justifySelf="flex-end" item xs="auto">
						<IconArrowForward sx={{ color: theme.palette.brand.blue[500] }} />
					</Grid>
				</Grid>
			</Button>
		</Paper>
	);
};
