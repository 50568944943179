// react
import { useState } from 'react';

// types
import { Document, Transaction } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// hooks
import { useFetch } from '../../../../hooks/useFetch';

// headers
import { getDocumentsHeaders } from './TableBasicDocs.headers';

// ui
import { Table, TableHeader } from '../../../ui/Table/Table';

// props
export interface TableBasicDocsProps {
	entity?: number;
	transaction?: Transaction;
}

export const TableBasicDocs = ({ entity, transaction }: TableBasicDocsProps) => {
	// state
	const [documents, setDocuments] = useState<Document[]>([]);

	// hooks
	const { t } = useTranslation();

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// vars
	const headers: TableHeader[] = getDocumentsHeaders({ t, transaction });

	// fetch documents
	let urlFetchDocs = `${process.env.REACT_APP_API_URL}/documents?populate=transaction,type,user`;

	if (entity) {
		urlFetchDocs += `&entity=${entity}`;
	}

	if (transaction) {
		urlFetchDocs += `&transaction=${transaction?.id}`;
	}

	const { isLoading } = useFetch({
		isEnabled: Boolean(entity || transaction),
		url: urlFetchDocs,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setDocuments(res.data);
			}
		},
	});

	return <Table data={documents} headers={headers} isLoading={isLoading} />;
};
