// react
import { ReactNode, useMemo } from 'react';

// types
import { Form, Pagination } from '../../../../@types';

// i18next
import { t } from 'i18next';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid } from '@mui/material';

// cards
import { CardEmpty } from '../../../cards/CardEmpty/CardEmpty';
import { CardForm } from '../../../cards/CardForm/CardForm';

// ui
import { EmptyProps } from '../../../ui/Empty/Empty';
import { SortParams, TableHeaders } from '../../../ui/TableHeaders/TableHeaders';
import { TableFooter } from '../../../ui/TableFooter/TableFooter';

// icons
import { ViewListOutlined as IconList } from '@mui/icons-material';

// props
export interface TableFormsProps {
	forms: Form[];
	isLoading?: boolean;
	pagination: Pagination;
}

export const TableForms = ({ forms, isLoading, pagination }: TableFormsProps) => {
	// state
	const [searchParams] = useSearchParams();

	// params
	const paramQ = searchParams.get('q');

	// headers
	const headers = [
		{
			label: t('form'),
			lg: 4,
			value: 'form',
		},
		{
			label: t('created'),
			lg: 2,
			value: 'created',
		},
		{
			label: t('updated'),
			lg: 2,
			value: 'updated',
		},
		{
			label: t('user'),
			lg: 4,
			value: 'user',
		},
	];

	// empty
	const empty = useMemo(() => {
		const emptyProps: EmptyProps = {
			content: { children: t('empty.forms.content') },
			IconCircleProps: { icon: (<IconList />) as ReactNode },
			title: { children: t('empty.forms.title') },
		};
		if (paramQ) {
			emptyProps.actions = [];
			emptyProps.content = { children: t('empty.default.content') };
			emptyProps.IconCircleProps = { icon: null };
		}

		return emptyProps;
	}, [paramQ]);

	// content
	const content = useMemo(() => {
		if (isLoading || !forms.length) {
			return <CardEmpty empty={empty} isLoading={isLoading} />;
		}

		return (
			<Grid container spacing={2}>
				{forms.map((form, i) => {
					return (
						<Grid item key={`form-${i}`} xs={12}>
							<CardForm form={form} />
						</Grid>
					);
				})}
			</Grid>
		);
	}, [empty, isLoading, forms]);

	// handlers
	const handleSort = ({ order, value }: SortParams) => {
		console.log(value, order);
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sx={{ display: { xs: 'none', lg: 'block' } }}>
				<TableHeaders headers={headers} onSort={handleSort} />
			</Grid>
			<Grid item xs={12}>
				{content}
			</Grid>
			<Grid item xs={12}>
				<TableFooter numShowing={forms.length} pagination={pagination} />
			</Grid>
		</Grid>
	);
};
