// react
import { MouseEvent, useContext, useState } from 'react';

// types
import { Entity } from '../../../@types';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import {
	ContextActivities,
	ContextEntities,
	ContextSuppliers,
} from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid, Typography } from '@mui/material';

// dialogs
import { DialogActions } from '../DialogActions/DialogActions';

// props
export interface DialogUpdateEntityStatusProps {
	entity: Entity;
	isOpen: boolean;
	onClose: (e?: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export const DialogUpdateEntityStatus = ({
	entity,
	isOpen,
	onClose,
}: DialogUpdateEntityStatusProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { refetchActivities } = useContext(ContextActivities);
	const { refetchEntities } = useContext(ContextEntities);
	const { refetchSuppliers } = useContext(ContextSuppliers);
	const { setBanner } = useContext(ContextBanner);

	// state
	const [error, setError] = useState<string | null>(null);

	// vars
	const isActivating = entity.wallet?.status === 'INACTIVE';
	const isSupplier = entity.type === 'SUPPLIER';

	// deactivate translations
	const dialog = {
		alert: isSupplier ? t('alert.deactivateSupplier') : t('alert.deactivateEntity'),
		btnSubmitChildren: isSupplier ? t('deactivateSupplier') : t('deactivateEntity'),
		i18nKey: isSupplier
			? 'dialogUpdateSupplierStatus.deactivate.content'
			: 'dialogUpdateEntityStatus.deactivate.content',
		title: isSupplier ? t('deactivateSupplier') : t('deactivateEntity'),
	};

	// activate translations
	if (isActivating) {
		dialog.btnSubmitChildren = isSupplier ? t('activateSupplier') : t('activateEntity');
		dialog.alert = isSupplier ? t('alert.activateSupplier') : t('alert.activateEntity');
		dialog.i18nKey = isSupplier
			? 'dialogUpdateSupplierStatus.activate.content'
			: 'dialogUpdateEntityStatus.activate.content';
		dialog.title = isSupplier ? t('activateSupplier') : t('activateEntity');
	}

	// patch entity
	const { fetchRequest: updateEntity, isLoading: isLoadingUpdateEntity } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/entities/${entity.id}`,
		options: {
			method: 'PATCH',
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
			body: JSON.stringify({
				wallet: {
					status: isActivating ? 'ACTIVE' : 'INACTIVE',
				},
			}),
		},
		onError: (error) => {
			let msg = t('error.default');

			if (error.id === 'ENTITY_UNAUTHORIZED_PATCH') {
				msg = t('error.unauthorized');
			}

			if (error.id === 'ENTITY_WALLET_NON_EXISTENT') {
				msg = t('error.entityWalletNonExistent', { name: entity.name });
			}

			setError(msg);
		},
		onSuccess: () => {
			// set banner
			setBanner({
				hasClose: true,
				message: isActivating
					? t('alert.entityWalletActivated', { name: entity.name })
					: t('alert.entityWalletDeactivated', { name: entity.name }),
				severity: 'success',
			});

			// refetch entities
			isSupplier ? refetchSuppliers() : refetchEntities();

			// refetch activities
			refetchActivities();

			// close dialog
			if (onClose) {
				onClose();
			}
		},
	});

	// handlers
	const handleSubmit = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		// update entity wallet status
		updateEntity();
	};

	const handleClose = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		// clear error
		setError(null);

		if (onClose) {
			onClose(e);
		}
	};

	return (
		<DialogActions
			actions={[
				{
					children: t('cancel'),
					onClick: handleClose,
					variant: 'text',
				},
				{
					children: dialog.btnSubmitChildren,
					loading: isLoadingUpdateEntity,
					onClick: handleSubmit,
					variant: 'contained',
				},
			]}
			alignItems="center"
			IconCircleProps={{
				type: 'entity',
			}}
			isOpen={isOpen}
			maxWidth={400}
			onClose={handleClose}
			subtitle={{
				children: entity.name,
			}}
			title={{
				children: dialog.title,
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Trans
						components={{
							strong: <Typography color="brand.magenta.600" component="span" fontWeight={600} />,
						}}
						i18nKey={dialog.i18nKey}
						values={{ name: entity.name }}
					/>
				</Grid>
				<Grid item xs={12}>
					<Alert severity="warning">{dialog.alert}</Alert>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</DialogActions>
	);
};
