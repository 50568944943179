// i18n
import { TFunction } from 'i18next';

export const getOptionsEntityTypes = (t: TFunction) => {
	return [
		{
			label: t('borrower'),
			value: 'BORROWER',
		},
		{
			label: t('intermediary'),
			value: 'INTERMEDIARY',
		},
		{
			label: t('piu'),
			value: 'PIU',
		},
		{
			label: t('subPiu'),
			value: 'SUB_PIU',
		},
	];
};

export const optionsCurrency = [
	{
		label: 'BDT',
		value: 'BDT',
	},
	{
		label: 'EUR',
		value: 'EUR',
	},
	{
		label: 'JPY',
		value: 'JPY',
	},
	{
		label: 'KES',
		value: 'KES',
	},
	{
		label: 'MDL',
		value: 'MDL',
	},
	{
		label: 'PHP',
		value: 'PHP',
	},
	{
		label: 'USD',
		value: 'USD',
	},
];
