// react
import { ReactNode } from 'react';

// i18n
import { t } from 'i18next';

// mui
import { Alert, Box, SxProps, Typography } from '@mui/material';

// ui
import { Empty } from '../Empty/Empty';

// theme
import { theme } from '../../../theme';

// props
export const tableHeaderPositions = ['left', 'right'];

export type TableHeaderPosition = (typeof tableHeaderPositions)[number];

export interface TableHeader {
	align?: TableHeaderPosition;
	label: string;
	// eslint-disable-next-line @typescript-eslint/ban-types
	render?: Function;
	value: string;
}

export interface TableProps {
	borderRadius?: number;
	color?: 'blue' | 'grey';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any[];
	frame?: 'CARD' | 'DEFAULT';
	hasBorder?: boolean;
	hasDividers?: boolean;
	headers: TableHeader[];
	isLoading?: boolean;
	isStacked?: boolean;
}

export const Table = ({
	borderRadius,
	color = 'blue',
	data,
	frame = 'DEFAULT',
	headers,
	hasBorder = false,
	hasDividers = false,
	isLoading,
	isStacked = false,
}: TableProps) => {
	// vars
	const cellPadding = frame === 'CARD' ? theme.spacing(1.5, 3) : theme.spacing(2);
	const colors = {
		blue: {
			background: theme.palette.brand.blue[100],
			border: theme.palette.brand.blue[500],
			header: theme.palette.brand.blue[700],
		},
		grey: {
			background: theme.palette.brand.grey[100],
			border: theme.palette.brand.grey[400],
			header: theme.palette.brand.grey[800],
		},
	};

	const renderTable = () => {
		if (isLoading) {
			return (
				<Box
					sx={(theme) => {
						return {
							padding: theme.spacing(2),
						};
					}}>
					<Empty hasContent={false} hasTitle={false} isLoading={isLoading} />
				</Box>
			);
		}

		const thead = (
			<Box
				component="thead"
				sx={(theme) => {
					return {
						backgroundColor: colors[color].background,
					};
				}}>
				<tr>
					{headers.map((header) => {
						return (
							<Box
								component="th"
								key={`table-header-${header.value}-${header.label}`}
								sx={(theme) => {
									let sx: SxProps = {
										borderBottom: hasBorder ? `1px ${colors[color].border} solid` : null,
										color: colors[color].header,
										padding: cellPadding,
										textAlign: 'left',
										whiteSpace: 'nowrap',
									};

									if (header.align === 'right') {
										sx = {
											...sx,
											textAlign: {
												lg: 'right',
											},
										};
									}

									return sx;
								}}>
								<Typography fontWeight={600} variant="caption">
									{header.label}
								</Typography>
							</Box>
						);
					})}
				</tr>
			</Box>
		);

		let tbody: ReactNode = (
			<tr>
				<Box
					component="td"
					colSpan={headers.length}
					sx={(theme) => {
						return {
							padding: theme.spacing(1),
						};
					}}>
					<Alert severity="info">{t('alert.empty')}</Alert>
				</Box>
			</tr>
		);

		if (data.length > 0) {
			tbody =
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				data.map((item: any, i: number) => {
					let sx: SxProps = {};

					if (hasDividers && i < data.length - 1) {
						sx = {
							...sx,
							borderBottomColor: theme.palette.divider,
							borderBottomStyle: 'solid',
							borderBottomWidth: 1,
						};
					}

					return (
						<Box component="tr" key={`table-row-${i}`} sx={sx}>
							{headers.map((header) => {
								const content = header.render ? (
									header.render(item[header.value], header, item, i)
								) : (
									<Typography component="span">{item[header.value]}</Typography>
								);

								return (
									<Box
										component="td"
										key={`table-data-${header.label}-${i}`}
										sx={(theme) => {
											let sx: SxProps = {
												padding: cellPadding,
												textAlign: 'left',
												whiteSpace: frame === 'CARD' ? 'nowrap' : 'wrap',
											};

											if (header.align === 'right') {
												sx = {
													...sx,
													textAlign: {
														lg: 'right',
													},
												};
											}

											return sx;
										}}>
										{isStacked && (
											<Typography
												color="brand.grey.500"
												fontWeight={600}
												sx={(theme) => {
													return {
														display: {
															lg: 'none',
														},
													};
												}}
												variant="caption">
												{header.label}
											</Typography>
										)}{' '}
										{content}
									</Box>
								);
							})}
						</Box>
					);
				});
		}

		return (
			<table>
				{thead}
				<tbody>{tbody}</tbody>
			</table>
		);
	};

	return (
		<Box
			sx={(theme) => {
				let sx: SxProps = {
					border: hasBorder ? `1px ${colors[color].border} solid` : null,
					borderRadius: borderRadius || null,
					overflowX: 'auto',
					'& table': {
						borderCollapse: 'collapse',
						width: '100%',
					},
				};

				if (isStacked) {
					sx = {
						...sx,
						'& thead': {
							display: 'none',
							[theme.breakpoints.up('lg')]: {
								display: 'table-header-group',
							},
						},
						'& tr': {
							display: 'block',
							padding: theme.spacing(2),
							[theme.breakpoints.up('lg')]: {
								display: 'table-row',
								padding: 0,
							},
							'&:nth-child(odd)': {
								backgroundColor: theme.palette.brand.grey[100],
							},
						},
						'& td': {
							display: 'grid',
							gridTemplateColumns: '1fr 1fr',
							padding: 0,
							[theme.breakpoints.up('lg')]: {
								display: 'table-cell',
								padding: theme.spacing(1, 3),
							},
						},
					};
				}

				return sx;
			}}>
			{renderTable()}
		</Box>
	);
};
