// react
import { ReactNode } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Typography } from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../IconCircle/IconCircle';

// dates
import { toRelativeTime } from '../../../utils/dates';

// styles
import useStyles from './Notification.styles';

// props
export interface NotificationProps {
	children: ReactNode;
	date?: Date;
	type?: IconCircleProps['type'];
}

export const Notification = ({ children, date, type = 'default' }: NotificationProps) => {
	// hooks
	const styles = useStyles();
	const { i18n } = useTranslation();

	return (
		<div className={styles.notification}>
			<IconCircle size="sm" type={type} />
			<div className={styles.message}>
				<Typography variant="caption">{children}</Typography>
				{date && (
					<time className={styles.time}>
						<Typography component="div" variant="caption">
							{toRelativeTime(date, i18n.language)}
						</Typography>
					</time>
				)}
			</div>
		</div>
	);
};
