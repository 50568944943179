// react
import { useContext } from 'react';

// context
import { ContextPayments } from '../../Project.context';

// mui
import { Container, Grid } from '@mui/material';

// tables
import { TablePayments } from '../../../../tables/cards/TablePayments/TablePayments';

export const Payments = () => {
	// context
	const { payments, isLoadingPayments, paginationPayments } = useContext(ContextPayments);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TablePayments
						isLoading={isLoadingPayments}
						pagination={paginationPayments}
						transactions={payments}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
