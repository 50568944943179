// mui
import { Paper } from '@mui/material';

// ui
import { Empty, EmptyProps } from '../../ui/Empty/Empty';

// props
export interface CardEmptyProps {
	empty: EmptyProps;
	isLoading?: boolean;
}

export const CardEmpty = ({ empty, isLoading }: CardEmptyProps) => {
	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(6, 2),
				};
			}}>
			<Empty {...empty} isLoading={isLoading} />
		</Paper>
	);
};
