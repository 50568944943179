// react
import { useContext, useState } from 'react';

// types
import { Entity } from '../../../@types';

// router
import { Link as RouterLink } from 'react-router-dom';

// i18n
import { Trans, useTranslation } from 'react-i18next';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, AlertTitle, Link } from '@mui/material';

// dialogs
import { DialogActions, DialogActionsProps } from '../DialogActions/DialogActions';

// forms
import { FormUploadTransactions } from '../../forms/FormUploadTransactions/FormUploadTransactions';

// props
export interface DialogUploadTransactionsProps {
	isOpen: boolean;
	onClose: () => void;
}

export const DialogUploadTransactions = ({ isOpen, onClose }: DialogUploadTransactionsProps) => {
	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// hooks
	const { t } = useTranslation();

	// context
	const { project } = useContext(ContextProject);

	// state
	const [beneficiaries, setBeneficiaries] = useState<Entity[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	// fetch single beneficiary
	const { isLoading: isLoadingBeneficiaries } = useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&type=BENEFICIARY&fields=id&limit=1`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setBeneficiaries(res.data);
			}
		},
	});

	// vars
	let actions: DialogActionsProps['actions'] = [];
	let content;

	if (!isLoadingBeneficiaries) {
		actions.push(
			{
				children: t('cancel'),
				onClick: onClose,
			},
			{
				children: t('uploadTransactions'),
				form: 'form-upload-transactions',
				loading: isLoading,
				type: 'submit',
				variant: 'contained',
			}
		);

		content = <FormUploadTransactions onClose={onClose} setIsLoading={setIsLoading} />;
	}

	if (!beneficiaries.length) {
		// redefine actions
		actions = [
			{
				children: t('gotIt'),
				onClick: onClose,
				variant: 'contained',
			},
		];

		// redefine content
		content = (
			<Alert severity="info">
				<AlertTitle>{t('alert.uploadTransactions.title')}</AlertTitle>
				<Trans
					components={{
						anchorUploadBeneficiaries: (
							<Link
								component={RouterLink}
								fontWeight={600}
								to={`/project/${project?.idWbg}/beneficiaries?dialog=uploadBeneficiaries`}
								underline="hover"
							/>
						),
					}}
					i18nKey="alert.uploadTransactions.content"
				/>
			</Alert>
		);
	}

	return (
		<DialogActions
			actions={actions}
			hasClose={true}
			isOpen={isOpen}
			maxWidth={700}
			onClose={onClose}
			title={{
				children: t('uploadTransactions'),
				variant: 'h3',
			}}>
			{content}
		</DialogActions>
	);
};
