// react
import { ReactNode } from 'react';

// mui
import { Grid, Typography } from '@mui/material';

// props
export interface RenderKeyValueParams {
	label: string;
	value: ReactNode;
}

export const RowKeyValue = ({ label, value }: RenderKeyValueParams) => {
	return (
		<Grid container justifyContent="space-between" spacing={2}>
			<Grid
				item
				sx={(theme) => {
					return {
						[theme.breakpoints.up('lg')]: {
							display: 'none',
						},
					};
				}}>
				<Typography variant="body2">{label}</Typography>
			</Grid>
			<Grid item>{value}</Grid>
		</Grid>
	);
};
