// react
import { ReactNode, useEffect, useMemo, useState } from 'react';

// types
import { Option } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import {
	Checkbox,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	Select as MuiSelect,
	SelectChangeEvent,
	SelectProps as MuiSelectProps,
} from '@mui/material';

// ui
import { IconCircle, IconCircleProps } from '../../ui/IconCircle/IconCircle';

// props
export type SelectProps = {
	IconCircleProps?: IconCircleProps;
	hasAllOption?: boolean;
	hasNoneOption?: boolean;
	hasSearchParam?: boolean;
	helperText?: string;
	options: Option[];
} & MuiSelectProps;

export const Select = ({
	defaultValue,
	disabled,
	fullWidth = true,
	helperText,
	hasAllOption = false,
	hasNoneOption = true,
	hasSearchParam = false,
	IconCircleProps,
	id,
	label,
	multiple = false,
	name,
	onChange,
	options,
	required,
	size = 'small',
	variant = 'outlined',
	...props
}: SelectProps) => {
	// hooks
	const { t } = useTranslation();

	// options
	const isAllOptionsDisabled = options.filter((o) => o.disabled).length === options.length;

	const optionAll = {
		disabled: isAllOptionsDisabled,
		label: t('all'),
		value: 'all',
	};

	// add all option
	if (hasAllOption && multiple) {
		if (!options.find((option) => option.label === t('all'))) {
			options.unshift(optionAll);
		}
	}

	// add none option
	if (!required && hasNoneOption && !multiple) {
		if (!options.find((option) => option.label === t('none'))) {
			options.unshift({
				label: t('none'),
				value: '',
			});
		}
	}

	// defaults
	const defaultSelected = useMemo(() => {
		let value: unknown = multiple ? [] : '';

		if (defaultValue) {
			value = defaultValue;

			// set default value as all options if default value includes "all"
			if (Array.isArray(defaultValue)) {
				if (defaultValue.includes('all')) {
					value = options.map((option) => option.value);
				}
			}
		}

		return value;
	}, [defaultValue, multiple, options]);

	// state
	const [searchParams, setSearchParams] = useSearchParams();
	const [selected, setSelected] = useState<unknown | unknown[]>(defaultSelected);

	// handlers
	const handleChange = (e: SelectChangeEvent<unknown>, child: ReactNode) => {
		const { value } = e.target;

		const param = String(name);
		let selectedNew;

		// if value is array
		if (Array.isArray(value)) {
			const valueAdded = value[value.length - 1];
			const isValueAll = valueAdded === 'all';

			if (isValueAll) {
				// set all options
				selectedNew = options.filter((option) => !option.disabled).map((option) => option.value);
			} else {
				// filter out "all"
				selectedNew = value.filter((val) => val !== 'all');
			}
		} else {
			selectedNew = value as string;
		}

		if (hasSearchParam) {
			// if value is empty or includes all
			if (!selectedNew.length || selectedNew.includes('all')) {
				// delete search param
				searchParams.delete(param);
			} else if (Array.isArray(selectedNew)) {
				// set search param as comma separated list
				searchParams.set(param, selectedNew.join(','));
			} else {
				// set search param
				searchParams.set(param, String(selectedNew));
			}

			// set search params
			setSearchParams(searchParams);
		}

		// set selected
		setSelected(selectedNew);

		if (onChange) {
			onChange(e, child);
		}
	};

	// render
	const renderValue = (value: unknown) => {
		// if value is array
		if (Array.isArray(value)) {
			const isAllSelected = value.length === options.length;

			if (isAllSelected) {
				return t('all');
			}

			// create array of labels using values
			const labels = [];

			// for each value
			for (let v = 0; v < value.length; v++) {
				// find option
				const option = options.find((o) => o.value === value[v]);

				// add option
				if (option) {
					labels.push(option.label);
				}
			}

			// set value as comma separated list
			return labels.join(', ');
		} else {
			// find option
			const option = options.find((o) => o.value === value);

			// set value as option label
			if (option) {
				return option.label;
			}
		}
	};

	// define conditional props
	const selectProps: MuiSelectProps = {};

	if (IconCircleProps) {
		selectProps.startAdornment = (
			<InputAdornment position="start" sx={{ marginRight: `6px !important` }}>
				<IconCircle size="xs" {...IconCircleProps} />
			</InputAdornment>
		);
	}

	// reset selected if search param does not exist
	useEffect(() => {
		if (hasSearchParam) {
			const param = searchParams.get(name as string);
			if (!param) {
				setSelected(defaultSelected);
			}
		}
	}, [defaultSelected, hasSearchParam, name, searchParams]);

	return (
		<FormControl sx={{ width: '100%' }}>
			{label && (
				<InputLabel
					disabled={disabled}
					htmlFor={id}
					required={required}
					shrink={false}
					sx={{ position: 'static', transform: 'none' }}>
					{label}
				</InputLabel>
			)}

			<MuiSelect
				defaultValue={defaultValue}
				disabled={disabled}
				fullWidth={fullWidth}
				id={id}
				multiple={multiple}
				name={name}
				onChange={handleChange}
				renderValue={renderValue}
				required={required}
				size={size}
				value={selected}
				variant={variant}
				{...selectProps}
				{...props}>
				{options.map((option, i) => {
					return (
						<MenuItem
							dense={true}
							disabled={option.disabled}
							disableRipple={true}
							key={`menu-item-${option.value}`}
							value={option.value}>
							{multiple && <Checkbox checked={(selected as unknown[]).includes(option.value)} />}
							<ListItemText
								primary={option.label}
								primaryTypographyProps={{
									color: 'brand.grey.800',
								}}
								secondary={option.secondary}
								secondaryTypographyProps={{
									color: 'brand.grey.500',
									variant: 'caption',
								}}
							/>
						</MenuItem>
					);
				})}
			</MuiSelect>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
