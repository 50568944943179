// react
import { MouseEvent, useRef, useState } from 'react';

// types
import { ActionButton } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import {
	Button,
	ButtonGroup,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@mui/material';

// icons
import { ArrowDropDownOutlined as IconArrowDropDown } from '@mui/icons-material';

// props
export interface BtnSplitProps {
	actions: ActionButton[];
}

export const BtnSplit = ({ actions = [] }: BtnSplitProps) => {
	// refs
	const refAnchor = useRef<HTMLDivElement>(null);

	// hooks
	const { t } = useTranslation();

	// state
	const [selectedAction, setSelectedAction] = useState<ActionButton>(actions[0]);
	const [isOpen, setIsOpen] = useState(false);

	const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
		if (selectedAction.onClick) {
			selectedAction.onClick(e);
		}
	};

	const handleMenuItemClick = (
		e: MouseEvent<HTMLLIElement, globalThis.MouseEvent>,
		action: ActionButton
	) => {
		setSelectedAction(action);
		setIsOpen(false);
	};

	const handleToggle = () => {
		setIsOpen((prevIsOpen) => !prevIsOpen);
	};

	const handleClose = (e: Event) => {
		if (refAnchor.current?.contains(e.target as HTMLElement)) {
			return;
		}

		setIsOpen(false);
	};

	return (
		<>
			<ButtonGroup
				aria-label={t('btnSplit')}
				disableElevation={true}
				ref={refAnchor}
				variant="contained">
				<Button onClick={handleClick} sx={{ border: 'none !important' }}>
					{selectedAction.children}
				</Button>
				<Button
					aria-controls={isOpen ? 'split-button-menu' : undefined}
					aria-expanded={isOpen ? 'true' : undefined}
					aria-haspopup="menu"
					aria-label={t('selectAction')}
					className="icon"
					onClick={handleToggle}
					size="small">
					<IconArrowDropDown />
				</Button>
			</ButtonGroup>
			<Popper
				anchorEl={refAnchor.current}
				disablePortal={true}
				open={isOpen}
				role={undefined}
				sx={{
					zIndex: 1,
				}}
				transition={true}>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
						}}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{actions.map((action, i) => (
										<MenuItem
											key={`menu-item-${i}`}
											disabled={action.disabled}
											selected={action.children === selectedAction?.children}
											onClick={(e) => handleMenuItemClick(e, action)}>
											{action.children}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
};
