// mui
import { Box, Grid, Typography } from '@mui/material';

// types
import { MetadataField, MetadataValue } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// theme
import { theme } from '../../../theme';

// utils
import { formatDate } from '../../../utils/dates';

// props
export interface MetadataProps {
	data: MetadataField[];
}

export const Metadata = ({ data }: MetadataProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Box sx={{ padding: theme.spacing(2) }}>
			<Grid container spacing={1}>
				{data.map((entry) => {
					const { label, name, type, value } = entry;

					// redefine value based on field type
					let valueFormatted: MetadataValue = t('na');
					if (name === 'country') {
						valueFormatted = String(
							new Intl.DisplayNames(['en'], { type: 'region' }).of(String(value))
						);
					} else if (type === 'DATE') {
						valueFormatted = formatDate({ date: new Date(value as Date) });
					} else if (type === 'CHECKBOX') {
						valueFormatted = value ? t('yes') : t('no');
					} else if (value) {
						valueFormatted = value;
					}

					return (
						<Grid item xs={12} md={6} lg={4} xl={3}>
							<Grid container spacing={1}>
								<Grid item>
									<Typography color="brand.blue.700" fontWeight={600} variant="caption">
										{label}
									</Typography>
								</Grid>
								<Grid item>
									<Typography variant="caption">{valueFormatted}</Typography>
								</Grid>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};
