// types
import { Loan } from '../../../../../../@types';

// i18next
import { useTranslation } from 'react-i18next';

// material
import { Grid } from '@mui/material';

// cards
import { CardKeyValue } from '../../../../../cards/CardKeyValue/CardKeyValue';

// utils
import { formatCurrency } from '../../../../../../utils/currency';

// props
export interface SummaryProps {
	loans: Loan[];
}

export const Summary = ({ loans }: SummaryProps) => {
	// hooks
	const { t } = useTranslation();

	// vars
	let amount = 0;
	let disbursed = 0;

	for (let i = 0; i < loans.length; i++) {
		amount += loans[i].amount;
		disbursed += loans[i].disbursed;
	}

	const currency = loans?.[0]?.currency;
	const undisbursed = amount - disbursed;
	const percent = Math.round((disbursed / amount) * 100);

	return (
		<Grid container spacing={2}>
			<Grid item xs={6} lg={3}>
				<CardKeyValue
					label={t('totalAmount')}
					value={formatCurrency({ currency, value: amount })}
				/>
			</Grid>
			<Grid item xs={6} lg={3}>
				<CardKeyValue
					label={t('undisbursed')}
					value={formatCurrency({ currency, value: undisbursed })}
				/>
			</Grid>
			<Grid item xs={12} lg={6}>
				<CardKeyValue
					label={t('disbursed')}
					percent={percent}
					value={formatCurrency({ currency, value: disbursed })}
				/>
			</Grid>
		</Grid>
	);
};
