// react
import { Dispatch, MouseEvent, SetStateAction, useContext, useState } from 'react';

// types
import { Entity, ErrorCause } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// context
import { ContextBanner } from '../../../App.context';
import { ContextContracts, ContextProject } from '../../pages/Project/Project.context';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// mui
import { Alert, Grid } from '@mui/material';

// fields
import { Input } from '../../fields/Input/Input';
import { Select } from '../../fields/Select/Select';
import { SelectCurrency } from '../../fields/SelectCurrency/SelectCurrency';

// props
export interface FormAddContractProps {
	onClose?: () => void;
	setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const FormAddContract = ({ onClose, setIsLoading }: FormAddContractProps) => {
	// hooks
	const { t } = useTranslation();

	// context
	const { setBanner } = useContext(ContextBanner);
	const { refetchContracts } = useContext(ContextContracts);
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// state
	const [error, setError] = useState<string | null>(null);
	const [suppliers, setSuppliers] = useState<Entity[]>([]);

	// fetch suppliers
	useFetch({
		isEnabled: Boolean(project?.id),
		url: `${process.env.REACT_APP_API_URL}/entities?project=${project?.id}&fields=id,name&type=SUPPLIER`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setSuppliers(res.data);
			}
		},
	});

	// options
	const optionsSuppliers = suppliers.map((supplier) => {
		return {
			label: supplier.name,
			value: supplier.id,
		};
	});

	const handleSubmit = async (e: MouseEvent<HTMLFormElement>) => {
		e.preventDefault();

		const form = document.querySelector('#form-add-contract') as HTMLFormElement;
		const fd = new FormData(form);
		const entries = Object.fromEntries(fd.entries());

		try {
			// set loader
			setIsLoading(true);

			if (!entries.supplier) {
				throw new Error('A contract must be associated with a supplier.', {
					cause: {
						id: 'CONTRACT_SUPPLIER_EMPTY',
					},
				});
			}

			const body = {
				amount: Number(entries.amount),
				currency: entries.currency,
				num: entries.num,
				project: project?.id,
				idStep: entries.idStep,
				supplier: Number(entries.supplier),
			};

			const fetchAddContract = await fetch(`${process.env.REACT_APP_API_URL}/contracts`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${accessToken}`,
					User: String(idToken),
				},
				body: JSON.stringify(body),
			});
			const resAddContract = await fetchAddContract.json();

			if (resAddContract.error) {
				throw new Error(resAddContract.error.message, {
					cause: {
						id: resAddContract.error.id,
					},
				});
			}

			// close dialog
			if (onClose) {
				onClose();
			}

			// refetch
			refetchContracts();

			// set success banner
			setBanner({
				hasClose: true,
				message: t('alert.contractCreated', { num: entries.num }),
				severity: 'success',
			});
		} catch (error) {
			const err = error as Error;
			const cause = err.cause as ErrorCause;

			let message = t('error.default');

			if (cause.id === 'CONTRACT_DUPLICATE') {
				message = t('error.contractDuplicate', { num: entries.num });
			}

			if (cause.id === 'CONTRACT_SUPPLIER_EMPTY') {
				message = t('error.contractSupplierEmpty');
			}

			if (cause.id === 'CONTRACT_UNAUTHORIZED_POST') {
				message = t('error.unauthorized');
			}

			// set error
			setError(message);
		} finally {
			// set loader
			setIsLoading(false);
		}
	};

	return (
		<form id="form-add-contract" name="formAddContract" onSubmit={handleSubmit}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-contract-number"
						label={t('referenceNumber')}
						name="num"
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Select
						id="form-add-contract-supplier"
						label={t('supplier')}
						name="supplier"
						options={optionsSuppliers}
						required={true}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-contract-amount"
						label={t('amount')}
						name="amount"
						required={true}
						type="number"
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<SelectCurrency id="form-add-contract-currency" name="currency" required={true} />
				</Grid>
				<Grid item xs={12} md={6}>
					<Input
						id="form-add-contract-step-reference"
						label={t('stepID')}
						name="idStep"
						required={false}
					/>
				</Grid>
				{error && (
					<Grid item xs={12}>
						<Alert severity="error">{error}</Alert>
					</Grid>
				)}
			</Grid>
		</form>
	);
};
