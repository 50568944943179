// types
import { Entity } from '../../../@types';

// mui
import { Box, Grid, Typography } from '@mui/material';

// ui
import { IconCircle } from '../IconCircle/IconCircle';

// props
export interface BoxEntityProps {
	entity: Entity;
	label: string;
}

export const BoxEntity = ({ entity, label }: BoxEntityProps) => {
	let type = 'entity';

	if (entity.type === 'BENEFICIARY') {
		if (entity.subtype === 'INDIVIDUAL') {
			type = 'individual';
		} else if (entity.subtype === 'ORGANIZATION') {
			type = 'organization';
		}
	} else if (entity.type === 'SUPPLIER') {
		type = 'supplier';
	}

	return (
		<Box
			sx={(theme) => {
				return {
					borderColor: theme.palette.divider,
					borderRadius: 2,
					borderStyle: 'solid',
					borderWidth: 1,
					padding: theme.spacing(2),
					height: '100%',
				};
			}}>
			<Grid container spacing={1}>
				<Grid item xs="auto">
					<IconCircle size="sm" type={type} />
				</Grid>
				<Grid item xs={true}>
					<Typography color="brand.grey.500" fontWeight={600} variant="caption">
						{label}
					</Typography>
					<Typography fontWeight={600}>{entity.name}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
