// react
import { useState } from 'react';

// mui
import { Box, Grid } from '@mui/material';

// icons
import {
	VisibilityOutlined as IconVisibility,
	VisibilityOffOutlined as IconVisibilityOff,
} from '@mui/icons-material';

// props
export interface VisibilityTextToggleProps {
	char?: string;
	charsVisible?: number;
	text: number | string;
}

export const VisibilityTextToggle = ({
	char = 'X',
	charsVisible = 4,
	text,
}: VisibilityTextToggleProps) => {
	// state
	const [isVisible, setIsVisible] = useState(false);

	// vars
	let str = text as string;
	const length = str.length;
	const isLongEnough = length > charsVisible;

	// if text length is longer than visible chars
	if (!isVisible && isLongEnough) {
		// define number of hidden chars
		const numHiddenChars = length - charsVisible;

		// define new str array
		const strNewArray = str.slice(numHiddenChars, length).split('');

		// for each hidden char
		for (let x = 0; x < numHiddenChars; x++) {
			// add char to start of array
			strNewArray.unshift(char);
		}

		// redefine string
		str = strNewArray.join('');
	}

	return (
		<Grid container spacing={1}>
			<Grid item>{str}</Grid>
			{isLongEnough && (
				<Grid item>
					<Box
						component="button"
						onClick={() => setIsVisible(!isVisible)}
						sx={(theme) => {
							return {
								color: theme.palette.brand.blue[500],
								'> svg': {
									width: theme.spacing(2.5),
								},
							};
						}}>
						{isVisible ? <IconVisibilityOff /> : <IconVisibility />}
					</Box>
				</Grid>
			)}
		</Grid>
	);
};
