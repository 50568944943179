// material
import {
	Box,
	Tabs as MuiTabs,
	TabsProps as MuiTabsProps,
	Tab,
	TabProps as MuiTabProps,
} from '@mui/material';
import { NavLinkProps } from 'react-router-dom';

// props
export type TabProps = MuiTabProps & Partial<NavLinkProps>;

export interface TabsProps extends MuiTabsProps {
	hasBorder?: boolean;
	tabs: TabProps[];
}

export const Tabs = ({
	hasBorder = true,
	scrollButtons = false,
	tabs,
	variant = 'scrollable',
	...props
}: TabsProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					borderBottom: hasBorder ? `1px ${theme.palette.divider} solid` : null,
				};
			}}>
			<MuiTabs
				scrollButtons={scrollButtons}
				TabIndicatorProps={{
					sx: {
						backgroundColor: 'brand.blue.400',
					},
				}}
				textColor="secondary"
				variant={variant}
				{...props}>
				{tabs.map((tab, i) => {
					const { value, ...tabProps } = tab;
					return <Tab {...tabProps} key={`tab-${i}-${value}`} value={value} />;
				})}
			</MuiTabs>
		</Box>
	);
};
