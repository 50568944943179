// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Grid } from '@mui/material';

// layouts
import { Gateway } from '../../layouts/Gateway/Gateway';

// forms
import { FormLoginPersona } from '../../forms/FormLoginPersona/FormLoginPersona';

export const PageLoginPersona = () => {
	// hooks
	const { t } = useTranslation();

	return (
		<Gateway title={t('login.title')}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<FormLoginPersona />
				</Grid>
			</Grid>
		</Gateway>
	);
};
