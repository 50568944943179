// material
import { Button, Chip, ChipProps, Container, Grid, PaletteBrand, Typography } from '@mui/material';

// types
import { ActionButton } from '../../../@types';

// ui
import { BtnSplit } from '../BtnSplit/BtnSplit';

// props
export interface TitleProps {
	actions?: ActionButton[];
	chip?: ChipProps;
	color?: keyof PaletteBrand;
	id?: string;
	overline?: string;
	title: string | undefined;
}

export const Title = ({ actions = [], chip, color = 'grey', id, overline, title }: TitleProps) => {
	return (
		<Container maxWidth={false}>
			<Grid alignItems="center" container justifyContent={{ md: 'space-between' }} spacing={2}>
				<Grid item xs={12} md={true}>
					<Grid container spacing={1}>
						{overline && (
							<Grid item xs={12}>
								<Typography
									component="div"
									sx={(theme) => {
										return {
											color: theme.palette.brand[color as keyof PaletteBrand][500],
										};
									}}
									variant="overline">
									{overline}
								</Typography>
							</Grid>
						)}

						<Grid item xs={12}>
							<Grid
								alignItems="center"
								container
								justifyContent={{ lg: 'space-between' }}
								spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h1">{title}</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid alignItems="center" container spacing={2}>
										{id && (
											<Grid item xs="auto">
												<Typography
													fontWeight={600}
													sx={(theme) => {
														return {
															color: theme.palette.brand[color as keyof PaletteBrand][500],
														};
													}}
													variant="subtitle1">
													{id}
												</Typography>
											</Grid>
										)}
										{chip && (
											<Grid item xs="auto">
												<Chip {...chip} />
											</Grid>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{actions.length > 0 && (
					<Grid
						item
						xs={12}
						md="auto"
						sx={(theme) => {
							return {
								display: {
									xs: 'none',
									md: 'inline-flex',
								},
							};
						}}>
						<Grid container justifyContent="flex-end" spacing={2}>
							{actions.map((action, i) => {
								const { children, options, ...btnProps } = action;

								return (
									<Grid item key={`title-action-${i}`} xs={12} md="auto">
										{options ? (
											<BtnSplit actions={options} />
										) : (
											<Button {...btnProps} fullWidth>
												{children}
											</Button>
										)}
									</Grid>
								);
							})}
						</Grid>
					</Grid>
				)}
			</Grid>
		</Container>
	);
};
