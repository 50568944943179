// react
import { SyntheticEvent } from 'react';

// types
import { FilterType } from '../../../@types';

// router
import { useSearchParams } from 'react-router-dom';

// mui
import { Grid, SelectChangeEvent } from '@mui/material';

// ui
import { Autocomplete, AutocompleteProps } from '../../fields/Autocomplete/Autocomplete';

// fields
import { Select, SelectProps } from '../../fields/Select/Select';

// props
export type FilterProps = {
	type?: FilterType;
	onChange?: (
		e: SelectChangeEvent<unknown> | SyntheticEvent<Element, Event>,
		filter?: FilterProps
	) => void;
} & AutocompleteProps &
	SelectProps;

export interface FiltersProps {
	id: string;
	filters: FilterProps[];
}

export const Filters = ({ filters, id }: FiltersProps) => {
	// state
	const [searchParams] = useSearchParams();

	// handlers
	const handleChangeFilter = (
		e: SelectChangeEvent<unknown> | SyntheticEvent<Element, Event>,
		filter: FilterProps
	) => {
		// remove search param
		searchParams.delete('q');

		// clear search input
		const elQ = document.querySelector('#form-search-q') as HTMLInputElement;

		if (elQ) {
			elQ.value = '';
		}

		if (filter.onChange) {
			filter.onChange(e, filter);
		}
	};

	return (
		<form id={id}>
			<Grid container spacing={2}>
				{filters.map((filter, i) => {
					const { defaultValue, type, ...propsFilter } = filter;
					const name = String(filter.name);
					const key = `filter-${i}-${name}`;
					const param = searchParams.get(name) || '';

					if (type === 'AUTOCOMPLETE') {
						return (
							<Grid item key={key} xs={12}>
								<Autocomplete
									{...propsFilter}
									defaultValue={defaultValue}
									disablePortal={true}
									onChange={(e) => handleChangeFilter(e, filter)}
								/>
							</Grid>
						);
					} else {
						const { multiple } = filter as SelectProps;

						if (!multiple) {
							propsFilter.value = param || defaultValue;
						}

						return (
							<Grid item key={key} xs={12}>
								<Select
									{...propsFilter}
									defaultValue={defaultValue}
									multiple={multiple}
									onChange={(e) => handleChangeFilter(e, filter)}
									MenuProps={{
										disablePortal: true,
									}}
								/>
							</Grid>
						);
					}
				})}
			</Grid>
		</form>
	);
};
