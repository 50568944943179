// i18n
import { useTranslation } from 'react-i18next';

// types
import { Entity, Loan } from '../../../../@types';

// material
import { Container, Grid, Typography } from '@mui/material';

// cards
import { CardEntityDetails } from '../../../cards/CardEntityDetails/CardEntityDetails';
import { CardLoans } from '../../../cards/CardLoans/CardLoans';
import { CardTraceability } from '../../../cards/CardTraceability/CardTraceability';

// props
export interface DetailsProps {
	loans: Loan[];
	supplier: Entity;
}

export const Details = ({ loans, supplier }: DetailsProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('details')}</Typography>
				</Grid>

				<Grid item xs={12} lg={6}>
					<CardEntityDetails loans={loans} entity={supplier} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardLoans loans={loans} />
				</Grid>

				<Grid item xs={12} md={6} lg={3}>
					<CardTraceability traceability={supplier.traceability} />
				</Grid>
			</Grid>
		</Container>
	);
};
