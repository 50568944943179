// react
import { useMemo } from 'react';

// types
import { EntityTraceability, TransactionTraceability } from '../../../@types';

// i18n
import { t } from 'i18next';

// mui
import { Chip, Tooltip } from '@mui/material';

// props
export interface ChipTraceabilityProps {
	traceability: EntityTraceability | TransactionTraceability | number;
}

export const ChipTraceability = ({ traceability }: ChipTraceabilityProps) => {
	// vars
	const value = typeof traceability === 'number' ? traceability : traceability.value;
	const { documents } = traceability as TransactionTraceability;
	const { transactions } = traceability as EntityTraceability;

	// chip color
	const color = useMemo(() => {
		if (value === null) {
			return 'default';
		} else if (value <= 49) {
			return 'error';
		} else if (value >= 50 && value <= 79) {
			return 'warning';
		} else {
			return 'success';
		}
	}, [value]);

	// chip text
	const label = useMemo(() => {
		if (value === null) {
			return t('na');
		} else {
			return `${value}%`;
		}
	}, [value]);

	const chip = <Chip color={color} label={label} size="small" variant="twoTone" />;

	if (documents) {
		return (
			<Tooltip
				placement="top"
				title={t('traceabilityDocuments', {
					required: documents.required.length,
					uploaded: documents.uploaded.length,
				})}>
				{chip}
			</Tooltip>
		);
	}

	if (transactions) {
		return (
			<Tooltip
				placement="top"
				title={t('traceabilityUndocumented', {
					undocumented: transactions.undocumented,
				})}>
				{chip}
			</Tooltip>
		);
	}

	return chip;
};
