// react
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// types
import { Option } from '../../../@types';

// mui
import {
	Autocomplete as MuiAutocomplete,
	AutocompleteChangeReason,
	AutocompleteProps as MuiAutocompleteProps,
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';

// ui
import TextField from '@mui/material/TextField';

// icons
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export type MuiAutocompletePropsOmit = Omit<
	MuiAutocompleteProps<Option, boolean | undefined, boolean | undefined, boolean | undefined>,
	'renderInput'
>;

export interface AutocompleteProps extends MuiAutocompletePropsOmit {
	checkedIcon?: ReactNode;
	hasAllOption?: boolean;
	hasSearchParam?: boolean;
	icon?: ReactNode;
	label?: string;
	name?: string;
	options: Option[];
	required?: boolean;
}

export const Autocomplete = ({
	checkedIcon = <CheckBoxIcon fontSize="small" />,
	defaultValue,
	disableCloseOnSelect = true,
	hasAllOption = false,
	hasSearchParam = false,
	icon = <CheckBoxOutlineBlankIcon fontSize="small" />,
	id,
	label,
	multiple = true,
	name,
	onChange,
	options = [],
	placeholder = '',
	required,
	size = 'small',
	...props
}: AutocompleteProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [selected, setSelected] = useState<MuiAutocompletePropsOmit['defaultValue']>(defaultValue);
	const [searchParams, setSearchParams] = useSearchParams();

	// handlers
	const handleChangeFilter = (
		e: SyntheticEvent<Element>,
		selected: Option | Option[] | null,
		reason: AutocompleteChangeReason
	) => {
		if (selected) {
			const { checked } = e.target as HTMLInputElement;
			const isSelectedArray = Array.isArray(selected);
			let paramNew;

			// if selected is array
			if (isSelectedArray) {
				// look for "all" option
				const optionAll = selected.find((option) => option.label === t('all'));

				if (optionAll && checked) {
					// set all options
					setSelected(options);
				} else {
					// filter out "all" option
					const selectedFiltered = selected.filter((option) => option.label !== t('all'));

					// define search param
					paramNew = selectedFiltered.map((option) => option.value).join(',');

					// set selected options
					setSelected(selectedFiltered);
				}
			} else {
				// define search param
				paramNew = selected.value as string;

				// set selected options
				setSelected(selected);
			}

			if (hasSearchParam) {
				if (!paramNew) {
					searchParams.delete(name as string);
				} else {
					searchParams.set(name as string, paramNew);
				}

				setSearchParams(searchParams);
			}

			if (onChange) {
				onChange(e, selected, reason);
			}
		}
	};

	// add all option
	if (!required && multiple && hasAllOption) {
		if (!options.find((option) => option.label === t('all'))) {
			options.unshift({
				label: t('all'),
				value: '',
			});
		}
	}

	// reset selected if search param does not exist
	useEffect(() => {
		if (hasSearchParam) {
			const param = searchParams.get(name as string);
			if (!param) {
				setSelected(defaultValue);
			}
		}
	}, [defaultValue, hasSearchParam, name, searchParams]);

	return (
		<FormControl sx={{ width: '100%' }}>
			{label && (
				<InputLabel
					htmlFor={id}
					required={required}
					shrink={false}
					sx={{ position: 'static', transform: 'none' }}>
					{label}
				</InputLabel>
			)}
			<MuiAutocomplete
				disableCloseOnSelect={true}
				getOptionLabel={(option) => (option as Option).label}
				id={id}
				isOptionEqualToValue={(option, value) => option.value === value.value}
				multiple={multiple}
				onChange={(e, selected, reason) => {
					handleChangeFilter(e, selected as Option, reason);
				}}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				options={options as any[]}
				renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
				size={size}
				renderOption={(props, option, { selected }) => {
					return (
						<MenuItem {...props} dense={true}>
							<Checkbox
								checked={selected}
								checkedIcon={checkedIcon}
								disableRipple={true}
								icon={icon}
								id={`${id}-checkbox-${option.value}`}
								name={`${id}-checkbox-${option.label}`}
								value={option.value}
							/>
							<ListItemText primary={option.label} secondary={option.secondary} />
						</MenuItem>
					);
				}}
				renderTags={(options) => {
					// define label as comma separated list
					let label = (options as Option[]).map((option) => option.label).join(', ');

					// find "all" option
					const optionAll = options.find((option) => option.label === t('all'));

					if (optionAll) {
						label = t('all');
					}

					return (
						<Typography
							sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								width: '80%',
								whiteSpace: 'nowrap',
							}}>
							{label}
						</Typography>
					);
				}}
				value={selected}
				{...props}
			/>
		</FormControl>
	);
};
