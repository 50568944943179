// i18n
import { TFunction } from 'i18next';
import { Trans } from 'react-i18next';

// types
import { Activity } from '../@types';

// router
import { Link as ReactRouter } from 'react-router-dom';

// material
import { Link, Typography } from '@mui/material';

// links
import { LinkDocument } from '../components/links/LinkDocument/LinkDocument';
import { LinkEntity } from '../components/links/LinkEntity/LinkEntity';
import { LinkSupplier } from '../components/links/LinkSupplier/LinkSupplier';

// props
import { LineItemProps } from '../components/ui/LineItem/LineItem';

// utils
import { formatCurrency } from './currency';
import { translateType } from './translations';
import { truncate } from './strings';

interface ConstructActivityItemsProps {
	activities: Activity[];
	t: TFunction<'translation', undefined>;
}

export const constructActivityItems = ({
	activities,
	t,
}: ConstructActivityItemsProps): LineItemProps[] => {
	return activities.map((activity) => {
		let components;
		let i18nKey;
		let type;
		let values;
		let linkSupplier;
		let linkEntity;
		let isSupplier;

		if (activity.entity) {
			linkSupplier = <LinkSupplier project={activity.project} supplier={activity.entity} />;
			linkEntity = <LinkEntity project={activity.project} entity={activity.entity} />;
			isSupplier = activity.entity.type === 'SUPPLIER';
		} else {
			linkSupplier = (
				<Link
					component={ReactRouter}
					fontWeight={600}
					to={`/project/${activity.project?.idWbg}/suppliers`}
					underline="hover"
				/>
			);
			linkEntity = (
				<Link
					component={ReactRouter}
					fontWeight={600}
					to={`/project/${activity.project?.idWbg}/entities`}
					underline="hover"
				/>
			);
		}

		switch (activity.type) {
			case 'ACCOUNT_CREATED':
				if (activity.account) {
					components = {
						anchor: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}/accounts`}
								underline="hover"
							/>
						),
					};
					i18nKey = 'activities.accountCreated';
					type = activity.account.type === 'LOCAL' ? 'local' : 'account';
					values = { name: activity.account.name };
				}
				break;

			case 'CONTRACT_CREATED':
				if (activity.contract) {
					components = {
						anchor: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}/contracts`}
								underline="hover"
							/>
						),
					};
					i18nKey = 'activities.contractCreated';
					type = 'contract';
					values = { num: activity.contract.num };
				}
				break;

			case 'DOCUMENT_CREATED':
				if (activity.document) {
					components = {
						anchor: <LinkDocument document={activity.document} />,
					};
					i18nKey = 'activities.documentCreated';
					type = 'document';
					values = { name: `${truncate({ end: 20, str: activity.document.name })}...` };
				}
				break;

			case 'ENTITY_CREATED':
				if (activity.entity) {
					components = {
						anchor: isSupplier ? linkSupplier : linkEntity,
					};
					i18nKey = 'activities.entityCreated';
					type = isSupplier ? 'supplier' : 'entity';
					values = {
						name: activity.entity.name,
						type: translateType(activity.entity.type, t),
					};
				}
				break;

			case 'ENTITY_UPDATED':
				if (activity.entity) {
					components = {
						anchor: isSupplier ? linkSupplier : linkEntity,
					};
					i18nKey = 'activities.entityUpdated';
					type = isSupplier ? 'supplier' : 'entity';
					values = {
						name: activity.entity.name,
						type: translateType(activity.entity.type, t),
					};
				}
				break;

			case 'ENTITY_WALLET_ACTIVATED':
				if (activity.entity) {
					components = {
						anchor: isSupplier ? linkSupplier : linkEntity,
					};
					i18nKey = 'activities.entityWalletActivated';
					type = isSupplier ? 'supplier' : 'entity';
					values = {
						name: activity.entity.name,
						type: translateType(activity.entity.type, t),
					};
				}
				break;

			case 'ENTITY_WALLET_DEACTIVATED':
				if (activity.entity) {
					components = {
						anchor: isSupplier ? linkSupplier : linkEntity,
					};
					i18nKey = 'activities.entityWalletDeactivated';
					type = isSupplier ? 'supplier' : 'entity';
					values = {
						name: activity.entity.name,
						type: translateType(activity.entity.type, t),
					};
				}
				break;

			case 'LOAN_CREATED':
				if (activity.loan) {
					components = {
						anchor: <strong />,
					};
					i18nKey = 'activities.loanCreated';
					type = 'loan';
					values = { id: activity.loan.idWbg };
				}
				break;

			case 'LOAN_ENABLED':
				if (activity.loan) {
					components = {
						anchor: <strong />,
					};
					i18nKey = 'activities.loanEnabled';
					type = 'loan';
					values = { id: activity.loan.idWbg };
				}
				break;

			case 'PROJECT_CREATED':
				if (activity.project) {
					components = {
						anchor: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}`}
								underline="hover"
							/>
						),
					};
					i18nKey = 'activities.projectCreated';
					type = 'project';
					values = { id: activity.project?.idWbg };
				}
				break;

			case 'TRANSACTION_CREATED':
				if (activity.transaction) {
					const amount = formatCurrency({
						currency: activity.transaction.currency,
						value: activity.transaction.amount,
					});
					const fromAccount = activity.fromAccount;
					const toAccount = activity.toAccount;

					values = { amount, fromAccount, toAccount };

					let tab = `payments`;

					switch (activity.transaction.type) {
						case 'CASH_BENEFIT':
							i18nKey = 'activities.transactionPaymentCreated';
							type = 'payment';
							break;
						case 'OPEX':
							i18nKey = 'activities.transactionOpExCreated';
							tab = 'opex';
							type = 'opex';
							break;
						case 'PAYMENT':
							i18nKey = 'activities.transactionPaymentCreated';
							type = 'payment';
							break;
						case 'REPLENISHMENT':
							i18nKey = 'activities.transactionReplenishmentCreated';
							type = 'replenishment';
							break;
						case 'TRANSFER':
							i18nKey = 'activities.transactionTransferCreated';
							tab = 'transfers';
							type = 'transfer';
							break;
					}

					components = {
						anchor: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}/transactions/${tab}`}
								underline="hover"
							/>
						),
						anchorFromAccount: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}/accounts/`}
								underline="hover"
							/>
						),
						anchorToAccount: (
							<Link
								component={ReactRouter}
								fontWeight={600}
								to={`/project/${activity.project?.idWbg}/accounts/`}
								underline="hover"
							/>
						),
					};
				}
				break;

			case 'USER_CREATED':
				if (activity.user) {
					components = { strong: <strong /> };
					i18nKey = 'activities.userCreated';
					type = 'user';
					values = { name: `${activity.user.firstName} ${activity.user.lastName}` };
				}
				break;
		}

		return {
			content: (
				<Typography>
					<Trans components={components} i18nKey={i18nKey} values={values} />
				</Typography>
			),
			date: new Date(activity.created),
			type,
		};
	});
};
