// mui
import { Box, Chip, ChipProps, Grid, Typography } from '@mui/material';

// props
export interface ChipBoxProps {
	color?: ChipProps['color'];
	label: string | undefined;
	value: string | number;
}

export const ChipBox = ({ color, label, value }: ChipBoxProps) => {
	return (
		<Box
			sx={(theme) => {
				return {
					border: `1px ${theme.palette.brand.grey[300]} solid`,
					borderRadius: 2,
					padding: theme.spacing(1),
				};
			}}>
			<Grid
				alignItems="center"
				justifyContent={{ xs: 'space-between', md: 'flex-start' }}
				container
				spacing={1}>
				<Grid item>
					<Chip className="tag" color={color} label={label} variant="twoTone" />
				</Grid>
				<Grid item>
					<Typography fontWeight={600}>{value}</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};
