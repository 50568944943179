// react
import { useContext, useMemo } from 'react';

// types
import { Entity } from '../../../../../@types';

// context
import { ContextEntitiesSummary } from '../../Project.context';

// i18n
import { useTranslation } from 'react-i18next';

// material
import { Container, Grid, Typography } from '@mui/material';

// cards
import { CardKeyValue } from '../../../../cards/CardKeyValue/CardKeyValue';

export const Summary = () => {
	// hooks
	const { t } = useTranslation();

	// context
	const { entitiesSummary, isLoadingEntitiesSummary } = useContext(ContextEntitiesSummary);

	const summary = useMemo(() => {
		let borrowers = 0;
		let intermediaries = 0;
		let pius = 0;
		let subPius = 0;

		for (let i = 0; i < entitiesSummary.length; i++) {
			const entity: Entity = entitiesSummary[i];

			switch (entity.type) {
				case 'BORROWER': {
					borrowers++;
					break;
				}

				case 'INTERMEDIARY': {
					intermediaries++;
					break;
				}

				case 'PIU': {
					pius++;
					break;
				}

				case 'SUB_PIU': {
					subPius++;
					break;
				}
			}
		}

		return {
			borrowers,
			intermediaries,
			pius,
			subPius,
		};
	}, [entitiesSummary]);

	return (
		<Container maxWidth={false}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h2">{t('summary')}</Typography>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						isLoading={isLoadingEntitiesSummary}
						label={t('pius')}
						value={summary.pius}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						isLoading={isLoadingEntitiesSummary}
						label={t('subPius')}
						value={summary.subPius}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						isLoading={isLoadingEntitiesSummary}
						label={t('borrowers')}
						value={summary.borrowers}
					/>
				</Grid>
				<Grid item xs={6} lg={true}>
					<CardKeyValue
						isLoading={isLoadingEntitiesSummary}
						label={t('intermediaries')}
						value={summary.intermediaries}
					/>
				</Grid>
			</Grid>
		</Container>
	);
};
