// react
import { SyntheticEvent, useContext, useState } from 'react';

// types
import { Account, AccountEntity, Transaction } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Alert, Box, Grid, Link, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// router
import { Link as RouterLink } from 'react-router-dom';

// ui
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { LineItem } from '../../ui/LineItem/LineItem';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// icons
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// utils
import { formatCurrency } from '../../../utils/currency';

// tables
import { TableBasicDocs } from '../../tables/basic/TableBasicDocs/TableBasicDocs';
import { TableBasicPayments } from '../../tables/basic/TableBasicPayments/TableBasicPayments';

// theme
import { theme } from '../../../theme';

// context
import { ContextProject } from '../../pages/Project/Project.context';

// props
export interface CardTransferProps {
	transfer: Transaction;
}

export const CardTransfer = ({ transfer }: CardTransferProps) => {
	// context
	const { project } = useContext(ContextProject);

	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [tabValue, setTabValue] = useState('financing');

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	// vars
	const transferFromAccount = transfer.fromAccount as Account;
	const transferToAccount = transfer.toAccount as Account;
	const transferFromAccountEntity = (transferFromAccount.entities as AccountEntity[]).find(
		(e) => e.relationship === 'OWNER'
	)?.entity;
	const transferToAccountEntity = (transferToAccount.entities as AccountEntity[]).find(
		(e) => e.relationship === 'OWNER'
	)?.entity;

	const tabs = [
		{
			label: t('financing'),
			value: 'financing',
		},
		{
			label: t('documents'),
			value: 'documents',
		},
		{
			label: t('comments'),
			value: 'comments',
		},
	];

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box sx={{ padding: theme.spacing(1.5) }}>
					<Grid container alignItems={'center'} spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={{
									margin: theme.spacing(0, 0, 2, 0),
									[theme.breakpoints.up('lg')]: {
										margin: 0,
									},
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type={'transfer'} />
									</Grid>
									<Grid item>
										<RowKeyValue
											label={t('date')}
											value={
												transfer.date ? (
													<Time date={new Date(transfer.date)} />
												) : (
													<Typography>{t('na')}</Typography>
												)
											}
										/>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('fromAccount')}
								value={
									<>
										{transferFromAccountEntity && (
											<Link
												component={RouterLink}
												fontWeight={600}
												to={`/project/${project?.idWbg}/entity/${transferFromAccountEntity.id}`}
												underline="hover">
												{transferFromAccountEntity.name}
											</Link>
										)}
										<Typography>{transferFromAccount.name}</Typography>
									</>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('amountSent')}
								value={
									<Typography>
										{formatCurrency({
											currency: transfer.currency,
											value: transfer.amount,
										})}
									</Typography>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue label={t('status')} value={<ChipStatus status={transfer.status} />} />
						</Grid>
						<Grid item xs={12} lg={2}>
							<RowKeyValue
								label={t('toAccount')}
								value={
									<>
										{transferToAccountEntity && (
											<Link
												component={RouterLink}
												fontWeight={600}
												to={`/project/${project?.idWbg}/entity/${transferToAccountEntity.id}`}
												underline="hover">
												{transferToAccountEntity.name}
											</Link>
										)}
										<Typography>{transferToAccount.name}</Typography>
									</>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('amountReceived')}
								value={
									<Typography>
										{formatCurrency({
											currency: transfer.currencyReceived || transfer.currency,
											value: transfer.amountReceived || transfer.amount,
										})}
									</Typography>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="financing">
							<TableBasicPayments payments={transfer.payments} transaction={transfer} />
						</TabPanel>
						<TabPanel value="documents">
							<TableBasicDocs transaction={transfer} />
						</TabPanel>
						<TabPanel value="comments">
							{transfer?.metadata?.comments ? (
								<LineItem
									content={transfer?.metadata?.comments}
									IconCircleProps={{ size: 'sm', type: 'comment' }}></LineItem>
							) : (
								<Alert severity="info">{t('alert.empty')}</Alert>
							)}
						</TabPanel>
					</TabContext>
				)}
			</Paper>
		</>
	);
};
