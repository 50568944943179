// react
import { MouseEvent, ReactNode, SyntheticEvent, useContext, useState } from 'react';

// types
import { Loan, Project } from '../../../@types';

// context
import { ContextUser } from '../../../App.context';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

// material
import { Box, Grid, Paper, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';

// ui
import { BtnExpand } from '../../ui/BtnExpand/BtnExpand';
import { BtnMenu } from '../../ui/BtnMenu/BtnMenu';
import { ChipStatus } from '../../ui/ChipStatus/ChipStatus';
import { ChipTraceability } from '../../ui/ChipTraceability/ChipTraceability';
import { IconCircle } from '../../ui/IconCircle/IconCircle';
import { MenuItemProps } from '../../ui/Menu/Menu';
import { RowKeyValue } from '../../ui/RowKeyValue/RowKeyValue';
import { Tabs } from '../../ui/Tabs/Tabs';
import { Time } from '../../ui/Time/Time';

// dialogs
import { DialogEnableLoan } from '../../dialogs/DialogEnableLoan/DialogEnableLoan';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	AssignmentOutlined as IconAssignment,
	AssignmentTurnedInOutlined as IconAssignmentTurnedIn,
	LocalAtmOutlined as IconLocalAtm,
	PaidOutlined as IconPaid,
	SwapHorizOutlined as IconSwapHoriz,
} from '@mui/icons-material';

// utils
import { formatCurrency } from '../../../utils/currency';
import { TableBasicTransactions } from '../../tables/basic/TableBasicTransactions/TableBasicTransactions';

export interface RenderKeyValueParams {
	label: string;
	value: ReactNode;
}

export interface CardLoanProps {
	loan: Loan;
	project: Project;
}

export const CardLoan = ({ loan, project }: CardLoanProps) => {
	// hooks
	const { t } = useTranslation();

	// state
	const [isExpanded, setIsExpanded] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [tabValue, setTabValue] = useState('transactions');

	// context
	const { user } = useContext(ContextUser);

	// params
	const paramDialog = searchParams.get('dialog');
	const paramDialogLoan = searchParams.get('dialogLoan');

	// vars
	const isDisabled = loan.status === 'DISABLED';

	// handlers
	const handleToggleCard = () => {
		setIsExpanded(!isExpanded);
	};

	const handleCloseDialogEnableLoan = (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
		searchParams.delete('dialog');
		searchParams.delete('dialogLoan');
		setSearchParams(searchParams);
	};

	const handleChangeTab = (e: SyntheticEvent, value: string) => {
		setTabValue(value);
	};

	const tabs = [
		{
			label: t('recentTransactions'),
			value: 'transactions',
		},
	];

	const btnMenuItems: MenuItemProps[] = [];

	if (user?.permissions?.entity?.create) {
		btnMenuItems.push({
			children: t('createEntity'),
			component: RouterLink,
			disabled: isDisabled,
			divider: true,
			icon: <IconAccountBalance />,
			to: `/project/${project.idWbg}/entities?dialog=addEntity`,
		});
	}

	if (user?.permissions?.transaction?.create) {
		btnMenuItems.push(
			{
				children: t('recordPayment'),
				component: RouterLink,
				disabled: isDisabled,
				icon: <IconPaid />,
				to: `/project/${project?.idWbg}/transactions/payments?dialog=recordPayment`,
			},
			{
				children: t('recordOpEx'),
				component: RouterLink,
				disabled: isDisabled,
				icon: <IconLocalAtm />,
				to: `/project/${project?.idWbg}/transactions/opex?dialog=recordOpEx`,
			},
			{
				children: t('recordFundTransfer'),
				component: RouterLink,
				disabled: isDisabled,
				divider: true,
				icon: <IconSwapHoriz />,
				to: `/project/${project?.idWbg}/transactions/transfers?dialog=recordFundTransfer`,
			}
		);
	}

	if (user?.permissions?.contract?.create) {
		btnMenuItems.push({
			children: t('addContract'),
			component: RouterLink,
			disabled: isDisabled,
			icon: <IconAssignment />,
			to: `/project/${project.idWbg}/contracts?dialog=addContract`,
		});
	}

	if (isDisabled && user?.permissions?.loan?.update) {
		btnMenuItems.unshift({
			children: t('enableLoan'),
			disabled: project.status === 'PENDING',
			divider: true,
			icon: <IconAssignmentTurnedIn color="success" />,
			onClick: () => {
				searchParams.set('dialog', 'enableLoan');
				searchParams.set('dialogLoan', loan.idWbg);
				setSearchParams(searchParams);
			},
		});
	}

	return (
		<>
			<Paper
				sx={(theme) => {
					const borderColor = isExpanded ? theme.palette.brand.blue[500] : 'transparent';
					return {
						border: `1px ${borderColor} solid`,
						position: 'relative',
					};
				}}>
				<Box
					sx={(theme) => {
						return {
							padding: theme.spacing(2),
						};
					}}>
					<Grid alignItems="center" container spacing={{ xs: 1, md: 2 }}>
						<Grid item xs={12} lg={2}>
							<Box
								sx={(theme) => {
									return {
										margin: theme.spacing(0, 0, 2, 0),
										[theme.breakpoints.up('lg')]: {
											margin: 0,
										},
									};
								}}>
								<Grid alignItems="center" container spacing={2} wrap="nowrap">
									<Grid item>
										<IconCircle type="loan" />
									</Grid>
									<Grid item>
										<Typography fontWeight={600}>{loan.idWbg}</Typography>
									</Grid>
								</Grid>
							</Box>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('closing')}
								value={<Time date={new Date(loan.dateClosing)} />}
							/>
						</Grid>
						<Grid item xs={12} lg={1}>
							<RowKeyValue
								label={t('currency')}
								value={<Typography component="div">{loan.currency}</Typography>}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('amount')}
								value={
									<Typography component="div">
										{formatCurrency({ currency: loan.currency, value: loan.amount })}
									</Typography>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={1.5}>
							<RowKeyValue
								label={t('undisbursed')}
								value={
									<Typography component="div">
										{formatCurrency({
											currency: loan.currency,
											value: loan.amount - loan.disbursed,
										})}
									</Typography>
								}
							/>
						</Grid>
						<Grid display="inline-flex" item xs={12} lg={1}>
							<RowKeyValue
								label={t('traceability')}
								value={<ChipTraceability traceability={loan.traceability} />}
							/>
						</Grid>
						<Grid display="inline-flex" item xs={12} lg={2}>
							<RowKeyValue
								label={t('status')}
								value={
									<ChipStatus
										color={project.status === 'PENDING' ? 'default' : null}
										status={loan.status}
									/>
								}
							/>
						</Grid>
						<Grid item xs={12} lg={true}>
							<Grid alignItems="center" container justifyContent={{ lg: 'flex-end' }} spacing={1}>
								{btnMenuItems.length > 0 && (
									<Grid
										item
										sx={(theme) => {
											return {
												position: 'absolute',
												top: 0,
												right: theme.spacing(1),
												[theme.breakpoints.up('lg')]: {
													position: 'relative',
													top: 'auto',
													right: 'auto',
												},
											};
										}}>
										<BtnMenu items={btnMenuItems} />
									</Grid>
								)}
								<Grid item>
									<BtnExpand
										isExpanded={isExpanded}
										label={isExpanded ? t('hideDetails') : t('seeDetails')}
										onClick={handleToggleCard}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
				{isExpanded && (
					<TabContext value={tabValue}>
						<Tabs hasBorder={false} onChange={handleChangeTab} tabs={tabs} value={tabValue} />
						<TabPanel value="transactions">
							<TableBasicTransactions loan={loan.id} />
						</TabPanel>
					</TabContext>
				)}
			</Paper>
			{isDisabled && (
				<DialogEnableLoan
					isOpen={paramDialog === 'enableLoan' && paramDialogLoan === loan.idWbg}
					loan={loan}
					onClose={handleCloseDialogEnableLoan}
				/>
			)}
		</>
	);
};
