// types
import { Loan } from '../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// mui
import { Grid, Paper, Typography } from '@mui/material';

// ui
import { IconCircle } from '../../ui/IconCircle/IconCircle';

// props
export interface CardLoansProps {
	loans: Loan[];
}

export const CardLoans = ({ loans }: CardLoansProps) => {
	// hooks
	const { t } = useTranslation();

	return (
		<Paper
			sx={(theme) => {
				return {
					padding: theme.spacing(2),
					height: '100%',
				};
			}}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography variant="h3">{t('financingSources')}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{loans.map((loan, i) => {
							return (
								<Grid item key={`loan-${loan.id}-${i}`} xs={12}>
									<Grid alignItems="center" container spacing={1}>
										<Grid item>
											<IconCircle type="loan" />
										</Grid>
										<Grid item>
											<Typography fontWeight={600}>{loan.idWbg}</Typography>
										</Grid>
									</Grid>
								</Grid>
							);
						})}
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	);
};
