// react
import { Suspense } from 'react';
import ReactDOM from 'react-dom';

// azure
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

// msal
import { config as configMsal } from './msal';

// i18n
import './i18n';

// components
import { App } from './App';

// styles
import './global.css';

// msal
const msalInstance = new PublicClientApplication(configMsal);

ReactDOM.render(
	<MsalProvider instance={msalInstance}>
		<Suspense fallback={<></>}>
			<App />
		</Suspense>
	</MsalProvider>,
	document.getElementById('root')
);
