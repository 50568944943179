// react
import { ReactNode, useContext, useEffect, useState } from 'react';

// types
import { EntityType, Option, Loan } from '../../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// router
import { useSearchParams } from 'react-router-dom';

// orgchart
import { Tree, TreeNode } from 'react-organizational-chart';

// context
import { ContextProject } from '../../Project.context';

// hooks
import { useFetch } from '../../../../../hooks/useFetch';

// mui
import { Container, Grid, Paper, SelectChangeEvent, Typography } from '@mui/material';

// theme
import { theme } from '../../../../../theme';

// fields
import { Select } from '../../../../fields/Select/Select';

// local
import { Node } from './Node/Node';

// img
import { LogoWorldBank } from '../../../../img/LogoWorldBank';
import { IconCircle, IconCircleProps } from '../../../../ui/IconCircle/IconCircle';

// props
export interface EntityNode {
	children?: EntityNode[];
	id?: number;
	name: string;
	type?: EntityType;
}

export interface LegendItem {
	color: IconCircleProps['color'];
	label: string;
	type: IconCircleProps['type'];
	value: EntityType;
}

export const Hierarchy = () => {
	// hooks
	const { t } = useTranslation();

	// state
	const [hierarchy, setHierarchy] = useState<EntityNode[]>([]);
	const [loan, setLoan] = useState('');
	const [loans, setLoans] = useState<Loan[]>([]);
	const [searchParams, setSearchParams] = useSearchParams();

	// params
	const paramLoan = searchParams.get('loan');

	// context
	const { project } = useContext(ContextProject);

	// session
	const accessToken = sessionStorage.getItem('accessToken');
	const idToken = sessionStorage.getItem('idToken');

	// fetch loans
	useFetch({
		url: `${process.env.REACT_APP_API_URL}/loans?project=${project?.id}&fields=id,idWbg`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setLoans(res.data);
			}
		},
	});

	// fetch hierarchy
	const { fetchRequest: fetchHierarchy } = useFetch({
		isLazy: true,
		url: `${process.env.REACT_APP_API_URL}/hierarchies/${loan}`,
		options: {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				User: String(idToken),
			},
		},
		onSuccess: (res) => {
			if (res.data) {
				setHierarchy(res.data);
			}
		},
	});

	const optionsLoans: Option[] = loans.map((loan: Loan) => ({
		label: loan.idWbg,
		value: String(loan.id),
	}));

	const legend: LegendItem[] = [
		{
			color: 'purple',
			label: t('piu'),
			type: 'entity',
			value: 'PIU',
		},
		{
			color: 'magenta',
			label: t('subPiu'),
			type: 'entity',
			value: 'SUB_PIU',
		},
		{
			color: 'yellow',
			label: t('borrower'),
			type: 'entity',
			value: 'BORROWER',
		},
		{
			color: 'orange',
			label: t('intermediary'),
			type: 'entity',
			value: 'INTERMEDIARY',
		},
		{
			color: 'green',
			label: t('supplier'),
			type: 'supplier',
			value: 'SUPPLIER',
		},
		{
			color: 'blue',
			label: t('beneficiary'),
			type: 'organization',
			value: 'BENEFICIARY',
		},
	];

	const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
		const { value } = event.target;

		// set search params
		searchParams.set('loan', String(value));
		setSearchParams(searchParams);

		// set loan
		setLoan(String(value));
	};

	const renderTreeNode = (entity: EntityNode) => {
		const childrenFiltered: EntityNode[] = [];
		let numOfBeneficiaries = 0;
		let numOfSuppliers = 0;

		if (entity.children) {
			for (let i = 0; i < entity.children.length; i++) {
				const child = entity.children[i];

				if (child.type === 'BENEFICIARY') {
					numOfBeneficiaries++;
				} else if (child.type === 'SUPPLIER') {
					numOfSuppliers++;
				} else {
					childrenFiltered.push(child);
				}
			}
		}

		const nodes: ReactNode[] = [];

		if (numOfBeneficiaries > 0) {
			nodes.push(
				<TreeNode
					label={
						<Node legend={legend} summary={{ count: numOfBeneficiaries, type: 'BENEFICIARY' }} />
					}
				/>
			);
		}

		if (numOfSuppliers > 0) {
			nodes.push(
				<TreeNode
					label={<Node legend={legend} summary={{ count: numOfSuppliers, type: 'SUPPLIER' }} />}
				/>
			);
		}

		return (
			<TreeNode key={entity.id} label={<Node entity={entity} legend={legend} />}>
				{nodes}
				{childrenFiltered.map(renderTreeNode)}
			</TreeNode>
		);
	};

	// set default loan
	useEffect(() => {
		if (paramLoan) {
			setLoan(paramLoan);
		} else if (loans.length) {
			setLoan(String(loans[0].id));
		}
	}, [loans, paramLoan]);

	// fetch hierarchy when loan changes
	useEffect(() => {
		if (loan) {
			fetchHierarchy();
		}
	}, [fetchHierarchy, loan]);

	return (
		<Grid container rowSpacing={2}>
			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Grid alignItems="stretch" container justifyContent="space-between" spacing={2}>
						<Grid item xs={12} lg={9}>
							<Paper
								sx={(theme) => {
									return {
										padding: theme.spacing(2),
										display: 'flex',
										alignItems: 'center',
										height: '100%',
									};
								}}>
								<Grid alignItems="center" container spacing={2}>
									<Grid item xs={12} lg="auto">
										<Typography variant="h3">{t('legend')}</Typography>
									</Grid>
									{legend.map((item, i) => {
										return (
											<Grid item key={`legend-item-${i}`}>
												<Grid alignItems="center" container spacing={1}>
													<Grid item>
														<IconCircle color={item.color} size="sm" type={item.type} />
													</Grid>
													<Grid item>
														<Typography fontWeight={600}>{item.label}</Typography>
													</Grid>
												</Grid>
											</Grid>
										);
									})}
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} lg={3}>
							<Paper
								sx={(theme) => {
									return {
										padding: theme.spacing(2),
										height: '100%',
									};
								}}>
								<Select
									onChange={handleSelectChange}
									options={optionsLoans}
									hasNoneOption={false}
									value={loan}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</Grid>

			<Grid item xs={12}>
				<Container maxWidth={false}>
					<Paper
						elevation={0}
						sx={(theme) => {
							return {
								backgroundColor: 'transparent',
								padding: theme.spacing(4, 0),
								width: '100%',
								overflowX: 'auto',
							};
						}}>
						<Grid container>
							<Grid item xs={12}>
								<Tree
									label={
										<Node
											entity={{ name: 'World Bank Group' }}
											icon={
												<LogoWorldBank
													hasLogoType={false}
													hasSubtitle={false}
													style={{ width: 30 }}
												/>
											}
										/>
									}
									lineColor={theme.palette.brand.grey[400]}
									lineWidth="1px"
									nodePadding="5px">
									{hierarchy.map((entity) => {
										return renderTreeNode(entity);
									})}
								</Tree>
							</Grid>
						</Grid>
					</Paper>
				</Container>
			</Grid>
		</Grid>
	);
};
