// react
import { createContext, Dispatch, SetStateAction } from 'react';

// types
import { Entity, Pagination, Transaction, User } from '../../../@types';

// defaults
import { defaultPagination } from '../../../utils/defaults';

// beneficiaries
export interface ContextEntityBeneficiariesProps {
	beneficiaries: Entity[];
	isLoadingBeneficiaries: boolean;
	paginationBeneficiaries: Pagination;
	refetchBeneficiaries: () => void;
	setBeneficiaries: Dispatch<SetStateAction<Entity[]>>;
	setPaginationBeneficiaries: Dispatch<SetStateAction<Pagination>>;
}

export const ContextEntityBeneficiaries = createContext<ContextEntityBeneficiariesProps>({
	beneficiaries: [],
	isLoadingBeneficiaries: false,
	paginationBeneficiaries: defaultPagination,
	refetchBeneficiaries: () => {
		console.log('REFETCH BENEFICIARIES');
	},
	setPaginationBeneficiaries: () => {
		console.log('SET PAGINATION');
	},
	setBeneficiaries: () => {
		console.log('SET BENEFICIARIES');
	},
});

// suppliers
export interface ContextEntitySuppliersProps {
	isLoadingSuppliers: boolean;
	paginationSuppliers: Pagination;
	refetchSuppliers: () => void;
	setPaginationSuppliers: Dispatch<SetStateAction<Pagination>>;
	setSuppliers: Dispatch<SetStateAction<Entity[]>>;
	suppliers: Entity[];
}

export const ContextEntitySuppliers = createContext<ContextEntitySuppliersProps>({
	isLoadingSuppliers: false,
	paginationSuppliers: defaultPagination,
	refetchSuppliers: () => {
		console.log('REFETCH SUPPLIERS');
	},
	setPaginationSuppliers: () => {
		console.log('SET PAGINATION');
	},
	setSuppliers: () => {
		console.log('SET SUPPLIERS');
	},
	suppliers: [],
});

// payments
export interface ContextEntityPaymentsProps {
	isLoadingPayments: boolean;
	paginationPayments: Pagination;
	payments: Transaction[];
	refetchPayments: () => void;
	setPaginationPayments: Dispatch<SetStateAction<Pagination>>;
	setPayments: Dispatch<SetStateAction<Transaction[]>>;
}

export const ContextEntityPayments = createContext<ContextEntityPaymentsProps>({
	isLoadingPayments: false,
	paginationPayments: defaultPagination,
	payments: [],
	refetchPayments: () => {
		console.log('REFETCH PAYMENTS');
	},
	setPaginationPayments: () => {
		console.log('SET PAGINATION');
	},
	setPayments: () => {
		console.log('SET PAYMENTS');
	},
});

// users
export interface ContextEntityUsersProps {
	isLoadingUsers: boolean;
	paginationUsers: Pagination;
	users: User[];
	refetchUsers: () => void;
	setPaginationUsers: Dispatch<SetStateAction<Pagination>>;
	setUsers: Dispatch<SetStateAction<User[]>>;
}

export const ContextEntityUsers = createContext<ContextEntityUsersProps>({
	isLoadingUsers: false,
	paginationUsers: defaultPagination,
	refetchUsers: () => {
		console.log('REFETCH USERS');
	},
	setPaginationUsers: () => {
		console.log('SET PAGINATION');
	},
	setUsers: () => {
		console.log('SET USERS');
	},
	users: [],
});
