// i18n
import { TFunction } from 'i18next';

// types
import { AccountSource, Loan } from '../../../@types';

// icons
import {
	AccountBalanceOutlined as IconAccountBalance,
	PublicOutlined as IconPublic,
} from '@mui/icons-material';

export interface ConstructAmountInputsProps {
	sources: AccountSource[];
	loans: Loan[];
	t: TFunction<'translation', undefined>;
}
export const constructAmountInputs = ({ sources, loans, t }: ConstructAmountInputsProps) => {
	return sources.map((source: AccountSource) => {
		const loanId = (source.loan as Loan)?.id ? (source?.loan as Loan).id : source.loan;
		const loan = loans.find((l) => l.id === loanId);
		const disabled = loan?.status === 'DISABLED';

		let label = loan?.idWbg;
		let type = 'default';
		let icon = null;

		if (loan) {
			type = 'loan';
		}

		if (source.source === 'OTHER') {
			icon = <IconPublic />;
			label = t('other');
		}

		if (source.source === 'GOVERNMENT') {
			icon = <IconAccountBalance />;
			label = t('government');
		}

		return { disabled, icon, label, type };
	});
};
