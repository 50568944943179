// types
import { User, UserStatus } from '../../../../@types';

// i18n
import { useTranslation } from 'react-i18next';

// ui
import { ChipStatus } from '../../../ui/ChipStatus/ChipStatus';
import { Table, TableHeader } from '../../../ui/Table/Table';
import { UserAvatar } from '../../../ui/UserAvatar/UserAvatar';

// links
import { LinkEmail } from '../../../links/LinkEmail/LinkEmail';
import { LinkPhone } from '../../../links/LinkPhone/LinkPhone';

// props
export interface TableBasicUsersProps {
	data: User[];
}

export const TableBasicUsers = ({ data = [] }: TableBasicUsersProps) => {
	// hooks
	const { t } = useTranslation();

	const headers = [
		{
			label: t('name'),
			render: (name: string, header: TableHeader, user: User) => {
				return <UserAvatar user={user} />;
			},
			value: 'name',
		},
		{
			label: t('jobTitle'),
			render: (title: string) => {
				return title || t('na');
			},
			value: 'title',
		},
		{
			label: t('status'),
			render: (status: UserStatus) => {
				return <ChipStatus status={status} />;
			},
			value: 'status',
		},
		{
			label: t('email'),
			render: (email: string) => {
				return email ? <LinkEmail email={email} /> : t('na');
			},
			value: 'email',
		},
		{
			label: t('phone'),
			render: (phone: string) => {
				return phone ? <LinkPhone phone={phone} /> : t('na');
			},
			value: 'phone',
		},
	];
	return <Table data={data} headers={headers} />;
};
